import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { observer } from 'mobx-react';
import Auth from '@aws-amplify/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkUserInCognitoGroup } from "../../utils/AuthUtils";
import { PATH_NAME_DOCUMENT_SEARCH } from "../../constants/strings";
import logoImg from "../../img/logo.png";
import documentImg from "../../img/doc-search.svg"
import { generateBrowserFingerprint, updateSessionAndDevicesArray } from '../../utils/Browser';

const HeaderBar = ({ auth, location }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const node = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isSideNavOpen) {
        if (node.current && !node.current.contains(e.target) && !e.target.closest('.burger-menu-btn')) {
          setIsSideNavOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSideNavOpen]);

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  }


  const handleLogOut = async (event) => {
    event.preventDefault();

    try {
      const currentUserCredentials = await Auth.currentUserInfo();
      let user = auth.user;
      const sessionCountCheck = currentUserCredentials.attributes["custom:sessionCount"];
      let devicesAttribute = currentUserCredentials.attributes['custom:devices'];

      if (sessionCountCheck !== undefined) {
        generateBrowserFingerprint(async (fingerprint) => {
          await updateSessionAndDevicesArray(fingerprint, Auth, devicesAttribute, sessionCountCheck, user, true);
        });
      }
      await Auth.signOut();
      localStorage.clear()
      // window.location.href = '/login';
    } catch (error) {
      localStorage.removeItem("email")
      console.log(error.message);
    }
  };

  const getRoutesJSX = () => {
    let user = auth.user;
    let path = location.pathname;
    return (
      <>
        {checkUserInCognitoGroup(user, "spark-credential-creation-admins") &&
          <a
            className={`nav-bar-item ${path === '/createuser' ? "active" : "nav-bar-item"}`}
            href='/createuser'
          >
            <FontAwesomeIcon icon="user" className="ico" />
            <div className="btn-label">
              Create User
            </div>
          </a>
        }
        {checkUserInCognitoGroup(user, "spark-watermark-admins") &&
          <a
            className={`nav-bar-item ${path === '/watermark' ? "active" : "nav-bar-item"}`}
            href='/watermark'
          >
            <FontAwesomeIcon icon="stamp" className="ico" />
            <div className="btn-label">
              Watermark
            </div>
          </a>
        }
        {/* {checkUserInCognitoGroup(user, "spark-download-permissions-admins") &&
                    <a
                        className={`nav-bar-item ${path === '/download-accesscontrol' ? "active" : "nav-bar-item"}`}
                        href='/download-accesscontrol'
                    >
                        <FontAwesomeIcon icon="file-download" className="ico" />
                        <div className="btn-label">
                            Download Control
                        </div>
                    </a>
                } */}
        {checkUserInCognitoGroup(user, "spark") &&
          <a
            className={`nav-bar-item ${path === '/podcastlist' ? "active" : "nav-bar-item"}`}
            href='/podcastlist'
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon="cloud-upload-alt" className="ico" />
            <div className="btn-label">
              Podcast Control
            </div>
          </a>
        }
        {checkUserInCognitoGroup(user, "signup-admins") &&
          <a
            className={`nav-bar-item ${path === '/documentupload' ? "active" : "nav-bar-item"}`}
            href='/documentupload'
          >
            <FontAwesomeIcon icon="file-upload" className="ico" />
            <div className="btn-label">
              Report Upload
            </div>
          </a>
        }
        {checkUserInCognitoGroup(user, "signup-admins") &&
          <a
            className={`nav-bar-item ${path === '/accesscontrol' ? "active" : "nav-bar-item"}`}
            href='/accesscontrol'
          >
            <FontAwesomeIcon icon="key" className="ico" />
            <div className="btn-label">
              Access Control
            </div>
          </a>
        }
        <a
          className={`nav-bar-item ${path === `${PATH_NAME_DOCUMENT_SEARCH}` ? "active" : "nav-bar-item"}`}
          href={`${PATH_NAME_DOCUMENT_SEARCH}`}
        >
          <img src={documentImg} className="ico header-icon" alt="Document Search" />
          <div className="btn-label">
            Document Search
          </div>
        </a>
        {/*
                    Commenting out for .
                */}
        <a
          className={`nav-bar-item ${path === '/podcasts' ? "active" : "nav-bar-item"}`}
          href='/podcasts'
        >
          <FontAwesomeIcon icon="podcast" className="ico" />
          <div className="btn-label">
            Podcasts
            {/* <sup><span className="badge badge-success">New</span></sup> */}
          </div>
        </a>
        <div className="nav-bar-item user-name-container">
          <FontAwesomeIcon icon="user-circle" className="ico user-icon" />
          <div className="btn-label user-name">
            Welcome, {user.attributes['custom:name']}
          </div>
        </div>
        <button
          className="nav-bar-item"
          onClick={handleLogOut}
        >
          <FontAwesomeIcon icon="sign-out-alt" className="ico logout-icon" />
          <div className="btn-label">
            Logout
          </div>
        </button>
      </>
    )
  }

  let user = auth.user;

  return (
    <>
      {isSideNavOpen && <div className="faded-content" />}
      <div className="main-header-bar">
        <a href={`${PATH_NAME_DOCUMENT_SEARCH}`}>
          <img
            className="spark-logo"
            src={logoImg}
            alt="Brand Logo"
          />
        </a>
        <div className={isSideNavOpen ? "sidenav is-active" : "sidenav"} ref={node}>
          <>
            <div className="user-name-item">
              <FontAwesomeIcon icon="user-circle" className="ico user-icon" />
              <div className="btn-label user-name">
                {user.attributes.email}
              </div>
            </div>
            {getRoutesJSX()}
          </>
        </div>
        <div className="user-actions-container">
          {getRoutesJSX()}
        </div>
        <button
          className="burger-menu-btn"
          onClick={toggleSideNav}
        >
          {isSideNavOpen ?
            <FontAwesomeIcon icon="times" className="podcast-reset-icon" /> :
            <FontAwesomeIcon icon={['fas', 'bars']} className="podcast-reset-icon" />
          }
        </button>
      </div>
    </>
  );
}


export default observer(HeaderBar);
