//This is a modified version of Bootstrap 4 card

import React, { Component } from 'react';
import './style.scss';
import { observer } from 'mobx-react';
import Login from '../../modules/Login';
import Signup from '../../modules/Signup';
import CreatePassword from '../../modules/CreatePassword';
import ForgotPassword from '../../modules/ForgotPassword';
import ResendMail from '../../modules/ResendMail';
import axios from "axios";
import {PATH_NAME_DOCUMENT_SEARCH} from "../../constants/strings";
import logoImg from '../../img/logo.png'
import Vector from '../../img/Vector.png'
import Banner from '../Banner';
import LoginWithPassword from '../../modules/LoginWithPassword';

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    let err;

    if (error.hasOwnProperty("response")) {
        err = error.response;
    } else {
        err = error;
    }

    return Promise.reject(err);
});

class MainLoginPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let redirectUrl = this.props.store.redirectRoute ? this.props.store.redirectRoute : `${PATH_NAME_DOCUMENT_SEARCH}`;

        if (this.props.auth.isAuthenticated) {
            this.props.history.push(redirectUrl); // redirect user to app if user is authenticated
        } else if (this.props.name === 'mainPage') {
            this.props.history.push('/login'); // redirect user to login if no endpoint is given
        }
    }

    render() {
        return (
            <>
                {/* <Banner></Banner> */}
                <div className="login-page">
                    <div style={{
                        width: "50%",
                        visibility: "hidden"
                    }}>
                        <div
                            style={{
                                position: "absolute",
                                display: "flex",
                                top: "5rem",
                                left: "3rem",
                                flexDirection: "column",
                                alignContent: "flex-start",
                                alignItems: "flex-start",
                            }}
                        >
                            <h5
                                style={{
                                    textAlign: "left",
                                    width: "40%"
                                }}
                            >
                                <b>New Directions for Institutional Research:</b> Vol 2022 No 189-192
                            </h5>
                            <img
                                style={{
                                    width: 40,
                                    heught: 40,
                                }}
                                className="spark-logo"
                                src={Vector}
                                alt="Brand Logo"
                            />
                        </div>
                    </div>
                    <div className="login-card">
                        <img
                            style={{
                                width: 200,
                                heught: 200,
                                position: "absolute",
                                top: 10,
                                left: 10,
                            }}
                            className="spark-logo"
                            src={logoImg}
                            alt="Brand Logo"
                        />
                        {this.props.name === 'login' ? (
                            <p
                                style={{
                                    fontSize: "1.5rem",
                                    marginTop: "5rem",
                                    marginBottom: "1rem",
                                    width: "70%",
                                    paddingLeft: "1rem",
                                    textAlign: "left"
                                }}
                            >Login to Your account</p>
                        ) :
                            <div
                                style={{
                                    fontSize: "1.5rem",
                                    marginTop: "5rem",
                                    marginBottom: "1rem",
                                    width: "70%",
                                    paddingLeft: "1rem",
                                    textAlign: "left"
                                }}
                            // className="card-title"
                            >{this.props.title}</div>
                        }
                        <div className="card-body">
                            <div className="section auth">
                                {this.props.name === 'login' && (
                                    <Login {...this.props} />
                                )
                                }
                                {this.props.name === 'loginwithpassword' && (
                                    <LoginWithPassword {...this.props} />
                                )
                                }
                                {this.props.name === 'signUp' && (
                                    <Signup {...this.props} />
                                )
                                }
                                {this.props.name === 'createPassword' && (
                                    <CreatePassword {...this.props} />
                                )
                                }
                                {this.props.name === 'forgotPassword' && (
                                    <ForgotPassword {...this.props} />
                                )
                                }
                                {this.props.name === 'resendMail' && (
                                    <ResendMail {...this.props} />
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

export default observer(MainLoginPage);
