import React, {Component} from 'react';
import './style.scss'
import {
    Button,
    Card,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input, Modal, ModalBody, ModalFooter,
    Progress,
    Row,
    Spinner
} from "reactstrap";
import {
    TOAST_TYPE_ERROR, TOAST_TYPE_INFO, TOAST_TYPE_SUCCESS,
    TOAST_TYPE_WARNING, WATERMARK_FILE_UPLOAD_ENDPOINT,
    WM_TOAST_ID
} from "../../constants/strings";
import Dropzone from "react-dropzone";
import uploadWaterMarkingFileApi from "../../apis/common";
import {getToken} from "../../services/auth.service";
import Validate from "../../utils/FormValidation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {checkUserInCognitoGroup} from "../../utils/AuthUtils";

class WaterMark extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            selectedFiles: [],
            errors: {
                fileInvalid: false,
                emailInvalid: false,
            },
            isLoading: false,
            isUploadFilesModalOpen: false,
            progressPercentage: 0,
            uploadStatus: "NotSelected",
            download_anyway: false,
            isModalOpen: false,
            error_message: '',
        }

        this.uploadStatusMapping = {
            "NotSelected": {
                "class": "info",
                "icon": "bx-upload"
            },
            "Uploading": {
                "class": "warning",
                "icon": "bx-upload"
            },
            "Processing": {
                "class": "info",
                "icon": "bx-dots-horizontal"
            },
            "Success": {
                "class": "success",
                "icon": "bx-check"
            },
            "Failed": {
                "class": "error",
                "icon": "bx-error"
            }
        }
    }

    componentDidMount() {
        if (!this.props.auth.user ||
            !checkUserInCognitoGroup(this.props.auth.user, "spark-watermark-admins")
        ) {
            this.props.history.push("/document_search");
        }
    }


    clearErrorState = () => {
        this.setState({
            error_message: "",
            errors: {
                fileInvalid: false,
                emailInvalid: false,
            }
        });
    };

    onInputChange = (e) => {
        this.setState({email: e.target.value})
    }

    onUploadFilesModalToggle = () => {
        this.setState({isUploadFilesModalOpen: !this.state.isUploadFilesModalOpen})
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                file: file,
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size)
            })
        );

        this.setState({selectedFiles: files}, () => {
            if (this.state.selectedFiles.length > 1) {
                this.setState({selectedFiles: []}, () => {
                    this.props.toastify.fire("Please select only one file", WM_TOAST_ID, TOAST_TYPE_WARNING)
                })
            }
        });
    };

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    onUploadProgress = (progressEvent) => {
        let {loaded, total} = progressEvent;
        let percent = Math.floor(loaded * 100 / total);

        // Change scale of progress to 90, to accomodate for processing time after upload
        let processedPercent = percent * (90 / 100);
        this.setState({progressPercentage: processedPercent});
        if (percent === 100) {
            this.setState({uploadStatus: "Processing"});
        }
    }

    async uploadFile() {
        let self = this;

        this.setState({isLoading: true});
        this.clearErrorState();

        const errors = Validate('watermark', this.state);
        if (Object.keys(errors).length) {
            this.setState({
                errors: {...errors},
                isLoading: false
            });
            return;
        }

        let data = new FormData();
        data.append('file', this.state.selectedFiles[0]);
        data.append('recipient_email', this.state.email);
        if (this.state.download_anyway) {
            data.append('download_anyway', this.state.download_anyway);
        }

        let options = {
            onUploadProgress: self.onUploadProgress.bind(self)
        }

        let token = await getToken();

        this.setState({uploadStatus: "Uploading"});

        this.uploadWaterMarkingFile(data, options, token)
            .then(this.uploadWaterMarkingFileThenCallback)
            .catch(this.uploadWaterMarkingFileErrorCallback);
    }

    async uploadWaterMarkingFile(data, options = {}, token) {
        return await uploadWaterMarkingFileApi.post(WATERMARK_FILE_UPLOAD_ENDPOINT,
            data,
            {
                ...options,
                headers: {"Authorization": token},
                responseType: 'blob'
            }
        );
    }

     uploadWaterMarkingFileThenCallback = async(res) => {
        if (res.status === 200) {
            this.setState({progressPercentage: 100, uploadStatus: "Success", isLoading: false});

            if(res.headers["content-type"] === "application/json"){
                const blobText = await new Response(res.data).json();
                if(blobText.status === false){
                    if(blobText.error_message === 'Recipient not Registered'){
                        this.setState({
                            error_message: blobText.error_message,
                        }, () => this.toggleModal())
                    }else {
                        this.props.toastify.fire(blobText.error_message, WM_TOAST_ID, TOAST_TYPE_ERROR);
                        this.resetPage();
                    }
                }else {
                    this.props.toastify.fire(blobText.success_message, WM_TOAST_ID, TOAST_TYPE_INFO);
                    this.resetPage();
                }
            } else {
                const blob = new Blob([res.data], {type: 'application/pdf'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${(this.state.selectedFiles[0].name).replace(/\.[^/.]+$/, "")}.pdf`;
                link.click();
                this.props.toastify.fire("File downloaded successfully.", WM_TOAST_ID, TOAST_TYPE_SUCCESS);
                this.resetPage();
            }
        } else {
            this.setState({uploadStatus: "Failed", isLoading: false},
                ()=>{
                    this.props.toastify.fire("Something went wrong.", WM_TOAST_ID, TOAST_TYPE_ERROR);
                    this.resetPage();
                }
                );

        }
    };

    uploadWaterMarkingFileErrorCallback = error => {
        console.error("error:", error);
        this.setState({uploadStatus: "Failed", isLoading: false},
            ()=>{
                this.props.toastify.fire("Something went wrong.", WM_TOAST_ID, TOAST_TYPE_ERROR);
                this.resetPage();
            }
            );
    };

    toggleModal = () => {
        this.setState({isModalOpen: !this.state.isModalOpen})
    }

    resetPage = () => {
        this.setState({
            selectedFiles: [],
            email: '',
            error_message: '',
            isLoading: false,
            progressPercentage: 0,
            uploadStatus: "NotSelected",
            download_anyway: false,
            isModalOpen: false,
            isDragActive: false,
        })
    }

    render() {
        const {errors} = this.state;
        return (
            <div className="watermark-container">
                <div className="watermark-container__heading">
                    <h1>Watermarking for Research Reports</h1>
                </div>
                <div className="watermark-container__form">
                    <FormGroup>
                        <div className="watermark-container__form__label-container">
                            <span className="watermark-container__form__field-label">Upload File (pdf or pptx):</span>
                        </div>
                        {this.state.uploadStatus === "NotSelected" ?
                            // eslint-disable-next-line react/jsx-no-undef
                            <>
                                <Form
                                    style={{
                                        border: errors.fileInvalid ? '2px solid #dc3545' : '2px solid #ced4da',
                                        borderRadius: '4px'
                                    }}>
                                    <Dropzone
                                        onDrop={acceptedFiles =>
                                            this.handleAcceptedFiles(acceptedFiles)
                                        }
                                    >
                                        {({getRootProps, getInputProps, isDragActive}) => (
                                            <div
                                                className="dz-message needsclick"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} multiple={false}/>
                                                {
                                                    <h6 style={{
                                                        cursor: "pointer",
                                                        color: isDragActive ? 'rgb(14, 175, 226)' : 'rgb(77, 77, 77)',
                                                        backgroundColor: isDragActive ? 'rgba(14, 175, 226, 0.2)' : '',
                                                        fontWeight: isDragActive ? '700' : 'inherit',
                                                        display: 'block',
                                                        padding: '1rem 2rem',
                                                        width: '100%',
                                                        height: '100%',
                                                        margin: '0',
                                                    }}
                                                    >{isDragActive ? `Drop the files here ...` : `Drop or click here to select file.`}</h6>
                                                }
                                            </div>
                                        )}
                                    </Dropzone>
                                    <div
                                        className="dropzone-previews"
                                        id="file-previews mb-3"
                                    >
                                        {this.state.selectedFiles.map((f, i) => {
                                            return (
                                                <Card
                                                    className="mt-1 mb-2 mr-2 ml-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={i + "-file"}
                                                >
                                                    <div className="p-2">
                                                        <Row className="align-items-center position-relative">
                                                            <Col>
                                                                <FontAwesomeIcon icon="times"
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: '0.75rem',
                                                                        top: '-0.45rem',
                                                                        width: '0.75rem',
                                                                        height: 'auto',
                                                                        cursor: "pointer"
                                                                    }}
                                                                     onClick={() => this.setState({selectedFiles: []})}
                                                                />

                                                                <p className="text-muted font-weight-bold">
                                                                    {f.name}
                                                                </p>
                                                                <p className="mb-0">
                                                                    <strong>{f.formattedSize}</strong>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            );
                                        })}
                                    </div>
                                </Form>
                            </>
                            :
                            <>
                                <div className="upload-badge-container">
                                    <div
                                        className={`upload-status-badge ${this.uploadStatusMapping[this.state.uploadStatus]["class"]}`}>
                                        {this.state.uploadStatus}
                                    </div>
                                </div>
                                {
                                    this.state.uploadStatus !== "Failed" &&
                                    // NOTE: progress bar doesn't allow to be contained inside a div; reason: unknown
                                    <div id="bar" className="mt-4">
                                        <Progress color="info" striped animated
                                                  value={this.state.progressPercentage}/>
                                        <div className="progress-bar bg-info"/>
                                    </div>
                                }
                                <div className="progress-container">
                                    <div className="selected-file">
                                        {this.state.selectedFiles[0].name}
                                    </div>
                                    {this.state.uploadStatus !== "Failed" &&

                                    <div className="progress-percentage">
                                        {this.state.progressPercentage}% Completed
                                    </div>
                                    }
                                </div>
                            </>
                        }
                        <span
                            className='watermark-container__form__feedback-text'
                            style={{display: 'block !important'}}
                        >
                            {errors.fileInvalid ?
                                this.state.selectedFiles.length > 1 ?
                                    `Please select only one file.`
                                    :
                                    `Please select a 'pdf' or 'pptx' format file.`
                                : ''}
                        </span>
                    </FormGroup>
                    <FormGroup>
                        <div className="watermark-container__form__label-container">
                            <span className="watermark-container__form__field-label">Enter Recipient Email ID:</span>
                        </div>
                        <Input
                            type="text"
                            name="email"
                            value={this.state.email}
                            placeholder="example@abc.com"
                            onChange={this.onInputChange}
                            onKeyPress={this.onKeyPress}
                            invalid={errors.emailInvalid}
                        />
                        <FormFeedback
                        >
                            {errors.emailInvalid ? 'Please enter valid E-mail ID.' : ''}
                        </FormFeedback>
                    </FormGroup>

                    <Button
                        className="watermark-container__submit-btn"
                        size="sm"
                        onClick={this.uploadFile.bind(this)}
                    >{!this.state.isLoading ? "SUBMIT" : <Spinner size="sm" color="white"/>}
                    </Button>

                </div>
                <Modal
                    isOpen={this.state.isModalOpen}
                    size='sm'
                    centered={true}
                >
                    <div className="download-confirm-modal"
                    >
                        <ModalBody className='download-confirm-modal__body'>
                            <span
                                className='download-confirm-modal__body__err-msg'>{`"${this.state.error_message}"`}</span>
                            <span>Download anyway?</span>
                        </ModalBody>
                        <ModalFooter className='download-confirm-modal__footer'>
                            <Button className='download-confirm-modal__footer__btn-yes' size='sm'
                                    onClick={() => {
                                        this.toggleModal();
                                        this.setState({download_anyway: true}, () => this.uploadFile())
                                    }
                                    }
                            >Yes</Button>
                            <Button className='download-confirm-modal__footer__btn-no' size='sm'
                                    onClick={() => this.resetPage()}
                            >No</Button>
                        </ModalFooter>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default WaterMark;