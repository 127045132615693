/* eslint-disable no-useless-escape */
const RESTRICTED_DOMAINS =
  process.env.REACT_APP_RESTRICTED_EMAIL_DOMAINS.split(",");

function validateForm(page, state) {
  let newState = {};

  switch (page) {
    case "createUser":
      newState = state;
      if (newState.hasOwnProperty("email")) {
        const email = newState.email.value;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (emailRegex.test(email)) {
          const domain = email.split("@")[1].split(".")[0];
          if (RESTRICTED_DOMAINS.includes(domain)) {
            newState.email.invalid = true;
            newState.email.error = true;
            newState.email.errorFeedback =
              "Please enter a valid email address. Domain not supported!";
          } else {
            newState.email.invalid = false;
            newState.email.error = false;
            newState.email.errorFeedback = "";
          }
        } else {
          newState.email.invalid = true;
          newState.email.error = true;
          newState.email.errorFeedback = "Please enter a valid email address.";
        }
      }
      if (newState.hasOwnProperty("first_name")) {
        if (newState.first_name.value.length) {
          newState.first_name.invalid = false;
          newState.first_name.error = false;
          newState.first_name.errorFeedback = "";
        } else {
          newState.first_name.invalid = true;
          newState.first_name.error = true;
          newState.first_name.errorFeedback = "Please enter your first name";
        }
      }
      if (newState.hasOwnProperty("last_name")) {
        if (newState.last_name.value.length >= 1) {
          newState.last_name.invalid = false;
          newState.last_name.error = false;
          newState.last_name.errorFeedback = "";
        } else {
          newState.last_name.invalid = true;
          newState.last_name.error = true;
          newState.last_name.errorFeedback = "Please enter your last name";
        }
      }
      if (newState.hasOwnProperty("password")) {
        let format =
          /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{12,}$/;
        if (
          newState.password.value.length >= 12 &&
          /\d/.test(newState.password.value) &&
          format.test(newState.password.value)
        ) {
          newState.password.invalid = false;
          newState.password.error = false;
          newState.password.errorFeedback = "";
        } else {
          newState.password.invalid = true;
          newState.password.error = true;
          newState.password.errorFeedback = `Password must be 12 characters long and must contain 1 symbol, and 1 number.`;
        }
      }
      break;

    case "login":
      if (
        state.hasOwnProperty("email") &&
        !state.email.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        newState = { ...newState, emailInvalid: true };
      }
      if (state.hasOwnProperty("password") && state.password === "") {
        newState = { ...newState, passwordInvalid: true };
      }
      break;

    case "signUp":
      let formInputs = { ...state.formInputs };

      if (!formInputs.name.value.length && formInputs.name.required) {
        formInputs.name.invalid = true;
        formInputs.name.error_message = "Name cannot be empty.";
      }

      if (!formInputs.company.value.length && formInputs.company.required) {
        formInputs.company.invalid = true;
        formInputs.company.error_message = "Company Name cannot be empty.";
      }

      if (!formInputs.contact.value.length && formInputs.contact.required) {
        formInputs.contact.invalid = true;
        formInputs.contact.error_message = "Contact Number cannot be empty.";
      }

      if (!formInputs.email.value.length && formInputs.email.required) {
        formInputs.email.invalid = true;
        formInputs.email.error_message = "Email cannot be empty.";
      }

      newState = { ...formInputs };
      break;

    case "resendMail":
      break;

    case "requestVerificationCode":
      if (state.hasOwnProperty("email") && state.email === "") {
        newState = { ...newState, emailInvalid: true };
      }
      break;

    case "createPassword":
      if (state.hasOwnProperty("tempPassword") && state.tempPassword === "") {
        newState = { ...newState, tempPasswordInvalid: true };
      }
      if (state.hasOwnProperty("newPassword") && state.newPassword.length < 6) {
        newState = { ...newState, newPasswordInvalid: true };
      }
      if (
        state.hasOwnProperty("confirmPassword") &&
        (state.confirmPassword === "" ||
          state.newPassword !== state.confirmPassword)
      ) {
        newState = { ...newState, confirmPasswordInvalid: true };
      }
      break;

    case "forgotPasswordVerification":
      if (state.hasOwnProperty("email") && state.email === "") {
        newState = { ...newState, emailInvalid: true };
      }
      if (state.hasOwnProperty("code") && state.code.length !== 6) {
        newState = { ...newState, codeInvalid: true };
      }
      if (state.hasOwnProperty("newPassword") && state.newPassword.length < 6) {
        newState = { ...newState, newPasswordInvalid: true };
      }
      if (
        state.hasOwnProperty("confirmPassword") &&
        (state.confirmPassword === "" ||
          state.newPassword !== state.confirmPassword)
      ) {
        newState = { ...newState, confirmPasswordInvalid: true };
      }
      break;

    case "documentUpload":
      if (state.payload.hasOwnProperty("file") && state.payload.file) {
        let mime_types = [
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/pdf",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "application/rtf",
          "text/plain",
        ];

        let extensions = ["doc", "docx", "pdf", "ppt", "pptx", "rtf", "txt"];
        let data = state.payload.file;

        for (let i = 0; i < data.length; i++) {
          let file = data[i];
          let extension = file.name.split(".").pop();

          if (
            data &&
            data.length !== 0 &&
            mime_types.includes(file.type) &&
            extensions.includes(extension)
          ) {
            newState = { file_invalid: true };
            break;
          } else {
            newState = { file_invalid: false };
            break;
          }
        }
      } else {
        newState = { file_invalid: true };
      }

      break;

    case "watermark":
      if (
        state.hasOwnProperty("email") &&
        !state.email.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        newState = { ...newState, emailInvalid: true };
      }
      if (
        state.hasOwnProperty("selectedFiles") &&
        state.selectedFiles.length === 0
      ) {
        newState = { ...newState, fileInvalid: true };
      } else if (
        state.hasOwnProperty("selectedFiles") &&
        state.selectedFiles.length > 1
      ) {
        newState = { ...newState, fileInvalid: true };
      } else {
        let filenameArr = state.selectedFiles[0].name.split(".");
        let fileExtension = filenameArr[filenameArr.length - 1].toLowerCase();

        if (
          !(
            fileExtension === "pdf" ||
            fileExtension === "ppt" ||
            fileExtension === "pptx"
          )
        ) {
          newState = { ...newState, fileInvalid: true };
        }
      }
      break;

    default:
      break;
  }

  return newState;
}

export default validateForm;
