
const podcast_list_link = [
    {
        "TITLE": "Podcast 1",
        "document_id": "1",
        "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/Fork & Future - Tejash Shah on ATNI 2023 India Index.m4a",
    },
    {
        "TITLE": "Podcast 2",
        "document_id": "2",
        "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/JuneSampleFile.mp3",
    },
    {
        "TITLE": "Podcast 3",
        "document_id": "3",
        "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/test15.m4a",
    }
]
const podcast_list = [
    {
        "document_id": "1",
        "highlighted_content": [],
        "release_date": "2023-12-05",
        "report_type": "Podcast",
        "score": 1,
        "stock_info": [],
        "title": "Podcast 1"
    },
    {
        "document_id": "2",
        "highlighted_content": [],
        "release_date": "2023-12-05",
        "report_type": "Podcast",
        "score": 1,
        "stock_info": [],
        "title": "Podcast 2"
    },
    {
        "document_id": "3",
        "highlighted_content": [],
        "release_date": "2023-12-05",
        "report_type": "Podcast",
        "score": 1,
        "stock_info": [],
        "title": "Podcast 3"
    }
]
const fetch_recommended_reports = {
    data: {
        "data": [
            {
                "document_id": "ef1e7011cf63807192fb560399a7be1f1f794532faf83997116a3acecca018c9",
                "lead_authors": [
                    "Gnanasundaram Saminathan"
                ],
                "report_type": "Initiation",
                "stock_info": [
                    {
                        "recommendation": "BUY",
                        "sector": "Capital Goods, Engineering & Consumer Durables",
                        "stock_id": 1724,
                        "stock_name": "Vardhman Textiles Ltd"
                    },
                    {
                        "recommendation": "BUY",
                        "sector": "Capital Goods, Engineering & Consumer Durables",
                        "stock_id": 1615,
                        "stock_name": "KPR Mill Ltd"
                    }
                ],
                "sub_authors": null,
                "title": "Indian Textile Sector - Initiating Coverage"
            },
            {
                "document_id": "5097aeb11e1317f7d7e1d0dfd0947918afa72beab8334bd65b14d98c84c6728d",
                "lead_authors": [
                    "Spark Research"
                ],
                "report_type": "Event/Company Update",
                "sub_authors": null,
                "title": "Spark Research 22 Apr 2021"
            },
            {
                "document_id": "43cffe7965fa74bc56d6a6e4e0d37a244a1643c6869f4518fda55309b5152ba6",
                "lead_authors": [
                    "Bharanidhar Vijayakumar"
                ],
                "report_type": "Event/Company Update",
                "sub_authors": null,
                "title": "Electricity Volumes Summary -28-03-2022"
            },
            {
                "document_id": "cdf0226e98558891981b9925ccfa444376a5a8833a2882bbe4f294070d51c26d",
                "lead_authors": [
                    "Spark Research"
                ],
                "report_type": "Event/Company Update",
                "sub_authors": null,
                "title": "Spark Research 29 Mar 2022"
            },
            {
                "document_id": "50dbc23deda271eb201c4ba0be443cec5699e8a02614fb52516efdaf38459340",
                "lead_authors": [
                    "Tejash Shah"
                ],
                "report_type": "Event/Company Update",
                "stock_info": [
                    {
                        "recommendation": "ADD",
                        "sector": "Retail",
                        "stock_id": 334,
                        "stock_name": "Jubilant Foodworks Ltd"
                    }
                ],
                "sub_authors": null,
                "title": "JUBI_Company Update Mar 2022"
            }
        ],
        "status": true
    },
    status: 200
}
const fetch_reports = {
    "data": {
        "category": {
            "glocal": [
                {
                    "doc_count": 1706,
                    "key": "global"
                }
            ]
        },
        "documents": {
            "hits": [
                {
                    "document_id": "e3cfd7ec797992dc0040aa5951e5d118a5c497b0fae02ab3487ceb6acd8ece48",
                    "highlighted_content": [],
                    "release_date": "2023-12-28",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 0,
                            "recommendation": "",
                            "sector": "Financials",
                            "stock_id": 16,
                            "target_price": 0
                        }
                    ],
                    "title": "'Quote 1 Dummy UAT SYNC Test to portal'"
                },
                {
                    "document_id": "55a8b289bf30f6e9f3c86a1d069850858a20288495ca4c1b138d796db3be2912",
                    "highlighted_content": [],
                    "release_date": "2023-12-28",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 0,
                            "recommendation": "",
                            "sector": "Banking",
                            "stock_id": 16,
                            "target_price": 0
                        }
                    ],
                    "title": "Dummy UAT SYNC Test to portal"
                },
                {
                    "document_id": "fbb90400f23e389fec8ba4e036ca9be1a414e7c0a8cd126cb56335f7626852d7",
                    "highlighted_content": [],
                    "release_date": "2023-12-28",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 0,
                            "recommendation": "",
                            "sector": "Financials",
                            "stock_id": 16,
                            "target_price": 0
                        }
                    ],
                    "title": "Underscore_Dummy UAT SYNC Test to portal"
                },
                {
                    "document_id": "99186709f81a20ae427355e5c19cce5a420656b3520c4901128055b62291cf8d",
                    "highlighted_content": [],
                    "release_date": "2023-12-28",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 0,
                            "recommendation": "",
                            "sector": "Financials",
                            "stock_id": 16,
                            "target_price": 0
                        }
                    ],
                    "title": "`Tset Dummy UAT SYNC Test to portal"
                },
                {
                    "document_id": "38a86f6eedbd5c33d9f6670a4471633261eff550ac4b1591385c0573ba147e7c",
                    "highlighted_content": [],
                    "release_date": "2023-12-28",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 0,
                            "recommendation": "",
                            "sector": "Financials",
                            "stock_id": 16,
                            "target_price": 0
                        }
                    ],
                    "title": "Hyphen - Dummy UAT SYNC Test to portal"
                },
                {
                    "document_id": "f77de55034ed0bbb2b19952ec4d97fee5a96c601234c66fd35a9b5761050c6e2",
                    "highlighted_content": [],
                    "release_date": "2023-12-28",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 0,
                            "recommendation": "",
                            "sector": "Financials",
                            "stock_id": 16,
                            "target_price": 0
                        }
                    ],
                    "title": "\"Double Quote Dummy UAT SYNC Test to portal\""
                },
                {
                    "document_id": "46efe07cb4f7130b6a384bb7c8d6759b4e8140eedde70f1bdce44e8b815819f9",
                    "highlighted_content": [],
                    "release_date": "2023-12-27",
                    "report_type": "Sector Update",
                    "score": 1,
                    "stock_info": [],
                    "title": "Pharmaceuticals_Sector Update_07 June 2022"
                },
                {
                    "document_id": "46efe07cb4f7130b6a384bb7c8d6759b4e8140eedde70f1bdce44e8b815819f9",
                    "highlighted_content": [],
                    "release_date": "2023-12-27",
                    "report_type": "Initiation",
                    "score": 1,
                    "stock_info": [],
                    "title": "Pharmaceuticals_Sector Update_07 June 2022"
                },
                {
                    "document_id": "add06836a8a54db369fe693ce5488df2ce732d5d79ff34550cec29cf9d24bdf9",
                    "highlighted_content": [],
                    "release_date": "2023-12-05",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 1423,
                            "recommendation": "BUY",
                            "sector": "Banks",
                            "stock_id": "1",
                            "target_price": 1423
                        }
                    ],
                    "title": "Sumamry_Earning"
                },
                {
                    "document_id": "19eaab80f92b1411f68c1c3ae4839e730695da6bfeff4e144fa2ad0dc5d90caa",
                    "highlighted_content": [],
                    "release_date": "2023-11-28",
                    "report_type": "Event/Company Update",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 0,
                            "recommendation": "",
                            "sector": "IT/Telecom/Flex-Staffing",
                            "stock_id": 42,
                            "target_price": 0
                        }
                    ],
                    "title": "Financials Sector Strategy - FY24"
                },
                {
                    "document_id": "422ebb38f75c2b98e9e99eba90399a8f474e4d2d8390df4cb68b01a2411b3785",
                    "highlighted_content": [],
                    "release_date": "2023-11-24",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 0,
                            "recommendation": "BUY",
                            "sector": "Constructions*",
                            "stock_id": 118,
                            "target_price": 0
                        }
                    ],
                    "title": "Test EICHER MOTORS Outlook Review FY24"
                },
                {
                    "document_id": "f710e5746b698d053dab9cd2acf7b5480335b96374061606b1539fbfafa27f59",
                    "highlighted_content": [],
                    "release_date": "2023-11-24",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 0,
                            "recommendation": null,
                            "sector": "Business Services",
                            "stock_id": 197,
                            "target_price": 0
                        }
                    ],
                    "title": "Test BAJAJCON Outlook Review FY24"
                },
                {
                    "document_id": "e566a8bb7e837bc5a8c0e2d1eb3bd2f93c0eb2b09b89fec9445d1bd8e78e9252",
                    "highlighted_content": [],
                    "release_date": "2023-11-09",
                    "report_type": "Outlook Review",
                    "score": 1,
                    "stock_info": [],
                    "title": "Test IndBank Outlook Review 2QFY24"
                },
                {
                    "document_id": "3f8e6379198b053790a8abe370d58973fce82f6d795fc6d7664b802ee8f01c1f",
                    "highlighted_content": [],
                    "release_date": "2023-10-19",
                    "report_type": "Outlook Review",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 1620,
                            "recommendation": "SELL",
                            "sector": null,
                            "stock_id": 1,
                            "target_price": 1593
                        }
                    ],
                    "title": "New Document Testing"
                },
                {
                    "document_id": "c1041a9c6b110d57ec2e95987749f3ff31dc4e2819b5eb24216a0ee150ee62a8",
                    "highlighted_content": [],
                    "release_date": "2023-10-09",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 652.0,
                            "recommendation": "SELL",
                            "sector": "Banks",
                            "stock_id": 3,
                            "target_price": 740.0
                        }
                    ],
                    "title": "New Testing Document 123"
                },
                {
                    "document_id": "081b15b64d3880a17b796d4a76121044b27bd1246714427abf4f8b9fdf78547b",
                    "highlighted_content": [],
                    "release_date": "2023-01-05",
                    "report_type": "Outlook Review",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 1620,
                            "recommendation": "SELL",
                            "sector": null,
                            "stock_id": 1,
                            "target_price": 1593
                        }
                    ],
                    "title": "sample audio pdf"
                },
                {
                    "document_id": "43efcdc834ded100b829a9b7b38b85bcc314f98a3e0cfa076a4a853edccae7d9",
                    "highlighted_content": [],
                    "release_date": "2022-11-30",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 3800,
                            "recommendation": "BUY",
                            "sector": "IT/Telecom/Flex-Staffing",
                            "stock_id": 55,
                            "target_price": 4000
                        }
                    ],
                    "title": "Spark recommendation - TCS has a lot on its plate"
                },
                {
                    "document_id": "f35211e1239eb381d8bdb52df2245e0d08af5517645efb02f1cbbcdf069a65cb",
                    "highlighted_content": [],
                    "release_date": "2022-11-30",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 3386,
                            "recommendation": "BUY",
                            "sector": "Automobiles",
                            "stock_id": 55,
                            "target_price": 3512
                        }
                    ],
                    "title": "Spark recommendation - TCS BUY Positive Momentum"
                },
                {
                    "document_id": "b2ec18281dba91032f05367f0bc82db1df08f1208ca7a22d933862101266bb24",
                    "highlighted_content": [],
                    "release_date": "2022-11-29",
                    "report_type": "Annual Report Analysis",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 1610,
                            "recommendation": "BUY",
                            "sector": "Financials",
                            "stock_id": 12,
                            "target_price": 1630
                        }
                    ],
                    "title": "HDFC BUY Test Report 29/11/2022"
                },
                {
                    "document_id": "7da0add09a8edabce398a0cfaf2e56e57c0c6a6f80cd5f48157fdb8cccf01693",
                    "highlighted_content": [],
                    "release_date": "2022-11-29",
                    "report_type": "Outlook Review",
                    "score": 1,
                    "stock_info": [
                        {
                            "current_price": 1620,
                            "recommendation": "BUY",
                            "sector": null,
                            "stock_id": 1,
                            "target_price": 1593
                        }
                    ],
                    "title": "testtest stock test demo"
                }
            ],
            "total_result": {
                "relation": "eq",
                "value": 1706
            }
        },
        "filters": [
            {
                "filter_key": "report_filter",
                "filter_name_key": "reports",
                "heading": "Report Type",
                "values": [
                    {
                        "doc_count": 39,
                        "key": "Annual Report Analysis"
                    },
                    {
                        "doc_count": 533,
                        "key": "Event/Company Update"
                    },
                    {
                        "doc_count": 20,
                        "key": "Initiation"
                    },
                    {
                        "doc_count": 925,
                        "key": "Outlook Review"
                    },
                    {
                        "doc_count": 110,
                        "key": "Sector Update"
                    },
                    {
                        "doc_count": 79,
                        "key": "Thematic Note"
                    }
                ]
            },
            {
                "filter_key": "sector_filter",
                "filter_name_key": "sectors",
                "heading": "Sector",
                "values": [
                    {
                        "doc_count": 52,
                        "key": "Agrochem, Fertilisers & Agri-Related Businesses"
                    },
                    {
                        "doc_count": 6,
                        "key": "Apparels"
                    },
                    {
                        "doc_count": 65,
                        "key": "Automobile Oems & Ancillaries"
                    },
                    {
                        "doc_count": 41,
                        "key": "Automobiles"
                    },
                    {
                        "doc_count": 3,
                        "key": "Banking"
                    },
                    {
                        "doc_count": 17,
                        "key": "Banks"
                    },
                    {
                        "doc_count": 23,
                        "key": "Building Materials"
                    },
                    {
                        "doc_count": 16,
                        "key": "Business Services"
                    },
                    {
                        "doc_count": 19,
                        "key": "Capital Goods"
                    },
                    {
                        "doc_count": 55,
                        "key": "Capital Goods, Engineering & Consumer Durables"
                    },
                    {
                        "doc_count": 55,
                        "key": "Cement"
                    },
                    {
                        "doc_count": 27,
                        "key": "Constructions"
                    },
                    {
                        "doc_count": 1,
                        "key": "Constructions*"
                    },
                    {
                        "doc_count": 19,
                        "key": "Consumer Discretionary"
                    },
                    {
                        "doc_count": 30,
                        "key": "Consumer Durables"
                    },
                    {
                        "doc_count": 6,
                        "key": "Consumer Staples"
                    },
                    {
                        "doc_count": 49,
                        "key": "Consumption"
                    },
                    {
                        "doc_count": 4,
                        "key": "Economics"
                    },
                    {
                        "doc_count": 109,
                        "key": "Financials"
                    },
                    {
                        "doc_count": 1,
                        "key": "Financials*"
                    },
                    {
                        "doc_count": 34,
                        "key": "Healthcare"
                    },
                    {
                        "doc_count": 4,
                        "key": "IT/Internet/Flex-Staffing/Software Services & Products"
                    },
                    {
                        "doc_count": 9,
                        "key": "IT/Telecom/Flex-Staffing"
                    },
                    {
                        "doc_count": 34,
                        "key": "Infrastructure"
                    },
                    {
                        "doc_count": 13,
                        "key": "Infrastructure/EPC/Power"
                    },
                    {
                        "doc_count": 11,
                        "key": "Infrastructure/Epc/Power"
                    },
                    {
                        "doc_count": 120,
                        "key": "Insurance"
                    },
                    {
                        "doc_count": 10,
                        "key": "It/Internet/Flex-Staffing"
                    },
                    {
                        "doc_count": 13,
                        "key": "It/Internet/Flex-Staffing/Software Services & Products"
                    },
                    {
                        "doc_count": 32,
                        "key": "Logistics"
                    },
                    {
                        "doc_count": 8,
                        "key": "Market Infrastructure"
                    },
                    {
                        "doc_count": 18,
                        "key": "Media"
                    },
                    {
                        "doc_count": 15,
                        "key": "Media & Entertainment"
                    },
                    {
                        "doc_count": 24,
                        "key": "Metals"
                    },
                    {
                        "doc_count": 3,
                        "key": "Metals - Ferrous"
                    },
                    {
                        "doc_count": 1,
                        "key": "Mid Caps"
                    },
                    {
                        "doc_count": 55,
                        "key": "Oil & Gas"
                    },
                    {
                        "doc_count": 11,
                        "key": "Paints"
                    },
                    {
                        "doc_count": 88,
                        "key": "Pharmaceuticals"
                    },
                    {
                        "doc_count": 35,
                        "key": "Real Estate"
                    },
                    {
                        "doc_count": 17,
                        "key": "Retail"
                    },
                    {
                        "doc_count": 68,
                        "key": "Software Services & Products"
                    },
                    {
                        "doc_count": 1,
                        "key": "Strategy"
                    },
                    {
                        "doc_count": 15,
                        "key": "Telecom"
                    },
                    {
                        "doc_count": 2,
                        "key": "Textiles"
                    }
                ]
            },
            {
                "filter_key": "stock_filter",
                "filter_name_key": "stocks",
                "heading": "Stock",
                "values": [
                    {
                        "doc_count": 4,
                        "key": "ABB India Ltd",
                        "stock_id": "61"
                    },
                    {
                        "doc_count": 6,
                        "key": "ACC Ltd",
                        "stock_id": "133"
                    },
                    {
                        "doc_count": 4,
                        "key": "AIA Engineering Ltd",
                        "stock_id": "62"
                    },
                    {
                        "doc_count": 3,
                        "key": "APL Apollo Tubes Ltd",
                        "stock_id": "2006"
                    },
                    {
                        "doc_count": 4,
                        "key": "AU Small Finance Bank Ltd",
                        "stock_id": "2"
                    },
                    {
                        "doc_count": 6,
                        "key": "Aarti Industries Ltd",
                        "stock_id": "338"
                    },
                    {
                        "doc_count": 3,
                        "key": "Aavas Financiers Ltd",
                        "stock_id": "343"
                    },
                    {
                        "doc_count": 5,
                        "key": "Adani Ports and Special Economic Zone Ltd",
                        "stock_id": "95"
                    },
                    {
                        "doc_count": 4,
                        "key": "Aditya Birla Fashion & Retail Ltd",
                        "stock_id": "219"
                    },
                    {
                        "doc_count": 4,
                        "key": "Affle (India) Ltd",
                        "stock_id": "2025"
                    },
                    {
                        "doc_count": 5,
                        "key": "Ahluwalia Contracts (India) Ltd",
                        "stock_id": "96"
                    },
                    {
                        "doc_count": 6,
                        "key": "Alembic Pharmaceuticals Ltd",
                        "stock_id": "332"
                    },
                    {
                        "doc_count": 6,
                        "key": "Alkem Laboratories Ltd",
                        "stock_id": "143"
                    },
                    {
                        "doc_count": 4,
                        "key": "Amara Raja Batteries Ltd",
                        "stock_id": "112"
                    },
                    {
                        "doc_count": 5,
                        "key": "Amber Enterprises India Ltd",
                        "stock_id": "63"
                    },
                    {
                        "doc_count": 5,
                        "key": "Ambuja Cements Ltd",
                        "stock_id": "134"
                    },
                    {
                        "doc_count": 6,
                        "key": "Apollo Hospitals Enterprise Ltd",
                        "stock_id": "144"
                    },
                    {
                        "doc_count": 5,
                        "key": "Apollo Tyres Ltd",
                        "stock_id": "113"
                    },
                    {
                        "doc_count": 1,
                        "key": "Aptus Value Housing Finance India Ltd",
                        "stock_id": "1747"
                    },
                    {
                        "doc_count": 6,
                        "key": "Ashok Leyland Ltd",
                        "stock_id": "111"
                    },
                    {
                        "doc_count": 7,
                        "key": "Ashoka Buildcon Ltd",
                        "stock_id": "97"
                    },
                    {
                        "doc_count": 4,
                        "key": "Asian Paints Ltd",
                        "stock_id": "200"
                    },
                    {
                        "doc_count": 4,
                        "key": "Aster DM Healthcare Ltd",
                        "stock_id": "145"
                    },
                    {
                        "doc_count": 3,
                        "key": "Astral Ltd",
                        "stock_id": "182"
                    },
                    {
                        "doc_count": 6,
                        "key": "Aurobindo Pharma Ltd",
                        "stock_id": "160"
                    },
                    {
                        "doc_count": 2,
                        "key": "Avanti Feeds Ltd",
                        "stock_id": "1989"
                    },
                    {
                        "doc_count": 17,
                        "key": "Axis Bank Ltd",
                        "stock_id": "1"
                    },
                    {
                        "doc_count": 1,
                        "key": "Bajaj Allianz Life insurance Co. Ltd",
                        "stock_id": "1755"
                    },
                    {
                        "doc_count": 5,
                        "key": "Bajaj Auto Ltd",
                        "stock_id": "114"
                    },
                    {
                        "doc_count": 3,
                        "key": "Bajaj Consumer Care Ltd",
                        "stock_id": "197"
                    },
                    {
                        "doc_count": 6,
                        "key": "Bajaj Finance Ltd",
                        "stock_id": "3"
                    },
                    {
                        "doc_count": 20,
                        "key": "Bajaj Finserv Ltd",
                        "stock_id": "35"
                    },
                    {
                        "doc_count": 4,
                        "key": "Bank of Baroda",
                        "stock_id": "4"
                    },
                    {
                        "doc_count": 4,
                        "key": "Bata India Ltd",
                        "stock_id": "216"
                    },
                    {
                        "doc_count": 4,
                        "key": "Berger Paints India Ltd",
                        "stock_id": "201"
                    },
                    {
                        "doc_count": 3,
                        "key": "Bharat Electronics Ltd",
                        "stock_id": "1507"
                    },
                    {
                        "doc_count": 4,
                        "key": "Bharat Forge Ltd",
                        "stock_id": "117"
                    },
                    {
                        "doc_count": 5,
                        "key": "Bharat Petroleum Corporation Ltd",
                        "stock_id": "82"
                    },
                    {
                        "doc_count": 7,
                        "key": "Bharti Airtel Ltd",
                        "stock_id": "37"
                    },
                    {
                        "doc_count": 9,
                        "key": "Biocon Ltd",
                        "stock_id": "146"
                    },
                    {
                        "doc_count": 5,
                        "key": "Birla Corporation Ltd",
                        "stock_id": "135"
                    },
                    {
                        "doc_count": 7,
                        "key": "Birlasoft Ltd",
                        "stock_id": "270"
                    },
                    {
                        "doc_count": 1,
                        "key": "Blue Dart Express Ltd",
                        "stock_id": "167"
                    },
                    {
                        "doc_count": 5,
                        "key": "Blue Star Ltd",
                        "stock_id": "65"
                    },
                    {
                        "doc_count": 5,
                        "key": "Brigade Enterprises Ltd",
                        "stock_id": "1511"
                    },
                    {
                        "doc_count": 5,
                        "key": "Britannia Industries Ltd",
                        "stock_id": "205"
                    },
                    {
                        "doc_count": 6,
                        "key": "Cadila Healthcare Ltd",
                        "stock_id": "156"
                    },
                    {
                        "doc_count": 4,
                        "key": "Canara Bank",
                        "stock_id": "5"
                    },
                    {
                        "doc_count": 3,
                        "key": "Capacite Infraprojects Ltd",
                        "stock_id": "98"
                    },
                    {
                        "doc_count": 5,
                        "key": "Carborundum Universal Ltd",
                        "stock_id": "67"
                    },
                    {
                        "doc_count": 5,
                        "key": "Ceat Ltd",
                        "stock_id": "115"
                    },
                    {
                        "doc_count": 4,
                        "key": "Central Depository Services (India) Ltd",
                        "stock_id": "1994"
                    },
                    {
                        "doc_count": 4,
                        "key": "Century Plyboards (India) Ltd",
                        "stock_id": "179"
                    },
                    {
                        "doc_count": 4,
                        "key": "Cera Sanitaryware Ltd",
                        "stock_id": "178"
                    },
                    {
                        "doc_count": 12,
                        "key": "Cholamandalam Financial Holdings Ltd",
                        "stock_id": "340"
                    },
                    {
                        "doc_count": 3,
                        "key": "Cholamandalam Investment and Finance Company Ltd",
                        "stock_id": "6"
                    },
                    {
                        "doc_count": 7,
                        "key": "Cipla Ltd",
                        "stock_id": "147"
                    },
                    {
                        "doc_count": 4,
                        "key": "City Union Bank Ltd",
                        "stock_id": "7"
                    },
                    {
                        "doc_count": 4,
                        "key": "Coal India Ltd",
                        "stock_id": "99"
                    },
                    {
                        "doc_count": 5,
                        "key": "Coforge Ltd",
                        "stock_id": "53"
                    },
                    {
                        "doc_count": 5,
                        "key": "Container Corporation of India Ltd",
                        "stock_id": "168"
                    },
                    {
                        "doc_count": 5,
                        "key": "Coromandel International Ltd",
                        "stock_id": "161"
                    },
                    {
                        "doc_count": 3,
                        "key": "Craftsman Automation Ltd",
                        "stock_id": "2026"
                    },
                    {
                        "doc_count": 5,
                        "key": "Crompton Greaves Consumer Electricals Ltd",
                        "stock_id": "66"
                    },
                    {
                        "doc_count": 4,
                        "key": "Cummins India Ltd",
                        "stock_id": "76"
                    },
                    {
                        "doc_count": 5,
                        "key": "Cyient Ltd",
                        "stock_id": "47"
                    },
                    {
                        "doc_count": 4,
                        "key": "DCB Bank Ltd",
                        "stock_id": "8"
                    },
                    {
                        "doc_count": 4,
                        "key": "DLF Ltd",
                        "stock_id": "2009"
                    },
                    {
                        "doc_count": 4,
                        "key": "Dabur India Ltd",
                        "stock_id": "206"
                    },
                    {
                        "doc_count": 5,
                        "key": "Dalmia Bharat Ltd",
                        "stock_id": "136"
                    },
                    {
                        "doc_count": 7,
                        "key": "Dhanuka Agritech Ltd",
                        "stock_id": "163"
                    },
                    {
                        "doc_count": 6,
                        "key": "Dilip Buildcon Ltd",
                        "stock_id": "100"
                    },
                    {
                        "doc_count": 7,
                        "key": "Divi's Laboratories Ltd",
                        "stock_id": "278"
                    },
                    {
                        "doc_count": 5,
                        "key": "Dixon Technologies (India) Ltd",
                        "stock_id": "68"
                    },
                    {
                        "doc_count": 5,
                        "key": "Dr. Lal PathLabs Ltd",
                        "stock_id": "148"
                    },
                    {
                        "doc_count": 7,
                        "key": "Dr. Reddy's Laboratories Ltd",
                        "stock_id": "159"
                    },
                    {
                        "doc_count": 6,
                        "key": "Eicher Motors Ltd",
                        "stock_id": "118"
                    },
                    {
                        "doc_count": 4,
                        "key": "Elgi Equipments Ltd",
                        "stock_id": "80"
                    },
                    {
                        "doc_count": 4,
                        "key": "Emami Ltd",
                        "stock_id": "211"
                    },
                    {
                        "doc_count": 4,
                        "key": "Endurance Technologies Ltd",
                        "stock_id": "1537"
                    },
                    {
                        "doc_count": 6,
                        "key": "Escorts Ltd",
                        "stock_id": "344"
                    },
                    {
                        "doc_count": 4,
                        "key": "Exide Industries Ltd",
                        "stock_id": "119"
                    },
                    {
                        "doc_count": 4,
                        "key": "Firstsource Solutions Ltd",
                        "stock_id": "39"
                    },
                    {
                        "doc_count": 6,
                        "key": "GAIL (India) Ltd",
                        "stock_id": "83"
                    },
                    {
                        "doc_count": 5,
                        "key": "Gateway Distriparks Ltd",
                        "stock_id": "169"
                    },
                    {
                        "doc_count": 4,
                        "key": "General Insurance Corporation of India Ltd",
                        "stock_id": "29"
                    },
                    {
                        "doc_count": 6,
                        "key": "Glenmark Pharmaceuticals Ltd",
                        "stock_id": "149"
                    },
                    {
                        "doc_count": 3,
                        "key": "Godrej Agrovet Ltd",
                        "stock_id": "1992"
                    },
                    {
                        "doc_count": 6,
                        "key": "Godrej Properties Ltd",
                        "stock_id": "192"
                    },
                    {
                        "doc_count": 6,
                        "key": "Granules India Ltd",
                        "stock_id": "150"
                    },
                    {
                        "doc_count": 5,
                        "key": "Grindwell Norton Ltd",
                        "stock_id": "69"
                    },
                    {
                        "doc_count": 5,
                        "key": "Gujarat Gas Ltd",
                        "stock_id": "89"
                    },
                    {
                        "doc_count": 4,
                        "key": "Gujarat Pipavav Port Ltd",
                        "stock_id": "101"
                    },
                    {
                        "doc_count": 3,
                        "key": "Gujarat State Petronet Ltd",
                        "stock_id": "90"
                    },
                    {
                        "doc_count": 6,
                        "key": "HCL Technologies Ltd",
                        "stock_id": "40"
                    },
                    {
                        "doc_count": 7,
                        "key": "HDFC Bank Ltd",
                        "stock_id": "12"
                    },
                    {
                        "doc_count": 15,
                        "key": "HDFC Life Insurance Company Ltd",
                        "stock_id": "31"
                    },
                    {
                        "doc_count": 5,
                        "key": "Havells India Ltd",
                        "stock_id": "70"
                    },
                    {
                        "doc_count": 3,
                        "key": "HealthCare Global Enterprises Ltd",
                        "stock_id": "151"
                    },
                    {
                        "doc_count": 5,
                        "key": "Hero MotoCorp Ltd",
                        "stock_id": "120"
                    },
                    {
                        "doc_count": 5,
                        "key": "Hindustan Petroleum Corporation Ltd",
                        "stock_id": "84"
                    },
                    {
                        "doc_count": 5,
                        "key": "Hindustan Unilever Ltd",
                        "stock_id": "208"
                    },
                    {
                        "doc_count": 3,
                        "key": "Housing Development Finance Corporation Ltd",
                        "stock_id": "11"
                    },
                    {
                        "doc_count": 4,
                        "key": "ICICI Bank Ltd",
                        "stock_id": "13"
                    },
                    {
                        "doc_count": 13,
                        "key": "ICICI Lombard General Insurance Company Ltd",
                        "stock_id": "36"
                    },
                    {
                        "doc_count": 13,
                        "key": "ICICI Prudential Life Insurance Company Ltd",
                        "stock_id": "33"
                    },
                    {
                        "doc_count": 5,
                        "key": "ICICI Securities Ltd",
                        "stock_id": "1581"
                    },
                    {
                        "doc_count": 3,
                        "key": "IRB Infrastructure Developers Ltd",
                        "stock_id": "102"
                    },
                    {
                        "doc_count": 4,
                        "key": "ITC Ltd",
                        "stock_id": "209"
                    },
                    {
                        "doc_count": 5,
                        "key": "IndiaMART InterMESH Ltd",
                        "stock_id": "337"
                    },
                    {
                        "doc_count": 4,
                        "key": "Indian Energy Exchange Ltd",
                        "stock_id": "1592"
                    },
                    {
                        "doc_count": 5,
                        "key": "Indian Oil Corporation Ltd",
                        "stock_id": "86"
                    },
                    {
                        "doc_count": 6,
                        "key": "Indian Railway Catering and Tourism Corporation Ltd",
                        "stock_id": "345"
                    },
                    {
                        "doc_count": 4,
                        "key": "Indraprastha Gas Ltd",
                        "stock_id": "91"
                    },
                    {
                        "doc_count": 6,
                        "key": "Indus Towers Ltd",
                        "stock_id": "1997"
                    },
                    {
                        "doc_count": 4,
                        "key": "IndusInd Bank Ltd",
                        "stock_id": "14"
                    },
                    {
                        "doc_count": 5,
                        "key": "Info Edge (India) Ltd",
                        "stock_id": "41"
                    },
                    {
                        "doc_count": 6,
                        "key": "Infosys Ltd",
                        "stock_id": "42"
                    },
                    {
                        "doc_count": 6,
                        "key": "Inox Leisure Ltd",
                        "stock_id": "189"
                    },
                    {
                        "doc_count": 4,
                        "key": "Intellect Design Arena Ltd",
                        "stock_id": "43"
                    },
                    {
                        "doc_count": 4,
                        "key": "InterGlobe Aviation Ltd",
                        "stock_id": "170"
                    },
                    {
                        "doc_count": 5,
                        "key": "JK Cement Ltd",
                        "stock_id": "336"
                    },
                    {
                        "doc_count": 6,
                        "key": "JK Lakshmi Cement Ltd",
                        "stock_id": "142"
                    },
                    {
                        "doc_count": 6,
                        "key": "JSW Steel Ltd",
                        "stock_id": "2002"
                    },
                    {
                        "doc_count": 6,
                        "key": "Jindal Steel & Power Ltd",
                        "stock_id": "2001"
                    },
                    {
                        "doc_count": 5,
                        "key": "Jubilant Foodworks Ltd",
                        "stock_id": "334"
                    },
                    {
                        "doc_count": 4,
                        "key": "Just Dial Ltd",
                        "stock_id": "44"
                    },
                    {
                        "doc_count": 2,
                        "key": "Jyothy Labs Ltd",
                        "stock_id": "212"
                    },
                    {
                        "doc_count": 4,
                        "key": "KEC International Ltd",
                        "stock_id": "72"
                    },
                    {
                        "doc_count": 8,
                        "key": "KNR Constructions Ltd",
                        "stock_id": "105"
                    },
                    {
                        "doc_count": 4,
                        "key": "KPIT Technologies Ltd",
                        "stock_id": "46"
                    },
                    {
                        "doc_count": 3,
                        "key": "KPR Mill Ltd",
                        "stock_id": "1615"
                    },
                    {
                        "doc_count": 4,
                        "key": "Kajaria Ceramics Ltd",
                        "stock_id": "176"
                    },
                    {
                        "doc_count": 4,
                        "key": "Kalpataru Power Transmission Ltd",
                        "stock_id": "71"
                    },
                    {
                        "doc_count": 3,
                        "key": "Kansai Nerolac Paints Ltd",
                        "stock_id": "202"
                    },
                    {
                        "doc_count": 4,
                        "key": "Karur Vysya Bank Ltd",
                        "stock_id": "15"
                    },
                    {
                        "doc_count": 10,
                        "key": "Kotak Mahindra Bank Ltd",
                        "stock_id": "16"
                    },
                    {
                        "doc_count": 4,
                        "key": "L&T Technology Services Ltd",
                        "stock_id": "48"
                    },
                    {
                        "doc_count": 2,
                        "key": "LA Opala RG Ltd",
                        "stock_id": "92"
                    },
                    {
                        "doc_count": 3,
                        "key": "LIC Housing Finance Ltd",
                        "stock_id": "17"
                    },
                    {
                        "doc_count": 4,
                        "key": "Larsen & Toubro Infotech Ltd",
                        "stock_id": "45"
                    },
                    {
                        "doc_count": 6,
                        "key": "Larsen & Toubro Ltd",
                        "stock_id": "106"
                    },
                    {
                        "doc_count": 4,
                        "key": "Laurus Labs Ltd",
                        "stock_id": "333"
                    },
                    {
                        "doc_count": 5,
                        "key": "Lupin Ltd",
                        "stock_id": "158"
                    },
                    {
                        "doc_count": 4,
                        "key": "Mahanagar Gas Ltd",
                        "stock_id": "93"
                    },
                    {
                        "doc_count": 3,
                        "key": "Mahindra & Mahindra Financial Services Ltd",
                        "stock_id": "18"
                    },
                    {
                        "doc_count": 6,
                        "key": "Mahindra & Mahindra Ltd",
                        "stock_id": "121"
                    },
                    {
                        "doc_count": 5,
                        "key": "Mahindra Logistics Ltd",
                        "stock_id": "171"
                    },
                    {
                        "doc_count": 5,
                        "key": "Marico Ltd",
                        "stock_id": "213"
                    },
                    {
                        "doc_count": 6,
                        "key": "Maruti Suzuki India Ltd",
                        "stock_id": "122"
                    },
                    {
                        "doc_count": 14,
                        "key": "Max Financial Services Ltd",
                        "stock_id": "30"
                    },
                    {
                        "doc_count": 4,
                        "key": "Max Healthcare Institute Ltd",
                        "stock_id": "1998"
                    },
                    {
                        "doc_count": 5,
                        "key": "Metropolis Healthcare Ltd",
                        "stock_id": "341"
                    },
                    {
                        "doc_count": 1,
                        "key": "Minda Corporation Ltd",
                        "stock_id": "1639"
                    },
                    {
                        "doc_count": 3,
                        "key": "Minda Industries Ltd",
                        "stock_id": "123"
                    },
                    {
                        "doc_count": 4,
                        "key": "Mindtree Ltd",
                        "stock_id": "49"
                    },
                    {
                        "doc_count": 4,
                        "key": "Multi Commodity Exchange of India Ltd",
                        "stock_id": "229"
                    },
                    {
                        "doc_count": 3,
                        "key": "Muthoot Finance Ltd",
                        "stock_id": "308"
                    },
                    {
                        "doc_count": 5,
                        "key": "NTPC Ltd",
                        "stock_id": "107"
                    },
                    {
                        "doc_count": 4,
                        "key": "Narayana Hrudayalaya Ltd",
                        "stock_id": "152"
                    },
                    {
                        "doc_count": 1,
                        "key": "National Stock Exchange of India Ltd",
                        "stock_id": "1647"
                    },
                    {
                        "doc_count": 4,
                        "key": "Nazara Technologies Ltd",
                        "stock_id": "2000"
                    },
                    {
                        "doc_count": 5,
                        "key": "Nestle India Ltd",
                        "stock_id": "214"
                    },
                    {
                        "doc_count": 12,
                        "key": "New India Assurance Company Ltd",
                        "stock_id": "34"
                    },
                    {
                        "doc_count": 3,
                        "key": "Nuvoco Vistas Corporation Ltd",
                        "stock_id": "2016"
                    },
                    {
                        "doc_count": 5,
                        "key": "Oberoi Realty Ltd",
                        "stock_id": "193"
                    },
                    {
                        "doc_count": 3,
                        "key": "Oil India Ltd",
                        "stock_id": "85"
                    },
                    {
                        "doc_count": 5,
                        "key": "Oil and Natural Gas Corporation Ltd",
                        "stock_id": "87"
                    },
                    {
                        "doc_count": 5,
                        "key": "Orient Electric Ltd",
                        "stock_id": "1656"
                    },
                    {
                        "doc_count": 1,
                        "key": "Others",
                        "stock_id": null
                    },
                    {
                        "doc_count": 6,
                        "key": "PI Industries Ltd",
                        "stock_id": "162"
                    },
                    {
                        "doc_count": 8,
                        "key": "PNC Infratech Ltd",
                        "stock_id": "109"
                    },
                    {
                        "doc_count": 5,
                        "key": "PSP Projects Ltd",
                        "stock_id": "104"
                    },
                    {
                        "doc_count": 6,
                        "key": "PVR Ltd",
                        "stock_id": "190"
                    },
                    {
                        "doc_count": 4,
                        "key": "Page Industries Ltd",
                        "stock_id": "198"
                    },
                    {
                        "doc_count": 4,
                        "key": "Persistent Systems Ltd",
                        "stock_id": "50"
                    },
                    {
                        "doc_count": 4,
                        "key": "Petronet LNG Ltd",
                        "stock_id": "88"
                    },
                    {
                        "doc_count": 4,
                        "key": "Phoenix Mills Ltd",
                        "stock_id": "339"
                    },
                    {
                        "doc_count": 3,
                        "key": "Pidilite Industries Ltd",
                        "stock_id": "204"
                    },
                    {
                        "doc_count": 5,
                        "key": "Polycab India Ltd",
                        "stock_id": "342"
                    },
                    {
                        "doc_count": 5,
                        "key": "Power Grid Corporation of India Ltd",
                        "stock_id": "103"
                    },
                    {
                        "doc_count": 6,
                        "key": "Prestige Estates Projects Ltd",
                        "stock_id": "194"
                    },
                    {
                        "doc_count": 4,
                        "key": "Punjab National Bank",
                        "stock_id": "19"
                    },
                    {
                        "doc_count": 4,
                        "key": "Quess Corp Ltd",
                        "stock_id": "59"
                    },
                    {
                        "doc_count": 7,
                        "key": "RBL Bank",
                        "stock_id": "20"
                    },
                    {
                        "doc_count": 6,
                        "key": "Rallis India Ltd",
                        "stock_id": "164"
                    },
                    {
                        "doc_count": 4,
                        "key": "Redington (India) Ltd",
                        "stock_id": "51"
                    },
                    {
                        "doc_count": 4,
                        "key": "Relaxo Footwears Ltd",
                        "stock_id": "258"
                    },
                    {
                        "doc_count": 7,
                        "key": "Reliance Industries Ltd",
                        "stock_id": "94"
                    },
                    {
                        "doc_count": 3,
                        "key": "Repco Home Finance Ltd",
                        "stock_id": "28"
                    },
                    {
                        "doc_count": 14,
                        "key": "SBI Life Insurance Company Ltd",
                        "stock_id": "32"
                    },
                    {
                        "doc_count": 2,
                        "key": "SIS Ltd",
                        "stock_id": "52"
                    },
                    {
                        "doc_count": 3,
                        "key": "SKF India Ltd",
                        "stock_id": "127"
                    },
                    {
                        "doc_count": 6,
                        "key": "SRF Ltd",
                        "stock_id": "1693"
                    },
                    {
                        "doc_count": 3,
                        "key": "Sadbhav Engineering Ltd",
                        "stock_id": "108"
                    },
                    {
                        "doc_count": 5,
                        "key": "Sagar Cements Ltd",
                        "stock_id": "139"
                    },
                    {
                        "doc_count": 5,
                        "key": "Schaeffler India Ltd",
                        "stock_id": "126"
                    },
                    {
                        "doc_count": 6,
                        "key": "Sharda Cropchem  Ltd",
                        "stock_id": "165"
                    },
                    {
                        "doc_count": 5,
                        "key": "Shree Cement Ltd",
                        "stock_id": "140"
                    },
                    {
                        "doc_count": 4,
                        "key": "Shriram City Union Finance Ltd",
                        "stock_id": "21"
                    },
                    {
                        "doc_count": 5,
                        "key": "Shriram Transport Finance Company Ltd",
                        "stock_id": "22"
                    },
                    {
                        "doc_count": 4,
                        "key": "Siemens Ltd",
                        "stock_id": "331"
                    },
                    {
                        "doc_count": 5,
                        "key": "Sobha Ltd",
                        "stock_id": "195"
                    },
                    {
                        "doc_count": 4,
                        "key": "Somany Ceramics Ltd",
                        "stock_id": "177"
                    },
                    {
                        "doc_count": 1,
                        "key": "Star Health and Allied Insurance Ltd",
                        "stock_id": "4505"
                    },
                    {
                        "doc_count": 5,
                        "key": "State Bank of India",
                        "stock_id": "24"
                    },
                    {
                        "doc_count": 6,
                        "key": "Steel Authority of India Ltd",
                        "stock_id": "2003"
                    },
                    {
                        "doc_count": 1,
                        "key": "Strategy",
                        "stock_id": "4027"
                    },
                    {
                        "doc_count": 5,
                        "key": "Sun Pharmaceutical Industries Ltd",
                        "stock_id": "157"
                    },
                    {
                        "doc_count": 5,
                        "key": "Sun TV Network Ltd",
                        "stock_id": "187"
                    },
                    {
                        "doc_count": 4,
                        "key": "Sundaram Finance Ltd",
                        "stock_id": "25"
                    },
                    {
                        "doc_count": 4,
                        "key": "Sundram Fasteners Ltd",
                        "stock_id": "128"
                    },
                    {
                        "doc_count": 4,
                        "key": "Suprajit Engineering Ltd",
                        "stock_id": "129"
                    },
                    {
                        "doc_count": 4,
                        "key": "Supreme Industries Ltd",
                        "stock_id": "183"
                    },
                    {
                        "doc_count": 4,
                        "key": "Syngene International Ltd",
                        "stock_id": "153"
                    },
                    {
                        "doc_count": 4,
                        "key": "TCI Express Ltd",
                        "stock_id": "172"
                    },
                    {
                        "doc_count": 5,
                        "key": "TV18 Broadcast Ltd",
                        "stock_id": "191"
                    },
                    {
                        "doc_count": 5,
                        "key": "TVS Motor Company Ltd",
                        "stock_id": "131"
                    },
                    {
                        "doc_count": 7,
                        "key": "Tata Consultancy Services Ltd",
                        "stock_id": "55"
                    },
                    {
                        "doc_count": 5,
                        "key": "Tata Consumer Products Ltd",
                        "stock_id": "1970"
                    },
                    {
                        "doc_count": 4,
                        "key": "Tata Elxsi Ltd",
                        "stock_id": "56"
                    },
                    {
                        "doc_count": 6,
                        "key": "Tata Steel Ltd",
                        "stock_id": "2004"
                    },
                    {
                        "doc_count": 5,
                        "key": "TeamLease Services Ltd",
                        "stock_id": "60"
                    },
                    {
                        "doc_count": 5,
                        "key": "Tech Mahindra Ltd",
                        "stock_id": "57"
                    },
                    {
                        "doc_count": 4,
                        "key": "The Federal Bank Ltd",
                        "stock_id": "10"
                    },
                    {
                        "doc_count": 5,
                        "key": "The Ramco Cements Ltd",
                        "stock_id": "137"
                    },
                    {
                        "doc_count": 4,
                        "key": "The South Indian Bank Ltd",
                        "stock_id": "23"
                    },
                    {
                        "doc_count": 4,
                        "key": "Thermax Ltd",
                        "stock_id": "73"
                    },
                    {
                        "doc_count": 3,
                        "key": "Thyrocare Technlogoies Ltd",
                        "stock_id": "154"
                    },
                    {
                        "doc_count": 1,
                        "key": "Timken India Ltd",
                        "stock_id": "130"
                    },
                    {
                        "doc_count": 4,
                        "key": "Titan Company Ltd",
                        "stock_id": "217"
                    },
                    {
                        "doc_count": 4,
                        "key": "Torrent Pharmaceuticals Ltd",
                        "stock_id": "155"
                    },
                    {
                        "doc_count": 5,
                        "key": "Torrent Power Ltd",
                        "stock_id": "243"
                    },
                    {
                        "doc_count": 4,
                        "key": "Transport Corporation of India Ltd",
                        "stock_id": "173"
                    },
                    {
                        "doc_count": 3,
                        "key": "Trent Ltd",
                        "stock_id": "218"
                    },
                    {
                        "doc_count": 4,
                        "key": "Triveni Turbine Ltd",
                        "stock_id": "79"
                    },
                    {
                        "doc_count": 4,
                        "key": "Tube Investments of India Ltd",
                        "stock_id": "1716"
                    },
                    {
                        "doc_count": 6,
                        "key": "UPL Ltd",
                        "stock_id": "166"
                    },
                    {
                        "doc_count": 4,
                        "key": "Ujjivan Small Finance Bank Ltd",
                        "stock_id": "346"
                    },
                    {
                        "doc_count": 5,
                        "key": "UltraTech Cement Ltd",
                        "stock_id": "141"
                    },
                    {
                        "doc_count": 4,
                        "key": "United Breweries Ltd",
                        "stock_id": "185"
                    },
                    {
                        "doc_count": 4,
                        "key": "United Spirits Ltd",
                        "stock_id": "184"
                    },
                    {
                        "doc_count": 5,
                        "key": "V-Guard Industries Ltd",
                        "stock_id": "81"
                    },
                    {
                        "doc_count": 4,
                        "key": "V-Mart Retail Ltd",
                        "stock_id": "220"
                    },
                    {
                        "doc_count": 4,
                        "key": "VIP Industries Ltd",
                        "stock_id": "199"
                    },
                    {
                        "doc_count": 4,
                        "key": "VRL Logistics Ltd",
                        "stock_id": "174"
                    },
                    {
                        "doc_count": 4,
                        "key": "VST Tillers Tractors Ltd",
                        "stock_id": "116"
                    },
                    {
                        "doc_count": 3,
                        "key": "Vardhman Textiles Ltd",
                        "stock_id": "1724"
                    },
                    {
                        "doc_count": 5,
                        "key": "Voltas Ltd",
                        "stock_id": "74"
                    },
                    {
                        "doc_count": 6,
                        "key": "Whirlpool of India Ltd",
                        "stock_id": "75"
                    },
                    {
                        "doc_count": 5,
                        "key": "Wipro Ltd",
                        "stock_id": "58"
                    },
                    {
                        "doc_count": 1,
                        "key": "Wonderla Holidays Ltd",
                        "stock_id": "221"
                    },
                    {
                        "doc_count": 4,
                        "key": "ZF Commercial Vehicles Control Systems India Ltd",
                        "stock_id": "132"
                    },
                    {
                        "doc_count": 7,
                        "key": "Zee Entertainment Enterprises",
                        "stock_id": "186"
                    },
                    {
                        "doc_count": 4,
                        "key": "Zomato Ltd",
                        "stock_id": "2027"
                    },
                    {
                        "doc_count": 4,
                        "key": "Zydus Wellness Ltd",
                        "stock_id": "215"
                    },
                    {
                        "doc_count": 4,
                        "key": "eClerx Services Ltd",
                        "stock_id": "38"
                    }
                ]
            },
            {
                "filter_key": "author_filter",
                "filter_name_key": "author_names",
                "heading": "Author",
                "values": [
                    {
                        "doc_count": 11,
                        "key": "Abhinav Vijayaraj"
                    },
                    {
                        "doc_count": 153,
                        "key": "Abhinesh Vijayaraj"
                    },
                    {
                        "doc_count": 1,
                        "key": "Ankit Dangayach"
                    },
                    {
                        "doc_count": 26,
                        "key": "Arjun N"
                    },
                    {
                        "doc_count": 41,
                        "key": "Arun Prasath"
                    },
                    {
                        "doc_count": 98,
                        "key": "Bharanidhar Vijayakumar"
                    },
                    {
                        "doc_count": 1,
                        "key": "Chenna Avinash"
                    },
                    {
                        "doc_count": 100,
                        "key": "Dr Harith Ahamed"
                    },
                    {
                        "doc_count": 16,
                        "key": "Gaurav Nagori"
                    },
                    {
                        "doc_count": 57,
                        "key": "Gautam Singh"
                    },
                    {
                        "doc_count": 2,
                        "key": "Gautham Balasubramani"
                    },
                    {
                        "doc_count": 104,
                        "key": "Girish Choudhary"
                    },
                    {
                        "doc_count": 20,
                        "key": "Gnanasundaram Saminathan"
                    },
                    {
                        "doc_count": 212,
                        "key": "Hardik Ruparel"
                    },
                    {
                        "doc_count": 1,
                        "key": "Hitesh Punjani"
                    },
                    {
                        "doc_count": 15,
                        "key": "Krishna Prakash R"
                    },
                    {
                        "doc_count": 8,
                        "key": "Krupashankar N.J."
                    },
                    {
                        "doc_count": 10,
                        "key": "Madhav PVR"
                    },
                    {
                        "doc_count": 142,
                        "key": "Mukesh Saraf "
                    },
                    {
                        "doc_count": 3,
                        "key": "Mussadiq Ahmed"
                    },
                    {
                        "doc_count": 18,
                        "key": "Navin Babu"
                    },
                    {
                        "doc_count": 16,
                        "key": "Omprakash Kavadi"
                    },
                    {
                        "doc_count": 2,
                        "key": "Raghav Bapu"
                    },
                    {
                        "doc_count": 22,
                        "key": "Ramakrishnan Seshan"
                    },
                    {
                        "doc_count": 6,
                        "key": "Ranganathan V S"
                    },
                    {
                        "doc_count": 93,
                        "key": "Ravi Swaminathan"
                    },
                    {
                        "doc_count": 81,
                        "key": "Sanketh Godha"
                    },
                    {
                        "doc_count": 24,
                        "key": "Santosh Kumar C"
                    },
                    {
                        "doc_count": 15,
                        "key": "Seetharaman R"
                    },
                    {
                        "doc_count": 6,
                        "key": "Somaiah Valliyappan"
                    },
                    {
                        "doc_count": 111,
                        "key": "Soumitra Chatterjee"
                    },
                    {
                        "doc_count": 245,
                        "key": "Spark Research"
                    },
                    {
                        "doc_count": 1,
                        "key": "Sriram Sampath"
                    },
                    {
                        "doc_count": 12,
                        "key": "Sumit Aggarwal"
                    },
                    {
                        "doc_count": 115,
                        "key": "Tejash Shah"
                    },
                    {
                        "doc_count": 15,
                        "key": "Uttham Kumar R"
                    },
                    {
                        "doc_count": 23,
                        "key": "Vijayaraghavan Swaminathan"
                    },
                    {
                        "doc_count": 23,
                        "key": "Vikram Subramanian"
                    },
                    {
                        "doc_count": 98,
                        "key": "Vishnu Kumar A S"
                    }
                ]
            },
            {
                "filter_key": "recommendation_filter",
                "filter_name_key": "recommendations",
                "heading": "Recommendation",
                "values": [
                    {
                        "doc_count": 31,
                        "key": "SELL"
                    },
                    {
                        "doc_count": 59,
                        "key": "ADD"
                    },
                    {
                        "doc_count": 105,
                        "key": "BUY"
                    },
                    {
                        "doc_count": 37,
                        "key": "REDUCE"
                    }
                ]
            }
        ]
    },
    "status": true
}

module.exports = {
    fetch_recommended_reports,
    fetch_reports,
    podcast_list,
    podcast_list_link
}