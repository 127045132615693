export function validJsonKey(obj, keyName, type, notEmpty) {
    if(obj.hasOwnProperty(keyName)) {
        if (typeof obj.keyName === type) {
            if (obj.keyName) {
                if(notEmpty) {
                    return obj.keyName.length > 0;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}
