import { toJS } from "mobx";
import {
    getDocumentMetaFrame,
    getDocumentMetaTemplate,
    getLoadedDocumentMetaTemplate,
    getSnippetsTemplate
} from "../utils/Document";
import { filterTemplate } from "../templates/PodCastList";

let toJs = require('lodash.clonedeep');

class PodcastSearchStore {
    apiCallActive = true;
    filters = toJs(filterTemplate);
    results = null;
    visitCounter = 0; //Visits to the app are recorded in this counter
    resultViewer = {
        apiCallActive: true,
        results: null,
    };
    pagination = {
        currentPage: 0,
        totalPages: 0,
    };
    previewActive = false;
    snippets = getSnippetsTemplate();
    selectedDocumentId = '';
    selectedDocumentSource = '';
    selectedDocumentIsOpen = false;
    selectedDocumentBlob = ``;
    selectedSnippetSearchTerms = '';
    selectedSnippetPageNum = 1;
    sharedDocumentId = '';
    triggerForClientDownload = false;
    tagify = {
        mainSearch: {
            input: null
        },
        advanceSearch: {
            sector: null,
            stock: null,
            report_type: null,
            recommendation: null,
            include_all: null,
            include_any: null,
            exclude_all: null,
        }
    };
    filterPanel = toJS(filterTemplate);
    temporarySharedDocumentId = "";

    setFilterPanel(data) {
        this.filterPanel = data;
    }

    incrementVisitCounter() {
        this.visitCounter++;
    }

    setApiCallActive(apiCallActive) {
        this.apiCallActive = apiCallActive;
    }

    setOpenPDFModal(openPDFModal) {
        this.openPDFModal = openPDFModal;
    }

    setResults(results) {
        this.results = toJS(results);
    }

    setResultViewer(apiCallActive, results = null) {
        this.resultViewer.apiCallActive = apiCallActive;
        this.resultViewer.results = (!results) ? null : toJs(results);
    }

    setFilterViewer(apiCallActive, results = null) {
        this.filterViewer.apiCallActive = apiCallActive;
        this.filterViewer.results = (!results) ? null : toJs(results);
    }
    setPreviewActive(previewActive) {
        this.previewActive = previewActive;
    }

    setSnippets(snippets, placeholder = false) {
        this.snippets.data = toJS(snippets);
        this.snippets.placeholder = placeholder;
    }

    setCurrentPage(pageNumber) {
        this.pagination.currentPage = pageNumber;
    }

    setTotalPages(pages) {
        this.pagination.totalPages = pages;
    }

    setFilters(filter, nestedFilter, val) {
        if (nestedFilter) {
            this.filters[filter][nestedFilter] = val;
        } else {
            this.filters[filter] = val;
        }
    }

    setSelectedDocumentId(selectedDocumentId) {
        this.selectedDocumentId = selectedDocumentId;
    }

    setSelectedDocumentSource(selectedDocumentSource) {
        this.selectedDocumentSource = selectedDocumentSource;
    }

    setSelectedDocumentIsOpen(selectedDocumentIsOpen) {
        this.selectedDocumentIsOpen = selectedDocumentIsOpen;
    }

    setSelectedDocumentBlob(selectedDocumentBlob) {
        this.selectedDocumentBlob = selectedDocumentBlob;
    }

    setSelectedSnippetPageNum(selectedSnippetPageNum) {
        this.selectedSnippetPageNum = selectedSnippetPageNum;
    }

    setSelectedSnippetSearchTerms(selectedSnippetSearchTerms) {
        this.selectedSnippetSearchTerms = selectedSnippetSearchTerms;
    }

    setSharedDocumentId(sharedDocumentId) {
        this.sharedDocumentId = sharedDocumentId;
    }

    setTriggerForClientDownload(triggerForClientDownload) {
        this.triggerForClientDownload = triggerForClientDownload;
    }

    resetFilters() {
        this.filters = toJs(filterTemplate);
    }

    resetFilterValues(filterValues) {
        this.filters = filterValues;
    }

    resetAllDocumentData() {
        this.selectedDocumentId = '';
        this.selectedDocumentSource = '';
        this.selectedDocumentIsOpen = false;
        this.selectedDocumentBlob = ``;
        this.selectedSnippetSearchTerms = '';
        this.selectedSnippetPageNum = 1;
    }
    resetFilterVal() {
        this.filterPanel = toJs(filterTemplate);
    }
    getFilters() {
        return this.filters;
    }

}

export default PodcastSearchStore;
