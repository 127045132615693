class DocumentUploadStore {
    tagify = {
        fieldInputs: {
            report_type: null,
            lead_authors: null,
            sub_authors: null,
            sector: null,
            stock: null,
            recommendation: null,
        }
    }
}

export default DocumentUploadStore;
