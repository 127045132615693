//This is an extension of Bootstrap 4 modal from the library 'reactstrap'

import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ShareDialog from "../ShareDialog";
import './style.scss';
import PDFViewer from "../PDFViewer";
import PDFJSBackend from "../../backends/pdfjs";
import documentSearch from "../../apis/documentSearch";
import {DS_SHARE_URL_PREFIX} from "../../constants/strings";
import axios from "axios";
import {getSession} from "../../utils/AuthUtils";


class PDFViewerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            closeAll: false,
            backdrop: true,
            size: 'lg',
            centered: true,
            scrollable: false,
            short_url: null,
            user_has_download_access: true,

        };

        //Refs
        this.shareDialogRef = React.createRef();
        this.pdfViewerRef = React.createRef();
        this.backend = new PDFJSBackend();
    }

    /**
     * @desc After the component is mounted, add an event listener
     * for type 'message' which will possible be shot by the component
     * enclosing this component
     */
    componentDidMount() {
        window.addEventListener('message', this.handleIframeTask);
        window.addEventListener("popstate", e => {
            this.toggle();
        });
        window.onbeforeunload = () => {
            this.toggle();
        };
    }

    /**
     * @desc This method is the handler for event type 'message' which are
     * supposed to be triggered by the PDFJs viewer, for events such as
     * 'Share', 'Download'.
     *
     * @param {event} e - React synthetic event
     */
    handleIframeTask = (e) => {
        // Code not needed as of now
        // if (e.origin !== process.env.HOST) {
        //     return;
        // }

        if (e.data && typeof e.data === 'string') {
            switch(e.data.toLowerCase()) {
                case 'show share dialog':
                    this.props.amplitudeAnalytics.documentAction({documentId: this.props.store.selectedDocumentId}, 'Shared');
                    this.getShortURL().then(()=>{});
                    break;
                case 'download single file':
                    this.props.store.setTriggerForClientDownload(true);
                    return ((this.pdfViewerRef.current) ? this.pdfViewerRef.current.trigger() : true);
                case 'close viewer':
                    this.setState({short_url: null})
                    return this.toggle();
                case 'check download permission':
                    this.checkUserDownloadPermission().then(()=>{})
            }
        }
    };


    toggleShareDialog = () => {
        let shareURL = this.state.short_url ? this.state.short_url : `${DS_SHARE_URL_PREFIX}${this.props.store.selectedDocumentId}`;
        this.shareDialogRef.current.setShareURL(shareURL);
        return ((this.shareDialogRef.current) ? this.shareDialogRef.current.show() : true);
    }

    getShortURL = async () => {
        const payload = {document_id: this.props.store.selectedDocumentId};
        let session =  await getSession();
        let config = { headers: { "Authorization": session.accessToken.jwtToken } }
        this.makeApiCall(`${process.env.REACT_APP_LIB_DOMAIN}/fetch_short_url`, payload, config)
            .then(this.getShortURLSuccess)
            .catch(this.getShortURLFailure)
    }

    getShortURLSuccess = (res) => {
        if(res.status === 200) {
            this.setState({short_url: res.data.data.short_url}, ()=>this.toggleShareDialog());
        }else {
            this.setState({short_url: null}, ()=>this.toggleShareDialog());
        }
    }

    getShortURLFailure = (err) => {
        console.log(err)
        this.setState({short_url: null}, ()=>this.toggleShareDialog());
    }

    sendMessageToPDFViewer = () => {
        let iframe = document.getElementById("pdfjsviewer-iframe");
        if (!iframe) return;
        let iWindow = iframe.contentWindow;
        let payload = {
            hide: !this.state.user_has_download_access
        }
        iWindow.postMessage(payload, '*')
    }

    checkUserDownloadPermission = async () => {
        const payload = {email: this.props.auth.user.attributes.email};
        let session =  await getSession();
        let config = { headers: { "Authorization": session.accessToken.jwtToken } };

        this.makeApiCall(`${process.env.REACT_APP_LIB_DOMAIN}/user_settings/download/get_download_access_status`, payload, config)
            .then(this.getUserPermissionStatusSuccess)
            .catch(this.getUserPermissionStatusFailure)
    }

    getUserPermissionStatusSuccess = (res) => {
        if(res.status === 200) {
            if(res.data.data.has_access){
                this.setState({user_has_download_access: true}, ()=>this.sendMessageToPDFViewer())
            } else {
                this.setState({user_has_download_access: false}, ()=>this.sendMessageToPDFViewer())
            }
        }else {
            this.setState({user_has_download_access: true}, ()=>this.sendMessageToPDFViewer())
        }
    }

    getUserPermissionStatusFailure = (err) => {
        console.log(err)
        this.setState({user_has_download_access: true}, ()=>this.sendMessageToPDFViewer())
    }

    makeApiCall = (url, payload, config)=> {
        return axios.post(url, payload, config);
    }

    onEnter = () => {
    };

    onOpened = () => {
        //This triggers the PDF viewer component
        this.pdfViewerRef.current.trigger();
    };

    onClosed = () => {
        this.props.store.setSelectedDocumentIsOpen(false);
    };

    /**
     * @desc Toggles the visibility of this modal
     */
    toggle = () => {
        // Mark the current document as not-open in PDF Viewer
        this.props.store.resetAllDocumentData();
        this.setState(prevState => ({ modal: !prevState.modal }));
    };

    /**
     * @desc Toggles the visibility of this modal and all
     * subsequent child modals if present
     */
    toggleAll = () => {
        this.setState(prevState => ({ modal: !prevState.closeAll }));
    };

    /**
     * @desc Toggles the visibility of this modal
     * to 'show' or 'visible'
     */
    show = () => {
        this.setState(prevState => ({ modal: true }));
    };

    /**
     * @desc Toggles the visibility of this modal
     * to 'hide' or 'invisible'
     */
    hide = () => {
        this.setState(prevState => ({ modal: false }));
    };

    render() {
        return (
            <Modal
                isOpen={this.state.modal}
                backdrop={this.state.backdrop}
                size={this.state.size}
                centered={this.state.centered}
                toggle={this.toggle}
                onOpened={this.onOpened}
                onClosed={this.onClosed}
                onEnter={this.onEnter}
                scrollable={this.scrollable}
                className="spark-modal"
            >
                {/*<div className="modal-header">*/}
                {/*    <span className="filler"></span>*/}
                {/*    <h5 className="modal-title">Document Viewer</h5>*/}
                {/*    <button className="close" onClick={this.toggle}>&times;</button>*/}
                {/*</div>*/}
                <ModalBody>
                    <PDFViewer
                        amplitudeAnalytics={this.props.amplitudeAnalytics}
                        store={this.props.store}
                        auth={this.props.auth}
                        toastify={this.props.toastify}
                        ref={this.pdfViewerRef}
                        backend={this.backend}
                        api={documentSearch}
                    />
                    <ShareDialog
                        ref={this.shareDialogRef}
                        toastify={this.props.toastify}
                        closeAll={this.state.closeAll}
                        title="SHARE"
                        backdrop={true}
                        showLabel={true}
                    />
                </ModalBody>
            </Modal>
        );
    }
}

export default PDFViewerModal;
