import React, {Fragment} from 'react';
import './style.scss';
import {Spinner} from "reactstrap";

class FloatingLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    toggle = (active) => {
        this.setState({active});
    };

    render() {
        let activeClass = this.state.active ? 'active' : '';

        return (
            <div className={`floating-loader ${activeClass} x-${this.props.xPos} y-${this.props.yPos}`}>
                <div className={"loader-animation"}>
                    <Spinner type="grow" color="secondary" />
                    <Spinner type="grow" color="secondary" />
                    <Spinner type="grow" color="secondary" />
                </div>
                {/*<span className="loader-msg">{this.props.message}</span>*/}
            </div>
        );
    }
}

export default FloatingLoader;
