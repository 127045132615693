import React, { Component, Fragment } from 'react';
import Validate from "../../utils/FormValidation";
import {
    Button, Form, FormGroup, Label, Input, FormFeedback, Spinner,
    Modal
} from 'reactstrap';
import Auth from '@aws-amplify/auth';
import ResponseModal from '../../components/ResponseModal';
import { observer } from "mobx-react";
import axios from "axios";
import { Link } from "react-router-dom";
import { PATH_NAME_DOCUMENT_SEARCH } from "../../constants/strings";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateBrowserFingerprint, updateSessionAndDevicesArray } from '../../utils/Browser';
const MAX_SESSION_COUNT = 5; // Maximum allowed session count

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: `${process.env.REACT_APP_COGNITO_PASSWORD}`,
            keepMeLoggedIn: false,
            isLoading: false,
            isModalOpen: false,
            resendLoader: false,
            isActive: false,
            devices: [],
            modal: {
                status: "",
                title: "",
                text: "",
                buttonText: "",
                functionalBtn: {
                    link: "",
                    text: "",
                    className: ""
                },
                metadata: {}
            },
            errors: {
                emailInvalid: false,
                passwordInvalid: false,
                cognito: null,
            },
            isSubmitted: false,
            attempts: 1,
            errorMessage : ''
        };

    }

    componentDidMount() {
        // Timeout to prevent race condition from DocumentSearch page
        setTimeout(() => {
            this.props.amplitudeAnalytics.visit('Login');
        }, 1000);
    }

    clearErrorState = () => {
        this.setState({
            responseText: "",
            errors: {
                emailInvalid: false,
                passwordInvalid: false,
                cognito: null,
            },
        });
    };
    onLogoutAllDevices = async () => {
        const user = await Auth.signIn(this.state.email, this.state.password);
        await Auth.updateUserAttributes(user, {
            'custom:sessionCount': "0",
            'custom:devices': JSON.stringify([])
        });
        await Auth.signOut({ global: true })
        localStorage.removeItem("email");
        localStorage.removeItem("popupShown")
        toast.success("All Session Logout Succsefully.")
        return;
    }
    onLoginSubmit = async (event, email) => {
        event.preventDefault();

        try {
            this.setState({ isLoading: true});
            this.clearErrorState();

            const errors = Validate('login', this.state);

            if (Object.keys(errors).length) {
                this.setState({
                    errors: { ...errors },
                    isLoading: false
                });
                return;
            }
            // AWS Cognito integration here
            const { password } = this.state;
            if (process.env.REACT_APP_COGNITO_ADMINUSERLIST?.includes(email)) {
                this.props.auth.setAuthStatus(false);
                this.props.auth.setUser(null);
                this.setState({ isLoading: false });
                localStorage.setItem("email", email)
                this.props.history.push("/loginwithpassword");
                return;
            }
            else {
                try {
                    const user = await Auth.signIn(email, password);
                    const sessionCountCheck = user.signInUserSession.idToken.payload['custom:sessionCount'] || 0;
                    const devicesAttribute = user.signInUserSession.idToken.payload['custom:devices'];

                    if (sessionCountCheck >= MAX_SESSION_COUNT) {
                        Auth.signOut().then(() => { });
                        this.props.auth.setAuthStatus(false);
                        this.setState({
                            isActive: true,
                            isLoading: false,
                            devices: JSON.parse(devicesAttribute)
                        })
                        return;
                    } else {
                        generateBrowserFingerprint(async (fingerprint) => {
                            // Call the function to update devicesArray and sessionCount
                            await updateSessionAndDevicesArray(fingerprint, Auth, devicesAttribute, sessionCountCheck, user);

                            this.setState({ isLoading: false });
                            this.props.auth.setUser(user);
                            this.props.auth.setAuthStatus(true);
                            let redirectUrl = this.props.store.redirectRoute ? this.props.store.redirectRoute : `${PATH_NAME_DOCUMENT_SEARCH}`;
                            this.props.history.push(redirectUrl);
                            setTimeout(() => { window.location.reload(false) });
                            this.props.amplitudeAnalytics.login(email);
                        });
                    }
                } catch (err) {
                    console.error('Authentication failed', err);
                    this.setState({
                        isLoading: false
                    });
                    this.showPopUpMessageModal(
                      "warning",
                      "Oops!",
                      "Sorry, our system does not recognize you. Please sign up by clicking the 'Create an Account' link in the login screen.",
                      "TRY AGAIN"
                    );
                }
            }
        } catch (error) {

            this.checkForEmailVariations(event);
            const errorPresent = this.state.errorMessage;
            if(errorPresent === ''){
                this.setState({
                    errorMessage: error.message,
                })
            }
            else if(error.message.includes("exceeded")){
                this.setState({
                    errorMessage: error.message,
                })
            }

            else if(error.message.includes("Incorrect")){
                this.setState({
                    errorMessage: error.message,
                })
            }
           

            if(this.state.attempts > 4){
                let err = null;

                !error.message ? err = {"message": error} : err = error;

                this.setState({
                    errors: {
                        ...this.state.errors,
                        cognito: this.state.errorMessage
                    },
                    isLoading: false
                });
            }
        }
    };

    checkForEmailVariations = (e) => {
        const {email, attempts} = this.state;
        let checkEmail = email;
        switch (attempts) {
            case 1:
                // checkEmail = checkEmail.toLowerCase();
                this.onLoginSubmit(e, checkEmail).then(() => {});
                this.setState({attempts: this.state.attempts+1})
                break;
            case 2:
            case 3:
                if(email.includes(".") || email.includes("_")){
                    if(attempts === 2) {
                        checkEmail = checkEmail.replace(/(^|\.|_)(\S)/g, s=>s.toUpperCase());
                    } else {
                        checkEmail = checkEmail.replace(/(^|\.|_|@)(\S)/g, s=>s.toUpperCase());
                    }

                    //setting the letter after last dot to lowercase
                    let lastDotIndex = checkEmail.lastIndexOf('.')
                    checkEmail = checkEmail.slice(0,lastDotIndex+1)+checkEmail.charAt(lastDotIndex+1).toLowerCase()+checkEmail.slice(lastDotIndex+2)

                    this.onLoginSubmit(e, checkEmail).then(() => {});
                }
                this.setState({attempts: this.state.attempts+1})
                break;
            case 4:
                checkEmail = checkEmail.toUpperCase();
                this.onLoginSubmit(e, checkEmail).then(() => {});
                this.setState({attempts: this.state.attempts+1})
                break;
            default: return
        }
    }

    handleResendMail = async () => {
        this.setState({ resendLoader: true });

        const { email } = this.state;
        let status = false;
        try {
            const params = {
                email: email,
            };
            let url = process.env.REACT_APP_LIB_DOMAIN + '/resend_email';
            let resendMailResponse = await axios.post(url, params);
            console.log(resendMailResponse);
            if (resendMailResponse &&
                resendMailResponse.hasOwnProperty('status') &&
                resendMailResponse.status === 200 &&
                resendMailResponse.data &&
                resendMailResponse.data.status
            ) {
                status = true;
            }
        } catch (error) {
            // TODO: add if condition for error response
            console.log(error);
        }

        if (status) {
            this.showPopUpMessageModal(
                'success',
                'Mail sent!',
                "Please generate a new password using the link sent to your E-mail.",
                "OKAY"
            );
        } else {
            this.showPopUpMessageModal(
                'warning',
                'Oops!',
                "Sorry, our system does not recognize you. Please try signing up with us again to generate a new password or contact your account manager.",
                "TRY AGAIN"
            );
        }
        this.setState({ resendLoader: false });
    };

    /**
     * It changes popup modal state to render popup messages according to the configuration passed into the function
     *  @param { string } status - ['error', 'warning', 'success']
     *  @param { string } modalTitle = title you want displayed on header of the modal
     *  @param { string } modalText = text to be displayed inside the modal
     *  @param { string } buttonText = text to be displayed on the close modal action button
     *  @param { string } functionalBtn
     */
    showPopUpMessageModal = (status, modalTitle, modalText, buttonText, functionalBtn) => {
        this.setState({
            modal: {
                status: status,
                title: modalTitle,
                text: modalText,
                buttonText: buttonText,
                functionalBtn: functionalBtn
            },
            isModalOpen: true,
        });
    };
    onInputChange = event => {
        this.setState({
            [event.target.name]: event.target.value.trim(),
            attempts: 1,
            errorMessage: ''
        });
    };

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.onLoginSubmit(e, this.state.email.toLowerCase()).then(() => {});
        }
    };

    render() {
        console.log(this.state.errors.cognito)
        let cognitoErrorMessage = this.state.errors.cognito ? this.state.errors.cognito : '';
        let parsedCognitoErrorMessage = cognitoErrorMessage;

        switch(true) {
            case cognitoErrorMessage.toLowerCase().includes('incorrect'):
                parsedCognitoErrorMessage = 'The password you entered is incorrect. Please re-enter or use Forgot Password to reset your password.';
                break;
            case cognitoErrorMessage.toLowerCase().includes('not exist'):
                parsedCognitoErrorMessage = 'We can’t find an active account with that username. Create a new account or if you’ve already done that please wait to get approved by an administrator.';
                break;
            case cognitoErrorMessage.toLowerCase().includes('disabled'):
                parsedCognitoErrorMessage = 'We can’t find an active account with that username. Create a new account or if you’ve already done that please wait to get approved by an administrator.';
                break;
            case cognitoErrorMessage.toLowerCase().includes('exceeded'):
                parsedCognitoErrorMessage = 'You have exceeded the maximum number of attempts. Please try again later.';
                break;
        }

        return (
            <Fragment>
                <Form>
                        <FormGroup>
                            <div className="label-container">
                                <span className="field-label">E-mail</span>
                            </div>
                        <Input
                            // style={{
                            //     "background": "transparent",
                            //     "color": "#f2f2f2",
                            //     border: 0,
                            //     borderBottom: "1px solid"
                            // }}
                                type="text"
                                name="email"
                                value={this.state.email}
                                placeholder="example@abc.com"
                                onChange={this.onInputChange}
                                onKeyPress={this.onKeyPress}
                                invalid={this.state.errors.emailInvalid}
                            />
                            <FormFeedback
                            >
                                {this.state.errors.emailInvalid ? 'Please enter valid E-mail ID' : ''}
                            </FormFeedback>
                        </FormGroup>
                    {/* <FormGroup>
                            <div className="label-container">
                                <span className="field-label">Password</span>
                            </div>
                            <Input
                                type="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.onInputChange}
                                onKeyPress={this.onKeyPress}
                                invalid={this.state.errors.passwordInvalid}
                            />
                            <FormFeedback
                            >
                                {this.state.errors.passwordInvalid ? "Please enter valid Password" : ''}
                            </FormFeedback>
                        </FormGroup> */}
                    <div
                        className="response-text">
                        {parsedCognitoErrorMessage}
                    </div>
                    <div className="secondary-inputs">
                        <FormGroup check={true} className="keep-me-signed-in-container" inline>
                            <Label check={true}>
                                <Input
                                    type="checkbox"
                                    name="keepMeLoggedIn"
                                    defaultChecked={true}
                                /> <span className="keep-me-signed-in">Keep me signed in</span>
                            </Label>
                        </FormGroup>
                        {/* <span className="forgot-password-button" >
                            <Link to="/forgot_password">Forgot Password?</Link>
                        </span> */}
                    </div>
                    <Button
                        style={{
                            backgroundColor: "#000",
                            padding: "0.6rem",
                            width: "70%",
                            borderRadius: "2rem",
                            marginTop: "20px",
                        }}
                            onClick={(e)=>this.onLoginSubmit(e, this.state.email.toLowerCase())}
                        >{!this.state.isLoading ? "LOGIN" : <Spinner size="sm" color="white" />}
                        </Button>
                    <div
                        style={{
                            margin: "0.5em 0 0 0",
                            fontSize: "0.8rem"
                        }}>
                        <Link className="create-account-link" to="/signup"> Create an Account </Link>
                    </div>
                    <div
                        style={{
                            fontSize: "0.8rem",
                            marginTop: "1rem",
                            lineHeight: "1rem"
                        }}>
                        <p>By signing up, I accepted all TERMS AND CONDITIONS
                            and have read the PRIVACY POLICY</p>
                    </div>
                </Form>
                {
                    this.state.isModalOpen &&
                    <ResponseModal
                        modal={this.state.isModalOpen}
                        backdrop={true}
                        modalClass="response-modal"
                        titleClass={"title " + this.state.modal.status}
                        modalTitle={this.state.modal.title}
                        textClass="text"
                        modalText={this.state.modal.text}
                        buttonClass={"action-button " + this.state.modal.status}
                        buttonText={this.state.modal.buttonText}
                        onClickAction={this.onActionButtonClick}
                        functionalBtn={this.state.modal.functionalBtn}
                        isBtnLoading={this.state.resendLoader}
                        type={this.state.modal.status}
                    />
                }
                {
                    this.state.isActive &&
                    <Modal
                        centered={true}
                        isOpen={this.state.isActive}
                        className='terms-n-conditions-modal'
                            backdrop='static'
                            toggle={() => this.setState({ isActive: false })}
                        scrollable={true}
                        >
                            <div>
                                <div className="px-4 pt-4 d-flex justify-content-end">
                                    <div>
                                        <Button
                                            type="button"
                                            className="btn-close btn-link bg-white"
                                            onClick={() => this.setState({ isActive: false })}
                                        >
                                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                        </Button>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "20px",
                                    }}
                                >
                                    <h4>{"You seem to be already logged in from the following devices"}</h4>
                                    <br />
                                    <div
                                        style={{
                                            margin: "0 auto", // Center the container within the modal
                                            textAlign: "left", // Left-align the content within the container
                                        }}
                                    >
                                    {this.state.devices?.map((d, index) => (
                                        <h5 key={index} style={{ fontSize: 20, padding: 10 }}>
                                            {`${index + 1}. Device: ${d?.device || d?.os}, Browser:${d?.browser} and OS:${d?.os}`}
                                        </h5>
                                    ))}
                                    </div>
                                    <br />
                                    <p style={{ color: "red" }}>
                                        {`We allow only ${MAX_SESSION_COUNT} simultaneous login sessions per user. If you continue, we will log you out from the above devices`}
                                    </p>
                                    <Button
                                        color='danger'
                                        onClick={() => {
                                            this.setState({ isActive: false });
                                            this.onLogoutAllDevices();
                                        }}
                                    >
                                        {"CONTINUE"}
                                    </Button>
                                </div>
                            </div>
                    </Modal>
                }
            </Fragment>
        );
    }
}

export default observer(Login);