const moment = require('moment');

/**
 * @desc Gets today's date in the desired format
 *
 * @param {string} format - Desired format
 * @returns {string} date - Formatted date
 */
export function getCurrentDate(format) {
    if(format) {
        return moment().format(format);
    } else {
        return moment().format();
    }
}

/**
 * @desc Gets historical date by subtracting 'num' of 'units' from todays date
 *
 * @param {number} num - Number of units to subtract
 * @param {string} unit - Units (Days, Months, Years)
 * @param {string} format - Desired format
 * @returns {string} date - Formatted date
 */
export function getPastDate(num, unit, format) {
    if(format) {
        return moment().subtract(num, unit).format(format);
    } else {
        return moment().subtract(num, unit).format();
    }
}

/**
 * @desc Change the date format
 *
 * @param {string} dateStr - Date string
 * @param {string} newFormat - Desired format
 * @returns {string} date - Formatted date
 */
export function formatDate(dateStr, newFormat) {
    return moment(dateStr).format(newFormat);
}

export function getUnixTimestampFromDate(dateStr) {
    return moment(dateStr).valueOf();
}
