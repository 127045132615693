import React, { Component, Fragment } from "react";
import Plyr from 'plyr';
import './style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default class AudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.player = null;
    }

    componentDidMount() {
        if (this.props.streamLink) {
            this.initPlayerObj();
        }
    }

    componentDidUpdate() {
        if (this.player && !this.props.isPlaying && this.player.playing) {
            this.player.pause();
        }
    }

    initPlayerObj() {
        this.player = new Plyr('#player', { ...this.props.options });
        window.player = this.player;
        if (this.props.options.hasOwnProperty("autoplay") && this.props.options.autoplay) {
            this.props.syncPlayingState(true);
        }
    }

    handlePlayPause = () => {
        this.player.togglePlay();
        this.props.syncPlayingState(this.player.playing);
    }

    render() {
        return (
            !!this.props.streamLink &&
            <Fragment>
                <div className="podcast-image">
                    <div className="audio-image">
                      
                    </div>
                </div>
                <div className={this.props.className ? `audio-player ${this.props.className}` : "audio-player"}>
                    <audio id="player" controls>
                            {/* <source src={`${process.env.REACT_APP_PODCAST_S3_LINK}` + this.props.streamLink?.split("X-Amz-Algorithm")?.[0]?.split("podcast-stream")?.[1]?.slice(0, -1)} /> */}
                            <source src={`${this.props.streamLink}`} />
                    </audio>
                    <div className="additional-controls">
                        <button className="prev-btn" onClick={this.props.handlePrev} title="Prev">
                            <FontAwesomeIcon icon="step-backward" />
                        </button>

                        <button className="play-pause-btn" onClick={this.handlePlayPause} title="Play/Pause">
                            {
                                this.props.isPlaying ?
                                    <FontAwesomeIcon icon={['far', 'pause-circle']} />
                                    :
                                    <FontAwesomeIcon icon={['far', 'play-circle']} />
                            }
                        </button>

                        <button className="next-btn" onClick={this.props.handleNext} title="Next">
                            <FontAwesomeIcon icon="step-forward" />
                        </button>
                    </div>
                    <div className="audio-info">
                        {this.props.title}
                    </div>
                </div>
            </Fragment>
        )
    }
}
