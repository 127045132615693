import React, { useEffect, useState } from 'react'
import dropdownToggleImg from "../../../img/toggle-dropdown.svg";
import { Button, Card, CardBody, CardHeader, Collapse, Spinner } from 'reactstrap';
import './style.scss';
const PodcastFilter = ({
    filterData = [],
    reseteFilter,
    selectedFilter,
    setSelectedFilter,
    apiCall
}) => {
    const [isOpenList, setIsOpenList] = useState();
    const [timerCall, settimerCall] = useState(null);
    useEffect(() => {
        setIsOpenList(filterData.map(() => true))
    }, [filterData]);

    function setPanelFilter(filter, filterName, selected) {
        if (timerCall) {
            clearTimeout(timerCall)
        }
        let filterPanelCurrVal = selectedFilter;
        if (filterPanelCurrVal[filterName].includes(selected.key)) {
            filterPanelCurrVal[filterName].splice(filterPanelCurrVal[filterName].indexOf(selected.key), 1)
        }
        else {
            filterPanelCurrVal[filterName].push(selected.key)
        }
        setSelectedFilter(filterPanelCurrVal)
        const timer = setTimeout(() => {
            apiCall();
        }, 1500);
        settimerCall(timer);
    }
    const toggle = (index) => {
        const newIsOpenList = [...isOpenList];
        newIsOpenList[index] = !newIsOpenList[index];
        setIsOpenList(newIsOpenList);
    };


    return (
        <>
            <div className='mb-3'>
                <Button
                    onClick={() => reseteFilter()}
                    className='px-4'
                    color='secondary'>
                    Reset
                </Button>
            </div>
            <div>
                {
                    filterData?.length < 0 ?
                        <div className="loader-container">
                            <Spinner color="info" style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        filterData?.map((item, index) => {
                        return (
                            <>
                                <div className='' key={index}>
                                    <div
                                        className='cursor-pointer'
                                        onClick={() => toggle(index)}
                                    >
                                        <div className="d-flex">
                                            <img
                                                src={dropdownToggleImg}
                                                className={`${isOpenList[index] == 1 ? "rotate" : ""} mr-3`}
                                                alt="dropdown toggle" />
                                            <span className='' >{item?.heading}</span>
                                        </div>
                                    </div>
                                    <Collapse isOpen={isOpenList[index]}>
                                        <div className='shadow-none'>
                                            <div className='shadow-none'>
                                                {
                                                    item?.values && item?.values.map((val, j) => {
                                                        return (

                                                            <div className='d-flex justify-content-between' >
                                                                <div className="d-flex"
                                                                    onClick={() =>
                                                                        setPanelFilter(item.filter_key, item.filter_name_key, val)}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        name=""
                                                                        id={`filter-${j}`}
                                                                        className='mr-4'
                                                                        checked={selectedFilter[item.filter_name_key].includes(val.key)}
                                                                    />
                                                                    <span className=''>{val.key === "" ? 'None' : val.key}</span>
                                                                </div>
                                                                <span className=''>{val.doc_count}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </>
                        )
                    })
                }

            </div>
        </>
    )
}

export default PodcastFilter;
