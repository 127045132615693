import React from 'react';
import { Spinner } from 'reactstrap';
import { toJS } from 'mobx';
import { observer } from "mobx-react";
import { Collapse, Button, CardBody, Card,CardHeader } from 'reactstrap';
import SparkCard from "../SparkCard";
import { filterPanelValInitial } from '../../templates/DocumentSearch/index';
import './style.scss';
import dropdownToggleImg from "../../img/toggle-dropdown.svg";

class FilterPanel extends React.Component {
    constructor(props) {
        super(props);
        this.loaderStateElemRef = React.createRef();
        this.successStateElemRef = React.createRef();
        this.failStateElemRef = React.createRef();
        this.emptyStateElemRef = React.createRef();
        this.resultsCountFigureElemRef = React.createRef();
        this.state = {
            dropdownOpen: false,
            activeIndex: 0,
            pageInputValue: 0,
            filterData: null,
            filterToggle: 0,
            filterPanelCurrVal: filterPanelValInitial,
            timerCall: null
        };
        this.totalResults = 0;
        this.totalPages = 0;
        this.currentPage = 1;
    }

    //Refs
    sortDropdownBtnRef = React.createRef();

    //Configs for child components
    resultViewerCardConfig = {
        header: {
            show: true,
            content: null
        },
        body: {
            content: null
        },
        footer: {
            show: false,
            content: null
        },
    };

    //Lifecycle methods
    componentDidMount() {
    }

   
    /**
     * @desc Generates JSX code for the Header section of Results Viewer Card
     *
     * @returns {JSX} - Result viewer card header
     */
    makeHeaderContentJSX() {
        return null
    }

  

    //helper function 
    toggleFilterTabs(e,idx){ 
        let filterToggle = this.state.filterToggle;
        filterToggle === idx ? filterToggle = null : filterToggle = idx;
        this.setState({filterToggle: filterToggle})
    }
    setPanelFilter(filter,filterName,selected){
        if(this.state.timerCall){
            clearTimeout(this.state.timerCall)
        }
        let filterPanelCurrVal = this.state.filterPanelCurrVal;
        if(filter === "stock_filter"){
            if(filterPanelCurrVal[filterName].includes(selected.stock_id)){
                filterPanelCurrVal[filterName].splice(filterPanelCurrVal[filterName].indexOf(selected.stock_id),1)
            }
            else{
                filterPanelCurrVal[filterName].push(selected.stock_id)
            }
        }
        else{
            if(filterPanelCurrVal[filterName].includes(selected.key)){
                filterPanelCurrVal[filterName].splice(filterPanelCurrVal[filterName].indexOf(selected.key),1)
            }
            else{
                filterPanelCurrVal[filterName].push(selected.key)
            }
        }

        this.setState({filterPanelCurrVal});
        const timer = setTimeout(() => {
            this.callApi();
        }, 1500); 
        this.setState({timerCall: timer})
    }
    callFilterApi(){
        if(this.state.timerCall){
            clearTimeout(this.state.timerCall)
        }
        this.callApi();

    }


    callApi(){
        let filterData = toJS(this.props.store.filterPanel);
        let filterPanelCurrVal = this.state.filterPanelCurrVal;
        let filterStore = toJS(this.props.store.resultViewer.results.filters)


        filterStore.forEach(element => {
            if(filterPanelCurrVal[element.filter_name_key].length > 0){
                filterData[element.filter_key][element.filter_name_key] = filterPanelCurrVal[element.filter_name_key];
            }
        });
       
        this.props.store.setFilterPanel(filterData);
        this.props.fetchFilterResults();
        filterStore.forEach(element => {
            filterPanelCurrVal[element.filter_name_key] = [];
        });
        
        this.setState({filterPanelCurrVal: filterPanelCurrVal})
    }
    callresetApi(){
        this.props.store.resetFilterVal()
        this.props.fetchFilterResults();
    }

      /**
     * @desc Generates JSX code for the list items (results)
     * inside the Body section of Results Viewer Card
     *
     * @param {object} listItems - An array of search results
     * @returns {JSX} - Result list item
    */
    makeResultListItemsJSX(listItems) {
        return(
            <div>
                <div className="filter-panel-container__btns">
                    {/* <div className="filter-panel-container__btns__filter">
                        <Button onClick={()=> this.callFilterApi()}>Filter</Button>
                    </div> */}
                    <div className="filter-panel-container__btns__reset">
                        <Button onClick={()=> this.callresetApi()} >Reset</Button>
                    </div>
                </div>
                {listItems.map((item,idx)=> {
                    if(!item.heading){
                        return(
                            null
                        )
                    }
                    else{
                        return(
                            <div className='filter-toggles' key={idx}>
                                <CardHeader
                                onClick={(e)=>{
                                    this.toggleFilterTabs(e,idx);
                                    
                                }}
                                >
                                    <img src={dropdownToggleImg} className={this.state.filterToggle === idx ? "rotate" : ""} alt="dropdown toggle"/>
                                    <span className='filter-toggles__heading' >{item.heading}</span>
                                </CardHeader>
                            <Collapse isOpen={this.state.filterToggle === idx}>
                            <Card>
                                <CardBody>
                                    {
                                        item.values && item.values.map((val,j)=>{
                                            return(
                                                <div className='filter-toggles__keys' >
                                                    <div className="filter-toggles__keys__name" onClick={()=>this.setPanelFilter(item.filter_key,item.filter_name_key,val)}>
                                                        <input
                                                               type="checkbox" name=""
                                                               id= {`filter-${idx}-${j}`}
                                                               checked={this.state.filterPanelCurrVal[item.filter_name_key].includes(val[item.filter_key === "stock_filter" ? "stock_id": "key"])}
                                                        />
                                                        <span className='filter-toggles__sub-heading'>{val.key === "" ? 'None' : val.key}</span>
                                                    </div>
                                                    <span className='filter-toggles__keys__doc-count'>{val.doc_count}</span>                                           
                                                </div>
                                            )
                                        })
                                    }
                                </CardBody>
                            </Card>
                            </Collapse>
                        </div>
                        )
                    }}
                )}
            </div>
        )
    }
    /**
    * @desc Generates JSX code for State Elements, i.e the
    * states which can be toggled (loading, success, fail, empty)
    *
    * @param {string} element - State element type
    * @param {object} results - An array of search results
    * @returns {JSX} - State element
    */
    makeStateElementJSX(element, results = []) {
        switch (element) {
            case 'loading':
                return (
                    <div className={"state-element loading"} ref={this.loaderStateElemRef}>
                        <Spinner type="grow" color="secondary" />
                        <Spinner type="grow" color="secondary" />
                        <Spinner type="grow" color="secondary" />
                    </div>
                );
            case 'success':
                return (
                    <div className={"state-element success"} ref={this.successStateElemRef}>
                        <div className="filter-panel-list">
                            {this.makeResultListItemsJSX(results.filters)}
                        </div>
                    </div>
                );
            case 'fail':
                return (
                    <div className={"state-element fail"} ref={this.failStateElemRef}>
                        <h3 className="placeholder-text">
                            {/*SOMETHING WENT WRONG :(*/}
                        </h3>
                    </div>
                );
            case 'empty':
                return (
                    <div className={"state-element empty"} ref={this.emptyStateElemRef}>
                        <h3 className="placeholder-text">
                            {/*NO FILTER FOUND*/}
                         </h3>
                    </div>
                );
            default: 
                return(
                    null
                )
        }
    }

    /**
    * @desc Generates JSX code for the Body section
    * of Results Viewer Card
    *
    * @returns {JSX} - Results box
    */
    makeBodyContentJSX() {
        let stateElement = null;
      //  If an API call is ongoing, rendering will be blocked by a loader
        if (this.props.store.resultViewer.apiCallActive) {
            stateElement = this.makeStateElementJSX('loading');
        } else {
            if (this.props.store.resultViewer.results) {
                if (this.props.store.resultViewer.results.filters) {
                    stateElement = this.makeStateElementJSX('success', this.props.store.resultViewer.results);
                } else {
                    stateElement = this.makeStateElementJSX('empty');
                }

            } else {
                stateElement = this.makeStateElementJSX('fail');
            }
        }

        return (
            <div className="results-box">
                {stateElement}
            </div>
        );
    }

    render() {
        //Configs for spark card component
        this.resultViewerCardConfig.header.content = this.makeHeaderContentJSX();
        this.resultViewerCardConfig.body.content = this.makeBodyContentJSX();
        let activeClass = this.props.store.previewActive ? '' : 'active';

        return (
            <div className={`filter-panel-container ${activeClass}`}>
                <SparkCard config={this.resultViewerCardConfig} />
            </div>
        );
    }
}

export default observer(FilterPanel);
