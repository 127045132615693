import { ENDPOINT_GET_SUGGESTIONS } from "../../constants/strings";

const moment = require("moment");

export const filtersMapping = {
    stock: {
        mainKey: "stock_filter",
        secondaryKey: "stocks"
    },
    report_type: {
        mainKey: "report_filter",
        secondaryKey: "reports"
    },
    sector: {
        mainKey: "sector_filter",
        secondaryKey: "sectors"
    },
    include_all: {
        mainKey: "include_all",
        secondaryKey: "words"
    },
    include_any: {
        mainKey: "include_any",
        secondaryKey: "words"
    },
    exclude_all: {
        mainKey: "exclude_all",
        secondaryKey: "words"
    },
    recommendation: {
        mainKey: "recommendation_filter",
        secondaryKey: "recommendations"
    },
    authors: {
        mainKey: "author_filter",
        secondaryKey: "author_names"
    },
};

export const filterTemplate = {
    "author_filter": {
        "author_names": [

        ]
    },
    "document_filter": {
        "document_ids": [

        ]
    },
    "date_filter": {
        "lower_bound": '2009-01-01',
        "upper_bound": moment().format('YYYY-MM-DD')
    },
    "stock_filter": {
        "stocks": [

        ]
    },
    "report_filter": {
        "reports": [

        ]
    },
    "sector_filter": {
        "sectors": [

        ]
    },
    "recommendation_filter": {
        "recommendations": [

        ]
    },
    "sort": {
        "sort_by": "release_date",
        "order": "desc"
    },
    "include_all": {
        "words": [

        ]
    },
    "include_any": {
        "words": [

        ]
    },
    "exclude_all": {
        "words": [

        ]
    },
    "content": {
        "query": ""
    },
    "from": 0,
    "size": 20
};

export const filterTemplate2 = {
    "author_filter": {
        "author_names": [

        ]
    },
    "document_filter": {
        "document_ids": [

        ]
    },
    "date_filter": {
        "lower_bound": '2009-01-01',
        "upper_bound": moment().format('YYYY-MM-DD')
    },
    "stock_filter": {
        "stocks": [

        ]
    },
    "report_filter": {
        "reports": [

        ]
    },
    "sector_filter": {
        "sectors": [

        ]
    },
    "recommendation_filter": {
        "recommendations": [

        ]
    },
    "sort": {
        "sort_by": "release_date",
        "order": "desc"
    },
    "include_all": {
        "words": [

        ]
    },
    "include_any": {
        "words": [

        ]
    },
    "exclude_all": {
        "words": [

        ]
    },
    "content": {
        "query": ""
    },
    "from": 0,
    "size": 20
};

export const filterPanelValInitial = {
        "author_names": [],
        "document_ids": [],
        "stocks": [],
        "reports": [],
        "sectors": [],
        "recommendations": []
};
export const mainSearchConfig = {
    identifier: "input",
    suggestionTypes: 'stock,sector,report_type,sector,recommendation,authors',
    apiURL: ENDPOINT_GET_SUGGESTIONS,
    suggester: true, // Whether to enable autocomplete suggesters for this particular input field
    enforceWhitelist: false,
    forceSuggesterResults: false,
    fetchDynamically: true,
    whitelist: [],
    placeholder: 'Search by Company, Sector or Keyword(s) (e.g. "Auto", or "Eicher Motors", or "Royal Enfield")',
    duplicates: true
};

export const advancedSearchConfig = [
    {
        identifier: "stock", // This will uniquely identify a suggester element and an input box in advanced search panel
        name: "Stock", // This is the label which will be shown against an input box in advanced search panel
        placeholder: 'Maruti Suzuki, Bajaj Finserv, Bharti Airtel', // Placeholder for the input elemen <input />
        apiURL: ENDPOINT_GET_SUGGESTIONS,
        suggester: true,
        fetchDynamically: true,
        forceSuggesterResults: true,
        whitelist: [ // TODO: clear this hardcoded data
        ],
    },
    {
        identifier: 'report_type',
        name: 'Report Type',
        placeholder: 'Annual Report, Conference Call',
        apiURL: ENDPOINT_GET_SUGGESTIONS,
        suggester: true, // Whether to enable autocomplete suggesters for this particular input field
        fetchDynamically: false,
        forceSuggesterResults: true,
        whitelist: [
        ],
    },
    {
        identifier: 'sector', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'Sector', // This is the label which will be shown against an input box in advanced search panel
        placeholder: 'FMCG, Automobiles & Ancillaries, Bank', // Placeholder for the input element <input />
        apiURL: ENDPOINT_GET_SUGGESTIONS,
        suggester: true, // Whether to enable autocomplete suggesters for this particular input field
        fetchDynamically: false,
        forceSuggesterResults: true,
        whitelist: [
        ],
    },
    {
        identifier: 'recommendation', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'Recommendation', // This is the label which will be shown against an input box in advanced search panel
        placeholder: '', // Placeholder for the input element <input />
        apiURL: ENDPOINT_GET_SUGGESTIONS,
        suggester: true, // Whether to enable autocomplete suggesters for this particular input field
        fetchDynamically: false,
        forceSuggesterResults: true,
        whitelist: [
        ],
    },
    {
        identifier: 'authors', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'Analyst', // This is the label which will be shown against an input box in advanced search panel
        placeholder: '', // Placeholder for the input element <input />
        apiURL: ENDPOINT_GET_SUGGESTIONS,
        suggester: true, // Whether to enable autocomplete suggesters for this particular input field
        fetchDynamically: false,
        forceSuggesterResults: true,
        whitelist: [
        ],
    },
    {
        identifier: 'include_all', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'ALL of these words', // This is the label which will be shown against an input box in advanced search panel
        placeholder: 'Meeting, Disclosure, Announcement', // Placeholder for the input elemen <input />
        suggester: false,
    },
    {
        identifier: 'include_any', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'ANY of these words', // This is the label which will be shown against an input box in advanced search panel
        placeholder: 'Meeting, Disclosure, Announcement', // Placeholder for the input elemen <input />
        suggester: false,
    },
    {
        identifier: 'exclude_all', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'NONE of these words', // This is the label which will be shown against an input box in advanced search panel
        placeholder: 'Meeting, Disclosure, Announcement', // Placeholder for the input element <input />
        suggester: false,
    },
];

export const fetchReportsApiResponse = {
    "data": {
        "data": {
            "category": {
                "document_types": [
                    {
                        "doc_count": 3,
                        "key": "THEMATIC RESEARCH"
                    },
                    {
                        "doc_count": 1,
                        "key": "Others"
                    }
                ],
                "glocal": [
                    {
                        "doc_count": 4,
                        "key": "global"
                    }
                ],
                "sectors": [
                    {
                        "doc_count": 3,
                        "key": "IT"
                    },
                    {
                        "doc_count": 1,
                        "key": "Others"
                    }
                ],
                "stocks": []
            },
            "documents": {
                "hits": [
                    {
                        "document_id": "60b8aa38452f5d977e59973810a64d43527c798525ff8423d24f07d623773e37",
                        "recommendation": "SELL",
                        "release_date": "2019-08-26",
                        "report_type": null,
                        "score": 1,
                        "sector": null,
                        "stock_id": null,
                        "title": null
                    },
                    {
                        "document_id": "759cd4e1866835e52d6fc269227513599faa79b9ed20b83a597811b4d5e9d14f",
                        "recommendation": "BUY",
                        "release_date": "2019-08-25",
                        "report_type": "THEMATIC RESEARCH",
                        "score": 1,
                        "sector": "IT",
                        "stock_id": "TCS",
                        "title": "TCS Research"
                    },
                    {
                        "document_id": "4b29d6ae9a5137cd5863987f18edbaf21064462192857db867704b6f6e502fc1",
                        "recommendation": "BUY",
                        "release_date": "2019-08-25",
                        "report_type": "THEMATIC RESEARCH",
                        "score": 1,
                        "sector": "IT",
                        "stock_id": "TCS",
                        "title": "TCS Research"
                    },
                    {
                        "document_id": "e252d86b69d8aaa0c3babb9c91f3a38c71ec7e3f7bf44677ee6bbdc4a6d22fdc",
                        "recommendation": "BUY",
                        "release_date": "2019-08-25",
                        "report_type": "THEMATIC RESEARCH",
                        "score": 1,
                        "sector": "IT",
                        "stock_id": "TCS",
                        "title": "TCS Research"
                    }
                ],
                "total_result": {
                    "relation": "eq",
                    "value": 4
                }
            }
        },
        "status": true
    },
    "status": 200,
    "statusText": "OK",
    "headers": {
        "content-type": "application/json",
        "content-length": "551"
    },
    "config": {
        "url": process.env.REACT_APP_LIB_DOMAIN + "/document_search/fetch_reports",
        "method": "post",
        "data": "{\"data\":{\"date_filter\":{\"lower_bound\":\"2009-08-28\",\"upper_bound\":\"2019-08-26\"},\"stock_filter\":{\"stocks\":[]},\"report_filter\":{\"reports\":[]},\"sector_filter\":{\"sectors\":[]},\"recommendation_filter\":{\"recommendations\":[]},\"sort\":{\"sort_by\":\"release_date\",\"order\":\"desc\"},\"include_all\":{\"words\":[]},\"include_any\":{\"words\":[]},\"exclude_all\":{\"words\":[]},\"content\":{\"query\":\"\"},\"from\":0,\"size\":20}}",
        "headers": {
            "Accept": "application/json, text/plain, */*",
            "Content-Type": "application/json;charset=utf-8"
        },
        "baseURL": process.env.REACT_APP_LIB_DOMAIN + "/document_search",
        "transformRequest": [
            null
        ],
        "transformResponse": [
            null
        ],
        "timeout": 0,
        "xsrfCookieName": "XSRF-TOKEN",
        "xsrfHeaderName": "X-XSRF-TOKEN",
        "maxContentLength": -1
    },
    "request": {}
};

export const fetchReportsApiRequest = {
    "data": filterTemplate
};
