import moment from "moment";
import egrmApi from "../apis/egrm";
import { LOGGING_ENDPOINT_AMPLITUDE } from "../constants/strings";

const logAmplitudeResponseToServer = async (token, payload) => {
    return await egrmApi.post(
        LOGGING_ENDPOINT_AMPLITUDE,
        { data: payload },
        { headers: { "Authorization": token } }
    );
};

const logAmplitudeResponseToServerWrapper = (token, payload) => {
    if(token) {
        logAmplitudeResponseToServer(token, payload)
            .then(res => {
            })
            .catch(err => {
            });
    }
};

/**
 * @class
 * @classdesc Manager class for Amplitude Analytics
 */
export default class AmplitudeAnalytics {
    constructor() {
        this.jwtToken = '';
    }

    setJwtToken(token) {
        this.jwtToken = token;
    }

    /**
     * A interceptor method for all event logging calls
     * @param {string} eventName
     * @param {object} eventProperties
     * @param {function} callbackAction - Any callback method you wanna trigger after event is logged
     */
    async logEventWrapper(eventName, eventProperties, callbackAction = null) {
        let self = this;
        try {
                let amplitudeLog = {
                    "sessionId": `None`,
                    "amplitudeInitialized": `false`,
                    "eventName": eventName || "",
                    "status": "Done",
                    "response": eventProperties,
                    "device": window?.navigator?.userAgent || "",
                    "url": window.location.hostname || "",
                    "email": localStorage.getItem("email") || "",
                };
            logAmplitudeResponseToServerWrapper(self.jwtToken, amplitudeLog);
        } catch(error) {
            console.error(error);
            logAmplitudeResponseToServerWrapper(self.jwtToken, {
                eventName: 'Exception',
                success: null,
                response: null,
                errorMessage: error.message
            });
        }
    }

    /**
     * ##########################################
     *              GENERAL EVENTS
     * ##########################################
     */

    logout() {
        try {
            localStorage.removeItem("email");
            localStorage.removeItem("popupShown")
        } catch(error) {
            console.error(error);
        }
    }

    login(username) {
        try {
            localStorage.setItem("email", username ? username : "");
            this.logEventWrapper("Logged In");
        } catch(error) {
            console.error(error);
        }
    }

    signup(email) {
        try {
            localStorage.setItem("email", email ? email : "");
            this.logEventWrapper("New User Registered", {});
        } catch(error) {
            console.error(error);
        }
    }

    visit(page) {
        try {
            this.logEventWrapper("Visited", {
                page
            });
        } catch(e) {
            console.error(e);
        }
    }

    /**
     * ##########################################
     *              SPECIFIC EVENTS
     * ##########################################
     */

    search(payload) {
        try {
            this.logEventWrapper('Search Performed', {
                filter: payload
            });
        } catch(error) {
            console.error(error);
        }
    }

    sharedDocumentViewed(documentMeta) {
        try {
            this.logEventWrapper('Shared document viewed', {
                document_id: documentMeta.documentId
            });
        } catch(error) {
            console.error(error);
        }
    }

    documentOpen(source, documentMeta) {
        try {
            let sourceMap = {
                icon: 'Icon',
                result: 'Result',
                preview: 'Preview'
            };

            this.logEventWrapper(`Document opened by clicking ${sourceMap[source]}`, {
                'document_meta': documentMeta
            });
        } catch(error) {
            console.error(error);
        }
    }

    documentAction(documentMeta, action) {
        try {
            this.logEventWrapper(`Document ${action}`, {
                'document_meta': documentMeta
            });
        } catch(error) {
            console.error(error);
        }
    }
}
