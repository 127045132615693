// PopupMessage.js

import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Button, ModalHeader, ModalFooter } from "reactstrap"; // Import modal components from reactstrap

const PopupMessage = ({ isOpen, onClose }) => {
  // const [seeMore, setSeemore] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the screen width is less than or equal to 767px
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Modal
      style={{ maxWidth: "95%", maxHeight: "50vh" }}
      centered={true}
      isOpen={isOpen}
      toggle={onClose}
    >
      <ModalHeader
        className="d-flex align-items-center justify-content-center"
        style={{ color: "red" }}
      >
        CAUTION
      </ModalHeader>
      <ModalBody
        style={{
          maxHeight: isMobile ? "calc(50vh - 130px)" : "none",
          overflowY: isMobile ? "auto" : "visible",
        }}
      >
        <div>
          <p
            style={{
              lineHeight: "1.25rem",
              marginBottom: "0.5rem",
              fontSize: "14px",
            }}
          >
            Over the last few months, certain unknown miscreants have formed
            numerous WhatsApp groups/fake websites and domain names misusing our
            name, infringing on our trademark, logo and impersonating our
            employee, Mr. Naresh Rathi, thereby inducing people to invest in
            stocks, falsely assuring them high profits and then duping them of
            their monies. Some people are being misled by these fraudsters who
            are posing as Avendus representatives into believing that they have
            been duped by Avendus, its affiliates and employees. A list of these
            fake groups and apps that we have identified/have been brought to
            our notice can be accessed via this{" "}
            <a
              target="_blank"
              without
              rel="noreferrer"
              href="https://www.avendus.com/india/fraud-whatsapp-groups-and-applications"
              style={{ color: "#0EAFE2", fontWeight: "bold" }}
            >
              link
            </a>
            . There could also be other surreptitious groups that have not come
            to our notice but are abusing our trade name or that of any of our
            employees to defraud the general public. We have lodged an FIR at
            the BKC Police Station, Mumbai (FIR No. 0518) in relation to the
            aforesaid acts and also informed regulatory authorities about the
            same. We have also requested them to take the strictest possible
            action against the perpetrators.
          </p>
          <p
            style={{
              lineHeight: "1.25rem",
              marginBottom: "0.5rem",
              fontSize: "14px",
            }}
          >
            Please note, Avendus Group comprising Avendus Capital Private
            Limited and its affiliates including Spark Institutional Equities
            Private Limited (with the brand names “Avendus”, “Avendus Spark”)
            are regulated entities. Neither we, nor any of our partners,
            directors or employees have created or subscribed to any such fake
            websites or groups on any third-party apps like WhatsApp, Telegram
            etc. that deal in stock market information or tips, nor do we
            endorse any such dubious activities that encourage people to part
            with their hard-earned monies by assuring high returns.
          </p>
          <p
            style={{
              lineHeight: "1.25rem",
              marginBottom: "0.5rem",
              fontSize: "14px",
            }}
          >
            We recommend that you only trust communication from authorized
            Avendus platforms –{" "}
            <a
              target="_blank"
              without
              rel="noreferrer"
              href="https://www.avendus.com/india"
              style={{ color: "#0EAFE2", fontWeight: "bold" }}
            >
              Official Website
            </a>
            ,{" "}
            <a
              target="_blank"
              without
              rel="noreferrer"
              href="https://www.linkedin.com/company/avendus/"
              style={{ color: "#0EAFE2", fontWeight: "bold" }}
            >
              LinkedIn
            </a>
            ,{" "}
            <a
              target="_blank"
              without
              rel="noreferrer"
              href="https://x.com/avendus"
              style={{ color: "#0EAFE2", fontWeight: "bold" }}
            >
              X
            </a>
            ,{" "}
            <a
              target="_blank"
              without
              rel="noreferrer"
              href="https://www.instagram.com/avendusgroup/"
              style={{ color: "#0EAFE2", fontWeight: "bold" }}
            >
              Instagram
            </a>
            ,{" "}
            <a
              target="_blank"
              without
              rel="noreferrer"
              href="https://www.youtube.com/@AvendusGroup"
              style={{ color: "#0EAFE2", fontWeight: "bold" }}
            >
              YouTube
            </a>
            . We also urge Avendus clients to only trust communication with/from
            your respective relationship managers in this regard. Anyone dealing
            with unauthorized entities or disbursing monies or acting on such
            tips without proper verification shall be doing so at their own risk
            and Avendus Group shall neither be responsible in any manner, nor
            can it assist the victims in recovering their lost monies. We
            strongly urge you to be vigilant and conduct thorough due diligence
            before acting on stock tips shared on such fraudulent groups
            impersonating Avendus or Naresh Rathi or any other employee
            {/* {!seeMore && (
              <button
                style={{
                  color: "blue",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => setSeemore(true)}
              >
                {" "}
                Read More
              </button>
            )} */}
          </p>
          <p
            style={{
              lineHeight: "1.25rem",
              marginBottom: "0.5rem",
              fontSize: "14px",
            }}
          >
            In case anyone misleads you in the name of Avendus or Avendus Spark
            or any other affiliate entities or its employees in any manner, you
            may consider filing a complaint at your jurisdictional police
            station.
          </p>
          {/* {seeMore ? (
            <>
              <p style={{ lineHeight: "2rem", marginBottom: "0.5rem" }}>
                In case anyone misleads you in the name of Avendus or Avendus
                Spark or any other affiliate entities or its employees in any
                manner, you may consider filing a police complaint and bringing
                it to the notice of the cyber crime cell and stock exchanges. We
                have already approached the police, cyber crime wing and
                relevant regulatory authorities in relation to the aforesaid
                acts and are exploring the strictest possible action against the
                perpetrators.
              </p>
            </>
          ) : (
            <></>
          )} */}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex align-items-center justify-content-center">
        <Button className={"btn-primary"} onClick={onClose}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PopupMessage;
