import React, { Component } from 'react';
import './style.scss';
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal, ModalBody, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ResponseModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.props
        };

        this.iconMapping = {
            success: "check-circle",
            warning: "exclamation-circle",
            error: "times-circle",
            info: "info-circle",
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if(this.props.onClickAction) this.props.onClickAction();
        else this.setState({ modal: !this.state.modal });
    }

    componentDidUpdate() {
        this.state = {
            ...this.props
        };
    }

    render() {
        const { modal, backdrop } = this.state;
        const { type, modalClass, modalTitle, modalText, titleClass, textClass, buttonText, buttonClass, functionalBtn, isBtnLoading } = this.props;

        return (
            <div>
                <Modal
                    centered={true}
                    isOpen={modal}
                    toggle={this.toggle}
                    className={modalClass}
                    backdrop={backdrop}
                >
                    <ModalBody>
                        <FontAwesomeIcon
                            className={"popover-icon " + type}
                            icon={this.iconMapping[type]}
                            size="5x"
                        />

                        <div className={titleClass}>{modalTitle}</div>
                        <div className={textClass} >{modalText}</div>

                        {
                            buttonText &&
                            <Button className={buttonClass} onClick={this.toggle}>{buttonText}</Button>
                        }
                        {
                            functionalBtn &&
                            Object.keys(functionalBtn).length &&
                            <Button
                                className={functionalBtn.className}
                                onClick={functionalBtn.onClickHandler}
                            >
                                {!isBtnLoading ? functionalBtn.text : <Spinner size="sm" color="white" />}
                            </Button>
                        }
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ResponseModal;
