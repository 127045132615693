import { cssTransition, toast } from 'react-toastify';
import SparkToast from "../components/SparkToast";
import {TOAST_TYPE_ERROR, TOAST_TYPE_INFO, TOAST_TYPE_WARNING} from "../constants/strings";
import React from "react";
import {ToastCloseButton} from "../components/ToastCloseButton";

const fade = cssTransition({
    enter: 'fadeInUp',
    exit: 'fadeOutDown',
    duration: 750,
});

export default class toastify {
    makeSparkToastJSX(toastType, msg) {
        return <SparkToast toastType={toastType} msg={msg}/>;
    }

    fire = (msg, toastId, toastType) => {
        try {
            toast.dismiss();

            let type = toast.TYPE.SUCCESS;
            let sparkToastJSX = this.makeSparkToastJSX(toastType, msg);

            switch (toastType) {
                case TOAST_TYPE_ERROR:
                    type = toast.TYPE.ERROR;
                    break;
                case TOAST_TYPE_INFO:
                    type = toast.TYPE.INFO;
                    break;
                case TOAST_TYPE_WARNING:
                    type = toast.TYPE.WARNING;
                    break;
                default:
                    break;
            }

            toast(sparkToastJSX, {
                position: "bottom-center",
                autoClose: 2000,
                type,
                toastId
            });
        } catch(ex) {
            console.error(ex);
        }
    };
};
