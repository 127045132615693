//This is a modified version of Bootstrap 4 card
import React, { Component, Fragment } from 'react';
import './style.scss';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import DocumentSearch from "../../modules/DocumentSearch";
import DocumentUpload from "../../modules/DocumentUpload";
import {
    ROUTE_NAME_DOCUMENT_SEARCH,
    ROUTE_NAME_DOCUMENT_UPLOAD,
    ROUTE_NAME_TERMS_CONDITIONS,
    ROUTE_NAME_PODCASTS,
    ROUTE_NAME_PODCASTS_SHARE,
    ENDPOINT_BANNER, ROUTE_NAME_ACCESS_CONTROL,
    ROUTE_NAME_WATERMARK, ROUTE_NAME_CREATE_USER,
    ROUTE_NAME_DOWNLOAD_ACCESS_CONTROL,
    ROUTE_NAME_PODCAST_UPLOAD,
    ROUTE_NAME_PODCAST_LIST,
    ROUTE_NAME_PODCAST_EDIT
} from "../../constants/strings";
import { getParameterByName, insertParam } from "../../utils/Browser";
import HeaderBar from '../../components/HeaderBar';
import Footer from '../../components/Footer';
import TermsConditions from '../../modules/TermsConditions';
import Podcasts from '../../modules/Podcasts';
import UniversalBanner from "../UniversalBanner";
import common from "../../apis/common";
import moment from "moment";
import AccessControl from "../../modules/AccessControl/access-control.page";
import WaterMark from "../../modules/WaterMark";
import CreateUser from "../../modules/CreateUser";
import DownloadAccessControl from "../../modules/DownloadAccessControl";
import Banner from '../Banner';
import PodcastList from '../../modules/PodcastUpload/index';
import PodcastUpload from '../../modules/PodcastUpload/Upload';
import PodcastEdit from '../../modules/PodcastUpload/Edit';
import PodcastNew from '../../modules/PodcastNew';
import ComplaintRegister from '../../modules/Complaint/ComplaintRegister';


class MainApp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            banner: {
                show: false,
                expire_on: '',
                content: ''
            }
        };

        Hub.listen("auth", ({ payload: { event } }) => {
            switch (event) {
                case "signOut":
                    if(this.props.auth.isAuthenticated) {
                        window.history.pushState(
                            "",
                            "",
                            "/" + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split("?")[0]
                        );

                        this.props.auth.setAuthStatus(false);
                        this.props.auth.setUser(null);
                    }
                    break;

                default:
                    break;
            }
        });
    }

    async componentDidMount() {
        try {
            const session = await Auth.currentSession();

            this.props.auth.setAuthStatus(true);

            const user = await Auth.currentAuthenticatedUser();

            this.props.auth.setUser(user);

            if (this.props.name === ROUTE_NAME_DOCUMENT_SEARCH) {
                let redirectedSharedDocumentId = toJS(this.props.store.temporarySharedDocumentId);
                if (redirectedSharedDocumentId) {
                    this.props.store.temporarySharedDocumentId = "";
                    insertParam("id", redirectedSharedDocumentId);
                }
            }

            if (!this.props.auth.isAuthenticated) {
                if (this.props.name === ROUTE_NAME_DOCUMENT_SEARCH) {
                    let enteredSharedDocumentId = getParameterByName('id', window.location.href);
                    if (enteredSharedDocumentId) {
                        this.props.store.temporarySharedDocumentId = enteredSharedDocumentId;
                    }
                }
            }

            this.getBannerContent(session);
        } catch (error) {
            await Auth.signOut();
            console.log(error);
        }
    }

    getBannerContent(session) {
        common.get(ENDPOINT_BANNER,
            {
                params: {},
                headers: { "Authorization": session.accessToken.jwtToken }
            }
        )
        .then(this.onGetBannerContentSuccess)
        .catch(this.onGetBannerContentFailure);
    }

    onGetBannerContentSuccess = res => {
        if (res.status && res.data && res.data.data) {
            const { show, expire_on, message } = res.data.data;
            const banner = {
                show, expire_on, message
            };

            this.setState({banner});
        }
    }

    onGetBannerContentFailure = () => {}

    getBannerJSX(banner) {
        const currentTimestamp = moment().valueOf();
        const expiryTimestamp = banner.expire_on && banner.expire_on.length ? moment(banner.expire_on, 'DD/MM/YYYY HH:mm:ss').valueOf() : 0;
        return banner.show && expiryTimestamp > currentTimestamp ? (<UniversalBanner text={banner.message}/>) : null;
    }

    render() {
        const { banner } = this.state;
        const bannerJSX = this.getBannerJSX(banner);

        return (
            <Fragment>
                { bannerJSX }

                {/* <Banner></Banner> */}
                <HeaderBar
                    {...this.props}
                />
                {
                    this.props.name === ROUTE_NAME_DOCUMENT_SEARCH &&
                    <DocumentSearch {...this.props} />
                }
                {
                    this.props.name === ROUTE_NAME_DOCUMENT_UPLOAD &&
                    <DocumentUpload {...this.props} />
                }
                {
                    this.props.name === ROUTE_NAME_PODCAST_LIST &&
                    <PodcastList {...this.props} />
                }
                {
                    this.props.name === ROUTE_NAME_PODCAST_UPLOAD &&
                    <PodcastUpload {...this.props} />
                }
                {
                    this.props.name === ROUTE_NAME_PODCAST_EDIT &&
                    <PodcastEdit {...this.props} />
                }
                {
                    this.props.name === ROUTE_NAME_PODCASTS &&
                    <PodcastNew {...this.props} />
                }
                {
                    this.props.name === ROUTE_NAME_PODCASTS_SHARE &&
                    <Podcasts {...this.props} />
                }
                {
                    this.props.name === ROUTE_NAME_ACCESS_CONTROL &&
                    <AccessControl {...this.props} />
                }
                {
                    this.props.name === ROUTE_NAME_TERMS_CONDITIONS &&
                    <TermsConditions {...this.props} />
                }
                {
                    this.props.name === ROUTE_NAME_WATERMARK &&
                    <WaterMark {...this.props} />
                }
                {

                    this.props.name === ROUTE_NAME_CREATE_USER &&
                    <CreateUser {...this.props} />
                }
                {
                    this.props.name === ROUTE_NAME_DOWNLOAD_ACCESS_CONTROL &&
                    <DownloadAccessControl {...this.props} />
                }
                {
                    this.props.name === "demo" &&
                    <ComplaintRegister {...this.props} />
                }
                {
                    (this.props.name !== ROUTE_NAME_TERMS_CONDITIONS && this.props.name !== ROUTE_NAME_PODCAST_LIST) &&
                    <Footer
                        {...this.props}
                    />
                }
            </Fragment>
        );
    }
}

export default observer(MainApp);
