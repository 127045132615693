//	this will accept api response format data as input and convert it into list of objects -
//  - in the format {'value': abc, category: 'sector'},{'value': '...','category': '...'}
var isObject = require('lodash.isobject');
var isString = require('lodash.isstring');

let utilFunctions = {
    getDropDownLists: (data) => {
        let whitelist = [];
        let blacklist = [];
        data.forEach((obj) => {
            if (!isObject(obj)) {
                return;
            }
            let item = {};

            item.category = obj.category;

            if (obj.category === 'stock') {
                item.value = obj.category_name.stock_name.trim();
                item.nse_ticker = obj.category_name.nse_ticker;
                item.bse_ticker = obj.category_name.bse_ticker;
                item.bbg_ticker = obj.category_name.bbg_ticker;
                item.stock_id = obj.category_name.stock_id;
                if(obj.category_name.sector_id){
                    item.sector_id = obj.category_name.sector_id;
                }
                if(obj.category_name.sector_name){
                    item.sector_name = obj.category_name.sector_name;
                }
            }
            else {
                item.value = isString(obj.category_name) ? obj.category_name.trim() : obj.category_name;
            }
            whitelist.push(item);
        });
        // return [whitelist, blacklist];
        return whitelist;
    },
    toTitleCase: (str) => {
        if ((str === null) || (str === ''))
            return '';
        else
            str = str.toString();

        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    }
};

export default utilFunctions;