import React, { Component } from 'react';
import { observer } from "mobx-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Pagination extends Component {
    constructor(props) {
        super(props);
    }

    onPrevButtonClick = () => {
        this.checkPageNumberAndUpdateFilters(this.props.store.pagination.currentPage - 1);
    };

    onNextButtonClick = () => {
        this.checkPageNumberAndUpdateFilters(this.props.store.pagination.currentPage + 1);
    };

    onPageInput = (e) => {
        this.props.store.setCurrentPage(e.target.value);
    };

    onPageKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (!isNaN(e.target.value)) {
                this.checkPageNumberAndUpdateFilters(parseInt(e.target.value));
            }
        }
    };

    checkPageNumberAndUpdateFilters(pageNumber) {
        try {
            let from = this.calculateFromFilter(pageNumber);
            if (from !== false) {
                this.props.store.setFilters('from', null, from);
                this.props.store.setCurrentPage(pageNumber);
                this.props.makeFetchResultsApiCall();
            }
        } catch (e) {
            console.log(e);
        }
    }

    calculateFromFilter(pageNumber) {
        if (pageNumber <= 0) {
            return false;
        }
        let from = pageNumber * this.props.store.filters.size - this.props.store.filters.size;
        if (from < this.props.totalResults) {
            return from;
        }
        return false;
    }

    render() {
        return (
            <div className="pagination">
                <button className="prev" onClick={this.onPrevButtonClick}>
                    <FontAwesomeIcon
                        icon="caret-left"
                    />
                </button>
                <div className="current-page">
                    <span className="label">Page: </span>
                    <input className="current-page-input" type="text" value={this.props.store.pagination.currentPage} onChange={this.onPageInput} onKeyPress={this.onPageKeyPress} />
                    <span className="total-result">{"/" + this.props.store.pagination.totalPages}</span>
                </div>
                <button className="next" onClick={this.onNextButtonClick}>
                    <FontAwesomeIcon
                        icon="caret-right"
                    />
                </button>
            </div>
        )
    }
}

export default observer(Pagination);
