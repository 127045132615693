import React from 'react';
import { observer } from 'mobx-react';
import Tagify from '@yaireo/tagify';
import utilFunctions from '../../utils/SearchModuleUtils';
import { getSession } from '../../utils/AuthUtils';
import common from '../../apis/common';

class TagsInput extends React.PureComponent {
    constructor(props) {
        
        super(props);
        this._handleRef = this._handleRef.bind(this);
        this.value = '';
        this.tagifySettings = {
            whitelist: this.props.config.hasOwnProperty('whitelist') ? utilFunctions.getDropDownLists(this.props.config.whitelist) : [],
            blacklist: this.props.config.hasOwnProperty('blacklist') ? this.props.config.blacklist : [],
            maxTags: this.props.config.hasOwnProperty('maxTags') ? this.props.config.maxTags : Infinity,
            duplicates: this.props.config.hasOwnProperty('duplicates') ? this.props.config.duplicates : false,
            backspace: true,
            addTagOnBlur: true,
            delimiters: "°",
            dropdown: {
                enabled: this.props.config.hasOwnProperty('dropdown') ? this.props.config.dropdown.enabled : 2, // always show suggestions dropdown,
                maxItems: Infinity,
                highlightFirst: false
            },
            enforceWhitelist: this.props.config.hasOwnProperty('enforceWhitelist') ? this.props.config.enforceWhitelist : false,
            templates: {
                tag: this.getTagTemplate.bind(this),
                dropdownItem: this.getDropdownTemplate.bind(this)
            },
            callbacks: {
                add: (e) => {
                    this.props.on_add(
                        e,
                        this.props.config.identifier,
                        this.props.config.forceSuggesterResults
                    )
                },
                remove: (e) => {
                    this.props.on_remove(e, this.props.config.identifier)
                },
                input: (e) => {
                    this.value = e.detail.value;

                    if (!this.props.config.suggester || !this.props.config.hasOwnProperty('fetchDynamically')) {
                        return;
                    }
                    if (!this.props.config.fetchDynamically
                    ) {
                        this.props.store.tagify[this.props.filterType][this.props.config.identifier].dropdown.show.call(
                            this.props.store.tagify[this.props.filterType][this.props.config.identifier], this.value
                        );
                        return;
                    }
                    let suggestionType = this.props.config.suggestionTypes ? this.props.config.suggestionTypes : (
                            this.props.config.suggestionIdentifier ? this.props.config.suggestionIdentifier : 
                            this.props.config.identifier
                        );

                    let payload = this.props.generatePayload({ identifier: suggestionType }, this.value);

                    this.getSuggestions(payload)
                        .then(this.onGetSuggestionsSuccessCallback)
                        .catch(this.onGetSuggestionsErrorCallback);
                },
            }
        };
        this.state = {
            sectors: []
        };
        if (this.props.transformTag){
            this.tagifySettings.transformTag = (tagData)=>{
                this.props.transformTag(tagData, this.props.config.identifier);
            };
        }
    }

    getAttributes = (tagData) => {
        let attributeString = '';
        Object.keys(tagData).forEach((key) => {
            attributeString += `${key}="${tagData[key]}" `;
        });
        return attributeString;
    };

    /**
     * @desc Method for making the API call to get suggestion for whitelist
     * @async
     * @returns {object} - Response object from the server
     */
    async getSectors() {
        let user = this.props.auth.user;
        let session = await getSession();
        if (!session) {
            return;
        }
        let sectors = user.attributes["custom:sectors"];
        let restriction;
        if (user.attributes["custom:restriction"]) {
            restriction = user.attributes["custom:restriction"];
        }
        try {
            if (sectors && sectors != "") {
                sectors = JSON.parse(sectors)
            }
            // if (restriction == "Partial Restriction") {
            //     sectors = ["ALL"]
            // }
        } catch (err) {
            sectors = ["ALL"]
        }
        this.setState({ sectors: sectors || ["ALL"] })
    }
    async getSuggestions(payload) {
        let session = await getSession();
        if (this.state.sectors?.length <= 0) {
            await this.getSectors();
        }
        if (!session) {
            return [];
        }
        const data = await this.props.apiObj.get(
            this.props.config.apiURL,
            {
                params: {
                    ...payload,
                    sectors: this.state.sectors
                },
                headers: { "Authorization": session.accessToken.jwtToken }
            });
        return data;
    }

    onGetSuggestionsSuccessCallback = (response) => {
        if (response.hasOwnProperty('status') &&
            response.status === 200 &&
            response.data.status &&
            response.data.hasOwnProperty("data") &&
            response.data.data.length
        ) {
            let results = response.data.data;
            let whitelist;
            whitelist = utilFunctions.getDropDownLists(results);
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].settings.whitelist.length = 0; // reset the whitelist
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].settings.whitelist = whitelist;
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].dropdown.show.call(
                this.props.store.tagify[this.props.filterType][this.props.config.identifier], ''); // render the suggestions dropdown
        }
        else {
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].settings.whitelist = [];
        }
    };

    onGetDropdownSuccessCallback = (response) => {
        if (response.hasOwnProperty('status') &&
            response.status === 200 &&
            response.data.status &&
            response.data.hasOwnProperty("data") &&
            response.data.data.length) {
            let results = response.data.data;
            let whitelist;
            whitelist = utilFunctions.getDropDownLists(results);
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].settings.whitelist.length = 0; // reset the whitelist
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].settings.whitelist = whitelist;
            // this.props.store.tagify[this.props.filterType][this.props.config.identifier].dropdown.show.call(
            //     this.props.store.tagify[this.props.filterType][this.props.config.identifier], ''); // render the suggestions dropdown
            this.addInputClickListener();
        }
    };

    addInputClickListener(){
        let self = this;
        this.props.store.tagify[this.props.filterType][this.props.config.identifier].
            DOM.scope.removeEventListener('click', function (e) { });
        this.props.store.tagify[this.props.filterType][this.props.config.identifier].
            DOM.scope.addEventListener('click', function (e) {
                if (!e.target.matches('tag') && !e.target.closest("tag")) {
                    self.props.store.tagify[self.props.filterType][self.props.config.identifier].dropdown.show.call(
                        self.props.store.tagify[self.props.filterType][self.props.config.identifier], '');
                }
            })
    }

    onGetSuggestionsErrorCallback = (err) => {
        console.error(err);
        this.props.store.tagify[this.props.filterType][this.props.config.identifier].settings.whitelist = [];
    };

    componentDidMount() {
        let self = this;
        this.props.store.tagify[this.props.filterType][this.props.config.identifier] = new Tagify(this.component, this.tagifySettings || {});

        if (this.props.config.suggester && this.props.config.hasOwnProperty('fetchDynamically') && !this.props.config.fetchDynamically
        ) {
            let payload = this.props.generatePayload(
                { identifier: (this.props.config.hasOwnProperty("suggestionIdentifier") ? this.props.config.suggestionIdentifier : this.props.config.identifier) }
                );
            this.getSuggestions(payload)
                .then(this.onGetDropdownSuccessCallback)
                .catch(this.onGetSuggestionsErrorCallback);
        }
        if (this.props.hasOwnProperty("onKeyPress")) {
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].
                DOM.scope.removeEventListener('keyup', function (e) { });
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].
                DOM.scope.addEventListener('keyup', this.props.onKeyPress);
        }
        if (this.props.hasOwnProperty("expandOnhover") && this.props.expandOnhover) {
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].
            DOM.scope.removeEventListener('mouseenter', function (e) {});

            this.props.store.tagify[this.props.filterType][this.props.config.identifier].
            DOM.scope.addEventListener('mouseenter', (e)=>{
                self.props.store.tagify[self.props.filterType][self.props.config.identifier].DOM.scope.classList.remove("collapsed-input");
            });
        }
        if (this.props.hasOwnProperty("expandOnhover") && this.props.expandOnhover) {
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].
            DOM.scope.removeEventListener('mouseleave', function (e) { });
            
            this.props.store.tagify[this.props.filterType][this.props.config.identifier].
            DOM.scope.addEventListener('mouseleave', (e)=>{
                self.props.store.tagify[self.props.filterType][self.props.config.identifier].DOM.scope.classList.add("collapsed-input");
            });
        }
        this.props.store.tagify[this.props.filterType][this.props.config.identifier].
            DOM.scope.removeEventListener('dblclick', function (e) { });

        this.props.store.tagify[this.props.filterType][this.props.config.identifier].
            DOM.scope.addEventListener('dblclick', function (e) {
                if (e.target.matches('tag') || e.target.closest("tag")) {
                    e.target.dispatchEvent(new KeyboardEvent('keydown', { 'key': 'Escape' }));
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                }
            });
    }


    getTagTemplate(v, tagData){
        if (this.props.config.identifier === 'input') {
            let tags = this.props.store.tagify[this.props.filterType][this.props.config.identifier].getTagElms();
            if (tags.length) {
                tags.forEach((tag) => {
                    if (tagData.hasOwnProperty("category") && tag.getAttribute('value') === v && tag.getAttribute('category') === tagData.category) {
                        tagData.category = this.props.defaultFilter;
                    }
                });
            }
        }
        if (!tagData.hasOwnProperty("category")) {
            if (this.props.config.identifier !== 'input') {
                tagData.category = (this.props.config.hasOwnProperty("suggestionIdentifier") ? this.props.config.suggestionIdentifier : this.props.config.identifier);
            } else {
                tagData.category = this.props.defaultFilter;
            }
        }
        
        try {
            return `
                <tag title='${v}' contenteditable='false' spellcheck="false" class='tagify__tag ${tagData.class ? tagData.class : ""}'
                    ${this.getAttributes(tagData)}
                >
                    <x title='remove tag' class='tagify__tag__removeBtn'></x>
                    <div>
                        <span class='tagify__tag-text'>${v}</span>
                    </div>
                </tag>
            `;
        }
        catch (err) {
            console.log(err);
        }
    }

    getDropdownTemplate(tagData) {
        try {
            let typeClass = ['advanceSearch', 'fieldInputs'].includes(this.props.filterType) ? 'advance-search-item' : '';
            let extraElements = this.props.filterType === 'mainSearch' ? `<div class="type"> ${tagData.category === 'authors' ? "Analyst" :utilFunctions.toTitleCase(tagData.category.replace(/_/g, " "))}</div>` : '';
            let disabledClass = '';
            if (this.props.disableSuggestion && this.props.disableSuggestion(tagData.value, this.props.config.identifier)) {
                disabledClass = `disabled`;
            }
            if (tagData.category === 'stock') {
                let value = tagData.hasOwnProperty("value") && tagData.value ? tagData.value : '—';
                let bse_ticker = tagData.hasOwnProperty("bse_ticker") && tagData.bse_ticker ? tagData.bse_ticker : '—';
                let nse_ticker = tagData.hasOwnProperty("nse_ticker") && tagData.nse_ticker ? tagData.nse_ticker : '—';
                let bbg_ticker = tagData.hasOwnProperty("bbg_ticker") && tagData.bbg_ticker ? tagData.bbg_ticker : '—';

                return `
                    <div class="tagify__dropdown__item ${typeClass} search-result-area-item ${disabledClass}" identifier="stock" >
                        ${ extraElements}
                        <div class="name">
                            <div class="stock-name" in-watchlist="true">${value}</div>
                            <div class="bse-ticker" in-watchlist="true" title=${bse_ticker}>${bse_ticker}</div>
                            <div class="nse-ticker" in-watchlist="true" title=${nse_ticker}>${nse_ticker}</div>
                            <div class="bbg-ticker" in-watchlist="true" title=${bbg_ticker}>${bbg_ticker}</div>
                        </div>
                    </div>
                `;
            } else {
                return `
                    <div class="tagify__dropdown__item ${typeClass} search-result-area-item ${disabledClass}" identifier="${tagData.category}">
                        ${ extraElements}
                        <div class="name">${tagData.value}</div>
                    </div>
                `;
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    _handleRef(component) {
        this.component = component;
    }

    render() {
        const attrs = {
            ref: this._handleRef,
            className: this.props.className,
            placeholder: this.props.placeholder,
        };

        return (
            this.props.mode === 'textarea' ?
                <textarea {...attrs} defaultValue={this.props.initialValue} /> :
                <input {...attrs} defaultValue={this.props.initialValue} />
        );
    }
}

export default observer(TagsInput);
