import React, {Component} from 'react';
import './style.scss';
// import {SECTION_UNAUDITED_USERS} from "../../constants/strings";
import Search from "../../components/Search/search.component";
import {
    DAC_TOAST_ID, SECTION_AUDITED_USERS, SECTION_UNAUDITED_USERS, TITLE_STH_WENT_WRONG,
    TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS,
    UI_STATE_EMPTY,
    UI_STATE_FAILURE,
    UI_STATE_LOADING,
    UI_STATE_SUCCESS,
} from "../../constants/strings";
import {Spinner, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getDownloadAccessControlUsers, handleAccessToggle} from "./download-access-control.api";
import ToggleSwitch from "../../components/ToggleSwitch";
import TablePagination from "../../components/TablePagination/table-pagination.component";
import {checkUserInCognitoGroup} from "../../utils/AuthUtils";

class DownloadAccessControl extends Component {
    constructor(props) {
        super(props);

        this.state ={
            viewState: UI_STATE_EMPTY,
            users: null,
            searchValue: '',
            pageNum: 1,
            size: 20
        }
    }

    componentDidMount() {
        if (!this.props.auth.user ||
            !checkUserInCognitoGroup(this.props.auth.user, "spark-download-permissions-admins")
        ) {
            this.props.history.push("/document_search");
        }

        this.getUsersData();
    }

    getUsersData = () => {
        let searchValue = this.state.searchValue;
        let record_per_page = this.state.size;
        let page = this.state.pageNum;
        this.setState({viewState: UI_STATE_LOADING})
        getDownloadAccessControlUsers(searchValue, record_per_page, page)
            .then(res=> {
                if (res.status === 200 && res.data.status) {
                    if (res.data.data.users_data.length) {
                        this.setState({users: res.data.data, viewState: UI_STATE_SUCCESS})
                    } else {
                        this.setState({viewState: UI_STATE_EMPTY})
                    }
                } else {
                    this.setState({viewState: UI_STATE_FAILURE})
                }
            })
            .catch(ex=> {
                console.log(ex)
                this.setState({viewState: UI_STATE_FAILURE})
            })
    }

    handleToggle = (state, email, download_enabled) => {
        let payload = {
            "username": email,
            "flag": download_enabled ? 0 : 1
        }
        handleAccessToggle(payload)
            .then(res=> {
                if(res.status === 200) {
                    if(res.data.status){
                        this.props.toastify.fire(res.data.success_message, DAC_TOAST_ID, TOAST_TYPE_SUCCESS);
                        this.getUsersData();
                    }else {
                        this.props.toastify.fire(res.data.error_message, DAC_TOAST_ID, TOAST_TYPE_ERROR);
                        this.getUsersData();
                    }
                } else {
                    this.props.toastify.fire(TITLE_STH_WENT_WRONG, DAC_TOAST_ID, TOAST_TYPE_ERROR);
                }
            })
            .catch(err=> {
                console.log(err)
                this.props.toastify.fire(TITLE_STH_WENT_WRONG, DAC_TOAST_ID, TOAST_TYPE_ERROR);
            })
    }

    getJSXForUsersTable(data) {
        const tableRowsJSX = [];
        data.users_data.forEach((elem, index) => {
            tableRowsJSX.push(
                <tr key={`download-access-user-row-${index}`}>
                    <td>{elem.name}</td>
                    <td>{elem.organisation_name}</td>
                    <td>{elem.email}</td>
                    <td>{elem.phone_number}</td>
                    <td>{elem.signup_date}</td>
                    <td>
                        <ToggleSwitch switchState={(state)=>this.handleToggle(state, elem.email, elem.download_enabled)} checked={elem.download_enabled}/>
                    </td>
                </tr>
            );
        });

        return (
            <div className='mb-4'>
                <Table hover responsive borderless>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Organization</th>
                        <th>Email</th>
                        <th>Contact Number</th>
                        <th>Signed Up At</th>
                        <th>Access</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableRowsJSX}
                    </tbody>
                </Table>

            </div>
        );
    }
    
    getJSXForUIState = () => {
        switch (this.state.viewState) {
            case UI_STATE_LOADING:
                return (
                    <div className="ui-state loading">
                        <Spinner type="grow" color="secondary" />
                        <Spinner type="grow" color="secondary" />
                        <Spinner type="grow" color="secondary" />
                    </div>
                );
            case UI_STATE_SUCCESS:
                const tableJSX = this.state.users ? this.getJSXForUsersTable(this.state.users) : null;

                return (
                    <div className="ui-state success">
                        {tableJSX}
                    </div>
                );
            case UI_STATE_FAILURE:
                return (
                    <div className="ui-state fail">
                        {/*<FontAwesomeIcon*/}
                        {/*    icon="exclamation-circle"*/}
                        {/*    className="ico"*/}
                        {/*/>*/}
                        <h6 className="placeholder-text">
                            {/*SOMETHING WENT WRONG*/}
                        </h6>
                    </div>
                );
            case UI_STATE_EMPTY:
                return (
                    <div className="ui-state empty">
                        {/*<FontAwesomeIcon*/}
                        {/*    icon="info-circle"*/}
                        {/*    className="ico"*/}
                        {/*/>*/}
                        <h6 className="placeholder-text">
                            {/*NO RECORDS FOUND*/}
                        </h6>
                    </div>
                );
        }
    }

    search = (searchBy) => {
        this.setState({searchValue: searchBy}, ()=>this.getUsersData());
    }

    paginate = (pageNum) => {
        this.setState({pageNum: pageNum}, ()=>this.getUsersData());
    }

    render() {
        const usersList = this.getJSXForUIState();
        let totalPages = 1;
        if(this.state.users){
             totalPages = Math.ceil(this.state.users.total_records/this.state.size)
        }
        return (
            <div className='download-access-control'>
                <div className="download-access-control__inner-container spark-card">
                    <div className="download-access-control__inner-container__heading">
                        <h4>Block/Permit Download</h4>
                    </div>
                    <div className='download-access-control__inner-container__search'>
                        <Search
                            onSubmit={this.search}
                            onReset={this.search}
                            placeholder="Search by Name, Organization, Email Address or Contact Number"
                        />
                    </div>
                    <div className='download-access-control__inner-container__list'>
                        {usersList}
                        <TablePagination
                            paginationEnterHandler={this.paginate}
                            navBtnClickHandler={this.paginate}
                            currentPage={this.state.pageNum}
                            totalPages={totalPages}
                            // totalResults={totalRecordsUnaudited}
                            size={this.state.size}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default DownloadAccessControl;