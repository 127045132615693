import React from 'react';
import './style.scss';

function UniversalBanner(props) {
    return (
        <div className="universal-banner">
            {
                props.text
            }
        </div>
    );
}

export default UniversalBanner;
