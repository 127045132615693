import moment from "moment";
import { ENDPOINT_GET_PODCAST_SUGGESTIONS } from "../../constants/strings";

export const podcastfilterPanelValInitial = {
    "author_names": [],
    "stocks": [],
    "sectors": [],
};
export const mainSearchConfig = {
    identifier: "input",
    suggestionTypes: 'stock,sector,sector,authors',
    apiURL: ENDPOINT_GET_PODCAST_SUGGESTIONS,
    suggester: true,
    enforceWhitelist: false,
    forceSuggesterResults: false,
    fetchDynamically: true,
    whitelist: [],
    placeholder: 'Search by Company, Sector or Keyword(s) (e.g. "Auto", or "Eicher Motors", or "Royal Enfield")',
    duplicates: true
};

export const advancedSearchConfig = [
    {
        identifier: "stock", // This will uniquely identify a suggester element and an input box in advanced search panel
        name: "Stock", // This is the label which will be shown against an input box in advanced search panel
        placeholder: 'Maruti Suzuki, Bajaj Finserv, Bharti Airtel', // Placeholder for the input elemen <input />
        apiURL: ENDPOINT_GET_PODCAST_SUGGESTIONS,
        suggester: true,
        fetchDynamically: true,
        forceSuggesterResults: true,
        whitelist: [ // TODO: clear this hardcoded data
        ],
    },
    {
        identifier: 'sector', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'Sector', // This is the label which will be shown against an input box in advanced search panel
        placeholder: 'FMCG, Automobiles & Ancillaries, Bank', // Placeholder for the input element <input />
        apiURL: ENDPOINT_GET_PODCAST_SUGGESTIONS,
        suggester: true, // Whether to enable autocomplete suggesters for this particular input field
        fetchDynamically: false,
        forceSuggesterResults: true,
        whitelist: [
        ],
    },
    {
        identifier: 'authors', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'Analyst', // This is the label which will be shown against an input box in advanced search panel
        placeholder: '', // Placeholder for the input element <input />
        apiURL: ENDPOINT_GET_PODCAST_SUGGESTIONS,
        suggester: true, // Whether to enable autocomplete suggesters for this particular input field
        fetchDynamically: false,
        forceSuggesterResults: true,
        whitelist: [
        ],
    },
    {
        identifier: 'include_all', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'ALL of these words', // This is the label which will be shown against an input box in advanced search panel
        placeholder: 'Meeting, Disclosure, Announcement', // Placeholder for the input elemen <input />
        suggester: false,
    },
    {
        identifier: 'include_any', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'ANY of these words', // This is the label which will be shown against an input box in advanced search panel
        placeholder: 'Meeting, Disclosure, Announcement', // Placeholder for the input elemen <input />
        suggester: false,
    },
    {
        identifier: 'exclude_all', // This will uniquely identify a suggester element and an input box in advanced search panel
        name: 'NONE of these words', // This is the label which will be shown against an input box in advanced search panel
        placeholder: 'Meeting, Disclosure, Announcement', // Placeholder for the input element <input />
        suggester: false,
    },
];

export const filtersMapping = {
    stock: {
        mainKey: "stock_filter",
        secondaryKey: "stocks"
    },
    sector: {
        mainKey: "sector_filter",
        secondaryKey: "sectors"
    },
    include_all: {
        mainKey: "include_all",
        secondaryKey: "words"
    },
    include_any: {
        mainKey: "include_any",
        secondaryKey: "words"
    },
    exclude_all: {
        mainKey: "exclude_all",
        secondaryKey: "words"
    },
    authors: {
        mainKey: "author_filter",
        secondaryKey: "author_names"
    },
};

export const filterTemplate = {
    "author_filter": {
        "author_names": [
        ]
    },
    date_filter: {
        from_date: '2009-01-01',
        to_date: moment().format('YYYY-MM-DD')
    },
    podcast_filter: {
        podcast_mask: []
    },
    "stock_filter": {
        "stocks": [
        ]
    },
    "sector_filter": {
        "sectors": [
        ]
    },
    "sort": {
        "sort_by": "release_date",
        "order": "desc"
    },
    "include_all": {
        "words": [
        ]
    },
    "include_any": {
        "words": [
        ]
    },
    "exclude_all": {
        "words": [
        ]
    },
    "content": {
        "query": ""
    },
    "from": 0,
    "size": 20
};

export const dummydata = {
    "filters": [
        {
            "filter_key": "author_filter",
            "filter_name_key": "author_names",
            "heading": "Author",
            "values": [
                {
                    "doc_count": 1,
                    "key": "Ankit Dangayach"
                },
                {
                    "doc_count": 3,
                    "key": "Arjun N"
                },
                {
                    "doc_count": 2,
                    "key": "Mukesh Saraf"
                },
                {
                    "doc_count": 1,
                    "key": "Vijayaraghavan Swaminathan"
                },
                {
                    "doc_count": 4,
                    "key": "Abhinesh Vijayaraj"
                }
            ]
        },
        {
            "filter_key": "sector_filter",
            "filter_name_key": "sectors",
            "heading": "Sector",
            "values": [
                {
                    "doc_count": 3,
                    "key": "Oil & Gas"
                },
                {
                    "doc_count": 2,
                    "key": "Automobiles"
                },
                {
                    "doc_count": 6,
                    "key": "Banks"
                }
            ]
        },
        {
            "filter_key": "stock_filter",
            "filter_name_key": "stocks",
            "heading": "Stock",
            "values": [
                {
                    "doc_count": 1,
                    "key": "Repco Home Finance Ltd"
                },
                {
                    "doc_count": 1,
                    "key": "HDFC Bank Ltd"
                },
                {
                    "doc_count": 1,
                    "key": "TVS Motor Company Ltd"
                },
                {
                    "doc_count": 3,
                    "key": "Reliance Industries Ltd"
                },
                {
                    "doc_count": 4,
                    "key": "Axis Bank Ltd"
                },
                {
                    "doc_count": 1,
                    "key": "Maruti Suzuki India Ltd"
                }
            ]
        }
    ],
    "page_info": {
        "page": 1,
        "total_count": 8,
        "total_pages": 1
    },
    "podcasts": [
        {
            "CLIENT_ACCESS_KEY": "7rlvvb562ls6jkb3j1eod0qgef",
            "CREATED_DATE": "2023-09-14",
            "ID": 28,
            "IS_DELETED": 0,
            "LEAD_AUTHORS": [
                "Ankit Dangayach"
            ],
            "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/Test5.mp4",
            "RELEASE_DATE": "2023-09-16",
            "SEARCHABLE_FLAG": 1,
            "SECTOR_INFO": [
                "Banks"
            ],
            "SHARE_KEY": "nNKa67a9J2/5Mw4gKM81wAb/S%2By4m%2B72jZfBweb5xJk%3D",
            "SHORT_URL": "https://sprkc.cc/44XBZOp",
            "STOCK_INFO": [
                "Repco Home Finance Ltd"
            ],
            "SUB_AUTHORS": null,
            "TITLE": "Test5",
            "UPDATED_TIME": "2023-09-14",
            "URL_MASK": "8e40be1d3eaad34f4984c2c8b8c86ffbc6856304b1b380a14fe3949d766accf8"
        },
        {
            "CLIENT_ACCESS_KEY": "7rlvvb562ls6jkb3j1eod0qgef",
            "CREATED_DATE": "2023-09-16",
            "ID": 32,
            "IS_DELETED": 0,
            "LEAD_AUTHORS": [
                "Vijayaraghavan Swaminathan"
            ],
            "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/Ganesh Jayaraman's thoughts on CY23 Outlook.mp3",
            "RELEASE_DATE": "2023-09-16",
            "SEARCHABLE_FLAG": 1,
            "SECTOR_INFO": [
                "Banks"
            ],
            "SHARE_KEY": "nNKa67a9J2/5Mw4gKM81wCQGIxrJxmUZ40HREElUcG0KG52ORUpH45%2BzPscP9px2Fdceo9aMC9YK22PWaze3pA%3D%3D",
            "SHORT_URL": "https://sprkc.cc/48jWCqW",
            "STOCK_INFO": [
                "Axis Bank Ltd"
            ],
            "SUB_AUTHORS": null,
            "TITLE": "Ganesh Jayaraman's thoughts on CY23 Outlook",
            "UPDATED_TIME": "2023-09-16",
            "URL_MASK": "ca51dc159ba791244b999a03b106773e277122a51d65d629e2f6ee443e7a5183"
        },
        {
            "CLIENT_ACCESS_KEY": "7rlvvb562ls6jkb3j1eod0qgef",
            "CREATED_DATE": "2023-09-08",
            "ID": 16,
            "IS_DELETED": 0,
            "LEAD_AUTHORS": [
                "Abhinesh Vijayaraj",
                "Arjun N"
            ],
            "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/'Aroma Awakenings' - Gnanasundaram Saminathan on CCL Products.m4a",
            "RELEASE_DATE": "2023-09-12",
            "SEARCHABLE_FLAG": 1,
            "SECTOR_INFO": [
                "Banks",
                "Oil & Gas"
            ],
            "SHARE_KEY": "nNKa67a9J2/5Mw4gKM81wGIRtRY5wXSDlX9/VRoiEi/DEsLcBoFKJAR%2BVES9QkHrs58DAkdvckZXPdlvzA4BfbB25h8JdC3em7z3j4QHQX0%3D",
            "SHORT_URL": "https://sprkc.cc/45MVrPb",
            "STOCK_INFO": [
                "Axis Bank Ltd",
                "Reliance Industries Ltd"
            ],
            "SUB_AUTHORS": null,
            "TITLE": "'Aroma Awakenings' - Gnanasundaram Saminathan on CCL Products",
            "UPDATED_TIME": "2023-09-20",
            "URL_MASK": "a72baaa929a48f1596db306b7d2e95bd8c6ad95b9a0c208164a8f156373a4725"
        },
        {
            "CLIENT_ACCESS_KEY": "7rlvvb562ls6jkb3j1eod0qgef",
            "CREATED_DATE": "2023-09-11",
            "ID": 19,
            "IS_DELETED": 0,
            "LEAD_AUTHORS": [
                "Abhinesh Vijayaraj",
                "Arjun N"
            ],
            "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/'Deciphering the benefits from the probable UK FTA.'.m4a",
            "RELEASE_DATE": "2023-09-11",
            "SEARCHABLE_FLAG": 1,
            "SECTOR_INFO": [
                "Banks",
                "Oil & Gas"
            ],
            "SHARE_KEY": "nNKa67a9J2/5Mw4gKM81wFMN0P7ZNB/epXsGZLXgi5xTjjwX51TEFvEqz0eBQgMtsO1X7g%2Bkv0Zj6AlRT2bc8sTZ3pXhOHiY/H/mqXa5ejY%3D",
            "SHORT_URL": "https://sprkc.cc/44NI6os",
            "STOCK_INFO": [
                "Axis Bank Ltd",
                "Reliance Industries Ltd"
            ],
            "SUB_AUTHORS": null,
            "TITLE": "'Deciphering the benefits from the probable UK FTA.'",
            "UPDATED_TIME": "2023-09-11",
            "URL_MASK": "bfbf170d1bec92a206af07734e9a76c121ff86a7dac656f20f399b72734d2585"
        },
        {
            "CLIENT_ACCESS_KEY": "7rlvvb562ls6jkb3j1eod0qgef",
            "CREATED_DATE": "2023-09-08",
            "ID": 13,
            "IS_DELETED": 0,
            "LEAD_AUTHORS": [
                "Mukesh Saraf"
            ],
            "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/Mukesh Podcast.m4a",
            "RELEASE_DATE": "2023-09-08",
            "SEARCHABLE_FLAG": 1,
            "SECTOR_INFO": [
                "Automobiles"
            ],
            "SHARE_KEY": "nNKa67a9J2/5Mw4gKM81wIvZinC9vVsJvlVr7zEYzRk%3D",
            "SHORT_URL": "https://sprkc.cc/3PcsyVw",
            "STOCK_INFO": [
                "TVS Motor Company Ltd"
            ],
            "SUB_AUTHORS": null,
            "TITLE": "Mukesh Podcast",
            "UPDATED_TIME": "2023-09-08",
            "URL_MASK": "7974b06cf8d9b99096e508987f7ff413d453c3ffd4b39c6091ac70c054ff533a"
        },
        {
            "CLIENT_ACCESS_KEY": "7rlvvb562ls6jkb3j1eod0qgef",
            "CREATED_DATE": "2023-09-08",
            "ID": 14,
            "IS_DELETED": 0,
            "LEAD_AUTHORS": [
                "Mukesh Saraf"
            ],
            "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/Avendus Spark - FY24 Outlook - Financial Sector.m4a",
            "RELEASE_DATE": "2023-09-08",
            "SEARCHABLE_FLAG": 1,
            "SECTOR_INFO": [
                "Automobiles"
            ],
            "SHARE_KEY": "nNKa67a9J2/5Mw4gKM81wH%2B2r71VRHl/vSC%2BEyWDKiQ1sNOwFFFHO5xJ6RXAWywhGGmcbySUBYrUjJH20r/7qg%3D%3D",
            "SHORT_URL": "https://sprkc.cc/3RdYM5w",
            "STOCK_INFO": [
                "Maruti Suzuki India Ltd"
            ],
            "SUB_AUTHORS": null,
            "TITLE": "Avendus Spark - FY24 Outlook - Financial Sector",
            "UPDATED_TIME": "2023-09-08",
            "URL_MASK": "a395bec852d9825ab9427103d24799e8e0bc1899de9213dc937d786884cf8562"
        },
        {
            "CLIENT_ACCESS_KEY": "7rlvvb562ls6jkb3j1eod0qgef",
            "CREATED_DATE": "2023-09-08",
            "ID": 15,
            "IS_DELETED": 0,
            "LEAD_AUTHORS": [
                "Abhinesh Vijayaraj"
            ],
            "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/Watts Up - Mukesh Saraf on the Domestic EV Battery ecosystem.m4a",
            "RELEASE_DATE": "2023-09-08",
            "SEARCHABLE_FLAG": 1,
            "SECTOR_INFO": [
                "Banks"
            ],
            "SHARE_KEY": "nNKa67a9J2/5Mw4gKM81wF0GGQCfpS99ptowBb1fNu47gG524ybV3EZaP%2B%2BLVgxPeAoBNqbX518fY9z13yhgDjaOOQcN74eoeGOwHAdCRXk%3D",
            "SHORT_URL": "https://sprkc.cc/3PcLIe8",
            "STOCK_INFO": [
                "HDFC Bank Ltd"
            ],
            "SUB_AUTHORS": null,
            "TITLE": "Watts Up - Mukesh Saraf on the Domestic EV Battery ecosystem",
            "UPDATED_TIME": "2023-09-08",
            "URL_MASK": "47a2b510872d2e3f1d1da9796b21f007e8a80640611bb8e72ecb90fe8ef1d7ce"
        },
        {
            "CLIENT_ACCESS_KEY": "7rlvvb562ls6jkb3j1eod0qgef",
            "CREATED_DATE": "2023-09-01",
            "ID": 8,
            "IS_DELETED": 0,
            "LEAD_AUTHORS": [
                "Abhinesh Vijayaraj",
                "Arjun N"
            ],
            "ORIGINAL_URL": "https://spark-podcasts-dev.s3.ap-south-1.amazonaws.com/New Testing.m4a",
            "RELEASE_DATE": "2023-09-01",
            "SEARCHABLE_FLAG": 1,
            "SECTOR_INFO": [
                "Banks",
                "Oil & Gas"
            ],
            "SHARE_KEY": "nNKa67a9J2/5Mw4gKM81wJNe99HtUiDxB7%2B9/D6ysok%3D",
            "SHORT_URL": "https://sprkc.cc/3qOf3DB",
            "STOCK_INFO": [
                "Axis Bank Ltd",
                "Reliance Industries Ltd"
            ],
            "SUB_AUTHORS": null,
            "TITLE": "New Testing",
            "UPDATED_TIME": "2023-09-16",
            "URL_MASK": "0f229a51702c4115f64c4f57294d5d98bd8ecac60daa38ad01759d26cc8df262"
        }
    ]
}