import {Hub} from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

export async function getToken() {
    try {
        const session = await Auth.currentSession();
        return session.accessToken.jwtToken;
    } catch (e) {
        Hub.dispatch('auth', { event: 'signOut', data: null }, 'Auth');
        return false;
    }
}
