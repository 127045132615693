import axios from 'axios';
import {getToken} from "../../services/auth.service";
import {
    API_BASE_URL, DOWNLOAD_ACCESS_CONTROL_GET_USERS_ENDPOINT, DOWNLOAD_ACCESS_CONTROL_TOGGLE_ACCESS_ENDPOINT
} from "../../constants/strings";

const baseURL = API_BASE_URL;
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const axiosInstance = axios.create({
    baseURL,
    timeout: 300000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
    }
});

function getAuthToken() {
    return new Promise((res, rej) => {
        getToken()
            .then(session => res(session))
            .catch(err => rej (err));
    });
}

axiosInstance.interceptors.request.use(config => {
    return getAuthToken().then(token => {
        config.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': token
        }

        return Promise.resolve(config);
    });
}, err => {
    return Promise.reject(err);
});

export function getDownloadAccessControlUsers(searchValue, record_per_page, page) {
    return axiosInstance.get(`${DOWNLOAD_ACCESS_CONTROL_GET_USERS_ENDPOINT}`,
        {
            params: {
                search_value: searchValue,
                record_per_page: record_per_page,
                page: page,
            },
            cancelToken: source.token
        }
    );
}

export function handleAccessToggle(payload) {
    return axiosInstance.post(`${DOWNLOAD_ACCESS_CONTROL_TOGGLE_ACCESS_ENDPOINT}`, payload, { cancelToken: source.token});
}

export function cancelRequest() {
    source.cancel('User navigated away from the page.');
}
