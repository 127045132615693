import React, { Component, Fragment } from 'react';
import { Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { observer } from 'mobx-react';
import TagsInput from "../../components/TagsInput";
import ShareDialog from "../../components/ShareDialog";
import DatePicker from "react-datepicker";
import { documentUploadConfig, getStockInfoConfigObj } from "../../templates/PodcastUpload";
import Validate from "../../utils/FormValidation";
import common from "../../apis/common";
import { ENDPOINT_GET_SUGGESTIONS, ENDPOINT_PODCST_UPLOAD } from "../../constants/strings";
import { getSession } from "../../utils/AuthUtils";
import {
    DU_TOAST_ID,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from "../../constants/strings";
import AmplitudeAnalytics from "../../backends/amplitudeAnalytics";
import "./style.scss";
import axios from "axios";

const moment = require('moment');
const amplitudeAnalytics = new AmplitudeAnalytics();

axios.interceptors.response.use((response) => {
    return response;
}, error => {
    let err;

    if (error.hasOwnProperty("response")) {
        err = error.response;
    } else {
        err = error;
    }
    return Promise.reject(err);
});

class PodcastUpload extends Component {
    constructor(props) {
        super(props);
        this.shareDialogRef = React.createRef();
        this.store = {
            tagify: {
                fieldInputs: {
                    stock: null,
                    lead_authors: null,
                    sector: null,
                }
            }
        };
        this.stockInfoData = {};
        this.state = {
            closeAll: false,
            isLoading: false,
            fileInputKey: moment.now(),
            dateState: new Date(),
            URL: "",
            currentCount: 0,
            payload: {
                title: null,
                file: null,
                lead_authors: [],
                stock: [],
                sector: [],
                release_date: null,
            },
            errors: {
                file_invalid: null,
                title_invalid: null,
                report_type_invalid: null,
                lead_authors_invalid: null,
                sub_authors_invalid: null,
                release_date_invalid: null,
            },
        };
    }

    clearFieldStates = () => {
        Object.keys(this.store.tagify.fieldInputs).forEach((key) => {
            this.store.tagify.fieldInputs[key].removeAllTags();
            if (!["lead_authors"].includes(key)) {
                delete this.store.tagify.fieldInputs[key];
            }
        });

        this.setState({
            fileInputKey: moment.now(),
            currentCount: 0,
            dateState: new Date(),
            payload: {
                title: null,
                file: null,
                lead_authors: [],
                stock: [],
                sector: [],
                release_date: null,
            }
        });
    };

    clearErrorState = () => {
        this.setState({
            responseText: "",
            errors: {
                file_invalid: null,
                title_invalid: null,
                report_type_invalid: null,
                lead_authors_invalid: null,
                sub_authors_invalid: null,
                release_date_invalid: null,
            }
        });
    };

    generateSuggestionsPayload = (obj, value) => {
        let identifier = obj.identifier;
        if (["lead_authors"].includes(obj.identifier)) {
            identifier = 'authors';
        }
        return {
            suggestion_text: value ? value : "",
            suggestion_type: [identifier],
            page: this.props.page,
            tab: this.props.tab
        };
    };

    onUpload = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });
        this.clearErrorState();
        const error = Validate('documentUpload', this.state);

        if (Object.keys(error).length) {
            this.setState({
                errors: { ...this.state.errors, ...error },
                isLoading: false
            });
            this.props.toastify.fire("Please input valid file.", DU_TOAST_ID, TOAST_TYPE_ERROR);
            return;
        }

        let form_data = new FormData();
        form_data.append('file', this.state.payload.file, this.state.payload.file.name);
        if (this.state.payload.lead_authors)
            form_data.append('lead_authors', JSON.stringify(this.state.payload.lead_authors));
        if (this.state.payload.release_date)
            form_data.append('release_date', this.state.payload.release_date);
        if (this.state.payload.sector)
            form_data.append('sector', JSON.stringify(this.state.payload.sector));
        if (this.state.payload.stock)
            form_data.append('stock', JSON.stringify(this.state.payload.stock));
        if (this.state.payload.title)
            form_data.append('title', this.state.payload.title);

        let session = await getSession();
        if (!session) {
            this.props.toastify.fire("Something went wrong. Please try again later.", DU_TOAST_ID, TOAST_TYPE_ERROR);
            return;
        }
        let response;
        console.log("d", this.state.payload)
        try {
            response = await common.post(
                ENDPOINT_PODCST_UPLOAD,
                form_data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": session.accessToken.jwtToken
                    }
                }
            );
            if (response && response.status === 200 && response.data.status && response.data.link) {
                // this.shareDialogRef.current.setShareURL(response.data.link);
                // window.addEventListener('message', this.shareDialogRef.current.show());
                this.props.toastify.fire(response.data.message, DU_TOAST_ID, TOAST_TYPE_SUCCESS);
                this.setState({ isLoading: false });
                this.props.history.push('/podcastlist');
                // this.clearFieldStates();
            } else {
                throw response;
            }
        } catch (err) {
            let res = err.response ? err.response : {};
            if (res.hasOwnProperty('data') && res.data.hasOwnProperty("error_message")) {
                this.props.toastify.fire(res.data.error_message, DU_TOAST_ID, TOAST_TYPE_ERROR);
            } else {
                this.props.toastify.fire('Something went wrong', DU_TOAST_ID, TOAST_TYPE_ERROR);
            }
            this.setState({ isLoading: false });
        }
    };

    updateState = (operation, identifier, data) => {
        let prevState = { ...this.state.payload };
        if (["lead_authors"].includes(identifier)) {
            if (operation === "ADD") {
                prevState[identifier].push(data.value);
            }
            else if (operation === "REMOVE") {
                let index = prevState[identifier].indexOf(data.value);
                if (index > -1) {
                    prevState[identifier].splice(index, 1);
                }
            }
            this.setState({
                payload: { ...prevState }
            });
        } else if (identifier === "sector") {
            if (operation === "ADD") {
                prevState[identifier].push(data.value);
            }
            else if (operation === "REMOVE") {
                let index = prevState[identifier].indexOf(data.value);
                if (index > -1) {
                    prevState[identifier].splice(index, 1);
                }
            }
            this.setState({
                payload: { ...prevState }
            });
        }
        else { //stock
            if (operation === "ADD") {
                prevState[identifier]?.push(data.value);
                if (data.sector_name) {
                    this.store.tagify.fieldInputs[`sector`].addTags([
                        {
                            category: 'sector',
                            value: data.sector_name
                        }
                    ]);
                }
            }
            else if (operation === "REMOVE") {
                let index = prevState[identifier].indexOf(data.value);
                if (index > -1) {
                    prevState[identifier].splice(index, 1);
                }
                if (data.sector_name) {
                    this.store.tagify.fieldInputs[`sector`].removeTag(data.sector_name);
                }
            }
        }
    };

    onTagAdd = (e, identifier) => {
        this.updateState('ADD', identifier, e.detail.data);
    };

    onTagRemove = (e, identifier) => {
        this.updateState('REMOVE', identifier, e.detail.data);
    };

    transformTag = (tagData, identifier) => {
        if (this.handleDisableSuggestion(tagData.value, identifier)) {
            tagData.value = '';
        }
    };

    onInputChange = event => {
        if (event.target.name.includes('target_price') || event.target.name.includes('current_price')) {
            let indexNumber = event.target.name.split('_');
            indexNumber = parseInt(indexNumber[indexNumber.length - 1]);
            if (!this.stockInfoData.hasOwnProperty(indexNumber)) {
                this.stockInfoData[indexNumber] = {};
            }
            if (isNaN(event.target.value.trim())) {
                this.props.toastify.fire("Price needs to be a number", DU_TOAST_ID, TOAST_TYPE_ERROR);
                return;
            }
            this.stockInfoData[indexNumber][event.target.name] = parseInt(event.target.value.trim());
        } else {
            let prevState = { ...this.state.payload };
            prevState[event.target.name] = event.target.value;
            this.setState({
                payload: { ...prevState }
            });
        }
    };

    handleDateChange = (date) => {
        let payload = { ...this.state.payload };
        payload.release_date = moment(date).format('YYYY-MM-DD');
        this.setState({
            dateState: date,
            payload: { ...payload }
        });
    };

    handleUpload = (e) => {
        let payload = { ...this.state.payload };
        const file = e.target.files[0];

        if (file) {
            // Extract the file name without the extension
            const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
            payload.file = file;
            payload.title = fileNameWithoutExtension;
            this.setState({
                payload: { ...payload },
            });
        }
    };


    componentDidMount() {
        if (!this.props.auth.user ||
            !this.props.auth.user.signInUserSession.accessToken.hasOwnProperty("payload") ||
            !this.props.auth.user.signInUserSession.accessToken.payload.hasOwnProperty("cognito:groups") ||
            !this.props.auth.user.signInUserSession.accessToken.payload["cognito:groups"].length ||
            !this.props.auth.user.signInUserSession.accessToken.payload["cognito:groups"].includes("spark")
        ) {
            this.props.history.push("/document_search");
        }
    }

    handleDisableSuggestion = (value, category) => {
        let isDisabled = false;
        if (category === "sub_authors" && this.state.payload.lead_authors.includes(value)) {
            isDisabled = true;
        }
        return isDisabled;
    };

    getStockInfoStoreObj = (index) => {
        let store = {};
        store[`stock_${index}`] = null;
        store[`sector_${index}`] = null;
        store[`recommendation_${index}`] = null;
        return store;
    };

    generateFields = (obj, idx) => {
        switch (obj.type) {
            case "file":
                return (
                    <div className="file-container">
                        <input
                            type="file"
                            key={this.state.fileInputKey}
                            id="file"
                            accept=".mp3,.m4a"
                            onChange={this.handleUpload}
                            required />
                    </div>
                );

            case "tagify":
                return (
                    <Fragment>
                        <TagsInput
                            auth={this.props.auth}
                            store={this.store}
                            config={obj.config}
                            filterType="fieldInputs"
                            className='field-tags-input collapsed-input'
                            transformTag={this.transformTag.bind(this)}
                            on_add={this.onTagAdd}
                            on_remove={this.onTagRemove}
                            expandOnhover={true}
                            mode="input"
                            apiObj={common}
                            disableSuggestion={this.handleDisableSuggestion}
                            generatePayload={this.generateSuggestionsPayload}
                            suggestionsApiUrl={ENDPOINT_GET_SUGGESTIONS}
                        />
                        <div className="feedback-field"></div>
                    </Fragment>
                );
            case "text":
                return (
                    <FormGroup>
                        <Input
                            type="text"
                            name={obj.identifier}
                            onChange={this.onInputChange}
                            invalid={this.state.errors[obj.identifier + "_invalid"]}
                        />
                    </FormGroup>
                );

            case "date":
                return (
                    <div className="date-container">
                        <DatePicker
                            className="date-picker"
                            dateFormat="dd-MMM-yyyy"
                            selected={this.state.dateState}
                            onChange={this.handleDateChange}
                            popperPlacement="top"
                            dropdownMode="select"
                            placeholderText="Select a date"
                            showMonthDropdown
                            showYearDropdown
                        />
                    </div>
                );
        }
    };

    render() {
        return (
            <div className="document-upload">
                <div className="upload-card">
                    <div className="card-header">
                        <div className="card-title">
                            Podcast Upload
                        </div>
                    </div>
                    <div className="card-body">
                        <Form>
                            <div className="form-input-container">
                                {
                                    documentUploadConfig.map((obj, idx) => {
                                        return (
                                            <div className="form-field" key={idx}>
                                                <span className="field-label">{obj.fieldName}
                                                    {obj.mandatory && <b className="mandatory">*</b>}
                                                </span>
                                                {
                                                    obj.identifier === "title" ? (
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name={obj.identifier}
                                                                value={this.state.payload[obj.identifier]}
                                                                disabled={true}
                                                                onChange={this.onInputChange}
                                                                invalid={this.state.errors[obj.identifier + "_invalid"]}
                                                            />
                                                        </FormGroup>
                                                    ) : (
                                                        this.generateFields(obj)
                                                    )
                                                }
                                                <div className="form-feedback">{this.state.errors[obj.identifier + "_invalid"] && obj.mandatory ? "Invalid Input" : ''}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="required-legend"><b className="mandatory">*</b>(Required Fields)</div>
                            <div style={{ marginTop: 100, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button
                                    className="submit-button"
                                    size="sm"
                                    onClick={this.onUpload}
                                >{!this.state.isLoading ? "Submit and Upload" : <Spinner size="sm" color="white" />}
                                </Button>
                                <a
                                    style={{
                                        color: "black"
                                    }}
                                    href={`/podcastlist`}
                                    className="mt-2"
                                >
                                    Go Back to Podcast List
                                </a>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(PodcastUpload);
