import axios from 'axios';
import {getToken} from "../../services/auth.service";
import {
    API_BASE_URL,
    CREATE_USER_ENDPOINT,
} from "../../constants/strings";

const baseURL = API_BASE_URL;
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const axiosInstance = axios.create({
    baseURL,
    timeout: 300000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
    }
});

function getAuthToken() {
    return new Promise((res, rej) => {
        getToken()
            .then(session => res(session))
            .catch(err => rej (err));
    });
}

axiosInstance.interceptors.request.use(config => {
    return getAuthToken().then(token => {
        config.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': token
        }

        return Promise.resolve(config);
    });
}, err => {
    return Promise.reject(err);
});

export function createUser(payload) {
    return axiosInstance.post(`${CREATE_USER_ENDPOINT}`, payload, {cancelToken: source.token});
}

export function cancelRequest() {
    source.cancel('User navigated away from the page.');
}