import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Card, Col, Container, Input, Row, Spinner, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from "mobx-react";
import { getSession } from "../../utils/AuthUtils";
import common from "../../apis/common";
import { toast } from 'react-toastify';
import PaginationNew from "../../components/PaginationNew/index";
import { DU_TOAST_ID, TOAST_TYPE_ERROR } from "../../constants/strings";

const RoleList = (props) => {
    const [podcast, setPodcast] = useState();
    const [loading, setLoading] = useState();
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [pager, setPager] = useState({});

    const onInputChange = event => {
        setSearchText(event.target.value)
    };

    const onSearchReset = () => {
        setSearchText("")
        setPage(1)
        makeFetchAudioListCall()
    }

    useEffect(() => {
        makeFetchAudioListCall()
    }, [page, searchText])

    const makeFetchAudioListCall = async () => {
        setLoading(true);
        let session = await getSession();
        if (!session) {
            this.props.toastify.fire("Something went wrong. Please try again later.", DU_TOAST_ID, TOAST_TYPE_ERROR);
            return;
        }
        let response;
        try {
            let data = {
                "page": page,
                "page_size": 7,
                ...(searchText != "" && ({
                    search_query: searchText
                }))
            };
            if (data?.search_query && data?.search_query != "") {
                delete data.page;
                delete data.page_size;
            }
            data = JSON.stringify(data)
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `/admin_get_all_podcasts`,
                headers: {
                    'Authorization': session.accessToken.jwtToken,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            response = await common.request(config);
            if (response && response.data.status && response.data['data']['podcast_list']
                && response.data['data']['podcast_list'].length
            ) {
                setLoading(false);
                setPodcast(response.data.data.podcast_list || []);
                setPager(response.data.data.page_info || []);
            } else {
                setLoading(false);
                setPodcast([])
            }
        }
        catch (e) {
            setLoading(false);
            setPodcast([])
        }
    }
    const handlePodcastDelete = async (podcast_id) => {
        const confirm = window.confirm("Are you sure to Delete This Podcast?");
        if (confirm) {
            let session = await getSession();
            if (!session) {
                this.props.toastify.fire("Something went wrong. Please try again later.", DU_TOAST_ID, TOAST_TYPE_ERROR);
                return;
            }
            let data = '';
            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: `/delete_podcast?podcast_id=${podcast_id}`,
                headers: {
                    'Authorization': session.accessToken.jwtToken,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            await common.request(config).then((response) => {
                if (response?.data?.success_message) {
                    toast.success(response?.data?.success_message);
                    makeFetchAudioListCall()
                }
            })
                .catch((error) => {
                    console.log(error);
                });;
        }
    };
    return (
        <div className="document-upload-new">
            <Container className="mt-5 mx-5" fluid>
                <Row className="my-2">
                    <Col className="text-left">
                        <h3>Podcast List</h3>
                    </Col>
                    <Col className="text-right">
                        <Link
                            to={{
                                pathname: `/podcastupload`,
                                state: {
                                },
                            }}
                            className="btn btn-primary"
                        >
                            Upload New Podcast
                        </Link>
                    </Col>
                </Row>
                <Row className="my-2">
                    <Col className="text-left">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Input
                                type="text"
                                value={searchText}
                                placeholder="Search podcasts by name"
                                onChange={onInputChange}
                            />
                            <button
                                title="Reset"
                                className="btn btn-danger"
                                onClick={() => onSearchReset()}
                                style={{ marginLeft: '10px' }} // Adjust margin as needed
                            >
                                <span className="reset-text">Reset</span>
                            </button>
                        </div>
                    </Col>
                </Row>
                <Card className="">
                    <Table hover responsive borderless>
                        <thead className="thead-light">
                            <tr>
                                <th>ID</th>
                                <th>TITLE</th>
                                <th>RELEASE DATE</th>
                                <th>STATUS</th>
                                <th>VIEW</th>
                                <th className="text-right">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr className="text-center">
                                    <td colSpan="10">
                                        <Spinner animation="border" role="status" >{null}</Spinner>
                                    </td>
                                </tr>
                            ) : (
                                podcast && podcast?.map((role) => {
                                    return (
                                        <tr key={role?.ID}>
                                            <td> {role?.ID} </td>
                                            <td className="text-left"> {role?.TITLE} </td>
                                            <td className="text-center"> {role?.RELEASE_DATE} </td>
                                            <td className="text-center">
                                                <Badge
                                                    color=""
                                                    className={`badge badge-pill badge-${role?.IS_DELETED == 1
                                                        ? "danger"
                                                        : "success"
                                                        } mr-4`}
                                                >
                                                    {role?.IS_DELETED == 1 ? "DELETED" : "ACTIVE"}
                                                </Badge>
                                            </td>
                                            <td className="text-center">
                                                <a style={{
                                                    cursor: "pointer"
                                                }}
                                                    target="_blank"
                                                    href={role?.ORIGINAL_URL}
                                                >
                                                    <Badge
                                                        color=""
                                                        className={`badge badge-pill badge-primary mr-4`}
                                                    >
                                                        {"VIEW"}
                                                    </Badge>
                                                </a>
                                            </td>
                                            <td className="text-right">
                                                <Link
                                                    to={{
                                                        pathname: `/podcastedit/${role?.ID}`,
                                                        state: {
                                                            podcast: role,
                                                        },
                                                    }}
                                                    className=" btn btn-sm btn-primary"
                                                >
                                                    <FontAwesomeIcon icon={"edit"} />
                                                </Link>
                                                {
                                                    role?.IS_DELETED == 0 && (
                                                        <Button
                                                            onClick={() => handlePodcastDelete(role?.ID)}
                                                            color="danger"
                                                            className="btn-sm ml-2"
                                                        >
                                                            <FontAwesomeIcon icon={"trash"} />
                                                        </Button>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </Table>
                </Card>
                <Row className="my-2">
                    <Col className="text-left">
                    </Col>
                    <Col className="text-right">
                        <PaginationNew
                            currentPage={page}
                            totalPages={pager?.total_pages}
                            onPageChange={(newPage) => setPage(newPage)}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default observer(RoleList);

