import React, { Component } from 'react';
import { observer } from "mobx-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button, FormGroup, Input} from "reactstrap";
import './table-pagination.scss';

class TablePagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageNum: 1
        };

        this.inputBox = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.totalPages !== this.props.totalPages) {
            this.setState({pageNum: 1});
        }
    }

    onChange = e => {
        let { pageNum } = this.state;
        let value = e.target.value;
        const { totalPages } = this.props;

        if (value === '' || (!isNaN(value) && (value >= 1 && value <= totalPages)) ) {
            pageNum = value;
            this.setState({pageNum});
        }
    };

    onPageKeyPress = (e) => {
        let { pageNum } = this.state;
        pageNum = parseInt(pageNum, 10);

        const { totalPages, section, paginationEnterHandler } = this.props;

        if (e.key === 'Enter') {
            if (pageNum >= 1 && pageNum <= totalPages) {
                paginationEnterHandler(pageNum, section);
            } else {
                this.setState({pageNum})
            }
        }
    };

    onNavBtnClick = (e, section) => {
        let { pageNum } = this.state;
        const { totalPages, navBtnClickHandler } = this.props;

        pageNum = parseInt(pageNum, 10);

        switch(e.target.name) {
            case 'prev-btn':
                if (pageNum > 1) {
                    pageNum -= 1;
                    this.setState({pageNum});
                    navBtnClickHandler(pageNum, section);
                }
                break;
            case 'next-btn':
                if (pageNum < totalPages) {
                    pageNum += 1;
                    this.setState({pageNum});
                    navBtnClickHandler(pageNum, section);
                }
                break;
        }
    }

    render() {
        const { section, totalPages } = this.props;
        const { pageNum } = this.state;

        return (
            <div className="table-pagination">
                <div className="table-pagination__inner-container">
                    <Button
                        className="table-pagination__btn prev"
                        onClick={e => this.onNavBtnClick(e, section)}
                        color="primary"
                        disabled={pageNum === 1}
                        name="prev-btn"
                    >
                        <FontAwesomeIcon icon="caret-left"/>
                    </Button>
                    <div className="table-pagination__current-page-wrapper">
                        <FormGroup>
                            {/*<Label for="exampleEmail">Email</Label>*/}
                            <Input
                                className="table-pagination__current-page-input"
                                type="number"
                                value={pageNum}
                                onKeyPress={this.onPageKeyPress}
                                onChange={this.onChange}
                                ref={this.inputBox}
                            />
                        </FormGroup>
                        <span className="mx-1">/</span>
                        <span className="total-result">{totalPages}</span>
                    </div>
                    <Button
                        className="table-pagination__btn next"
                        name="next-btn"
                        onClick={e => this.onNavBtnClick(e, section)}
                        color="primary"
                        disabled={pageNum === totalPages}
                    >
                        <FontAwesomeIcon icon="caret-right"/>
                    </Button>
                </div>
            </div>
        )
    }
}

export default observer(TablePagination);
