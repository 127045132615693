import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import './style.scss';
import "react-datepicker/dist/react-datepicker.css";
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const moment = require('moment');

class DateRangeModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };
        this.handleChangeFrom = this.handleChangeFrom.bind(this);
        this.handleChangeTo = this.handleChangeTo.bind(this);
    }

    handleChangeFrom(date) {
        this.setState({
            startDate: date
        });
        let isDateRangeValid = !moment(this.state.endDate).isBefore(date);
        this.props.onDateChange(this.changeDateFormat(date), this.changeDateFormat(this.state.endDate), isDateRangeValid)
    }

    changeDateFormat(dateObj) {
        return moment(dateObj).format('YYYY-MM-DD')
    }

    handleChangeTo(date) {
        this.setState({
            endDate: date
        });
        let isDateRangeValid = !moment(date).isBefore(this.state.startDate);
        this.props.onDateChange(this.changeDateFormat(this.state.startDate), this.changeDateFormat(date), isDateRangeValid)
    }

    render() {
        return (
            <div className="date-pickers-container">
                <div className="start-date-container ds-date-container">
                    <DatePicker
                        className="start-date-picker ds-date-picker"
                        dateFormat={this.props.dateFormat}
                        selected={this.state.startDate}
                        minDate={this.props.startDateMin}
                        maxDate={this.props.startDateMax}
                        onChange={this.handleChangeFrom}
                        popperPlacement="bottom"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                    />
                    <FontAwesomeIcon icon="calendar" className="calendar-icon" />
                </div>
                <span className="mid-label">To</span>
                <div className="end-date-container ds-date-container">
                    <DatePicker
                        className="end-date-picker ds-date-picker sk_date_picker"
                        dateFormat={this.props.dateFormat}
                        selected={this.state.endDate}
                        minDate={this.props.endDateMin}
                        maxDate={this.props.endDateMax}
                        onChange={this.handleChangeTo}
                        popperPlacement="bottom"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                    />
                    <FontAwesomeIcon icon="calendar" className="calendar-icon" />
                </div>
            </div>
        );
    }
}

export default observer(DateRangeModule);
