export const ERRORS = {
  MSG_STH_WENT_WRONG: "Something went wrong. Please try again one more time.",
  MSG_UNKNOWN_USER:
    "Sorry, our system does not recognize you. Please contact your account manager.",
  MSG_RESEND_MAIL_ERROR:
    "Sorry, our system does not recognize you. Please try signing up with us again to generate a new password or contact your account manager.",
  MSG_RESEND_MAIL_SUCCESS:
    "Please generate a new password using the link sent to your E-mail.",
  MSG_SERVER_DOWN:
    "Our servers seems to be down at the moment. Please try again after sometime.",
};
