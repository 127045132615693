import React, {Fragment} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './style.scss';
import {TOAST_TYPE_ERROR, TOAST_TYPE_INFO, TOAST_TYPE_SUCCESS, TOAST_TYPE_WARNING} from "../../constants/strings";

class SparkToast extends React.Component {
    constructor(props) {
        super(props);
    }

    ghostFunction() {
        return <FontAwesomeIcon icon={['far', 'check-circle']} />;
    }

    makeToastIconJSX() {
        switch(this.props.toastType) {
            case TOAST_TYPE_SUCCESS:
                return (
                    <FontAwesomeIcon icon="check-circle"/>
                );
            case TOAST_TYPE_ERROR:
                return (
                    <FontAwesomeIcon icon="times-circle"/>
                );
            case TOAST_TYPE_WARNING:
                return (
                    <FontAwesomeIcon icon="info-circle"/>
                );
            case TOAST_TYPE_INFO:
                return (
                    <FontAwesomeIcon icon="info-circle"/>
                );
        }
    }

    render() {
        let toastIcon = this.makeToastIconJSX();

        return (
            <Fragment>
                <div className="toast-icon-wrapper">
                    {toastIcon}
                </div>
                <div className="toast-text-container">
                    <div className="toast-type">
                        {this.props.toastType}
                    </div>
                    <div className="toast-message">
                        {this.props.msg}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SparkToast;
