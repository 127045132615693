import axios from 'axios';
import {getToken} from "../../services/auth.service";
import {
    ACCESS_CONTROL_SIGNUP_APPROVAL_ENDPOINT, ACCESS_CONTROL_SIGNUP_STATUS_AUDITED,
    ACCESS_CONTROL_SIGNUP_STATUS_PENDING,
    API_BASE_URL
} from "../../constants/strings";
import {getCurrentDate} from "../../utils/DateTime";

// const baseURL = 'https://run.mocky.io/v3';
const baseURL = API_BASE_URL;
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const axiosInstance = axios.create({
    baseURL,
    timeout: 300000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
    }
});

function getAuthToken() {
    return new Promise((res, rej) => {
        getToken()
            .then(session => res(session))
            .catch(err => rej (err));
    });
}

axiosInstance.interceptors.request.use(config => {
    return getAuthToken().then(token => {
        config.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': token
        }

        return Promise.resolve(config);
    });
}, err => {
    return Promise.reject(err);
});

export function getUnauditedUsers(filters, pagination) {
    return axiosInstance.get(`${ACCESS_CONTROL_SIGNUP_APPROVAL_ENDPOINT}`,
            {
                params: {
                    q_string: filters.searchBy,
                    signup_status_filter: ACCESS_CONTROL_SIGNUP_STATUS_PENDING,
                    date_filter_lower: filters.dateMin,
                    date_filter_upper: filters.dateMax,
                    page: pagination.pagedBy,
                    records_per_page: pagination.recordsPerPage
                },
                cancelToken: source.token
            }
        );
    // return axiosInstance.get(`/c005d328-04ff-4ae9-9b32-a49efa70aa8c`, { params: {q_string, page}, cancelToken: source.token});
}

export function getAuditedUsers(filters, pagination) {
    return axiosInstance.get(`${ACCESS_CONTROL_SIGNUP_APPROVAL_ENDPOINT}`,
        {
            params: {
                q_string: filters.searchBy,
                signup_status_filter: ACCESS_CONTROL_SIGNUP_STATUS_AUDITED,
                date_filter_lower: filters.dateMin,
                date_filter_upper: filters.dateMax,
                page: pagination.pagedBy,
                records_per_page: pagination.recordsPerPage
            },
            cancelToken: source.token
        }
    );
    // return axiosInstance.get(`/0cf7a87e-85cc-4b20-8d3f-d3ebbe4ddbc8`, { params: {q_string, page}, cancelToken: source.token});
}

export function updateUserStatus(email, signup_status, sectors, restriction, flag) {
    return axiosInstance.post(`${ACCESS_CONTROL_SIGNUP_APPROVAL_ENDPOINT}`,
        {
            signup_status,
            email,
            sectors,
            restriction,
            flag
        },
        {
            cancelToken: source.token
        }
    );
    // return axiosInstance.get(`/6d456f37-1ac3-4db9-8dac-184a64ea7000`, { params: {email, status}, cancelToken: source.token});
}

export function cancelRequest() {
    source.cancel('User navigated away from the page.');
}
