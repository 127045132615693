import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, FormGroup, Spinner, Input, FormFeedback } from 'reactstrap';
import Validate from "../../utils/FormValidation";
import Auth from '@aws-amplify/auth';
import ResponseModal from '../../components/ResponseModal';
import { Link } from 'react-router-dom';
import axios from "axios";

class RequestVerificationCode extends Component {
	state = {
		email: '',
		isLoading: false,
		isModalOpen: false,
		resendLoader: false,
		modal: {
			status: "",
			title: "",
			text: "",
			buttonText: "",
			functionalBtn: {}
		},
		errors: {
			emailInvalid: false,
			cognito: null,
		}
	};

	clearErrorState = () => {
		this.setState({
			responseText: "",
			errors: {
				cognito: null,
				emailInvalid: false,
			}
		});
	};

	forgotPasswordHandler = async event => {
		event.preventDefault();
		this.setState({ isLoading: true });
		// Form validation
		this.clearErrorState();
		const errors = Validate('requestVerificationCode', this.state);
		if (Object.keys(errors).length) {
			this.setState({
				errors: { ...errors },
				isLoading: false
			});
			return;
		}
		let self = this;
		let message, buttonText, functionalBtn;
		let status = 'warning';
		let fromSpark = '';
		// AWS Cognito integration here
		try {
			await Auth.forgotPassword(this.state.email.toLowerCase());
			this.setState({ isLoading: false });
			this.props.setEmail(this.state.email.toLowerCase());
			setTimeout(function () {
				self.props.changeScreen('forgotPasswordVerification')
			}, 2500);
			this.showPopUpMessageModal(
				'success',
				'One more step',
				"We have sent a verification code to your E-mail ID. Please copy it to the next screen along with your new password to complete the process!",
				"Okay"
			);
		} catch (error) {
			if (error.code === "NotAuthorizedException") {
				if (error.message === "User is disabled") {
					message = "Restricted access. Please contact your account manager."
				} else {
					message = "Looks like you've not completed the sign up process yet. Click here to generate a fresh new password for your account.";
					buttonText = "";
					functionalBtn = {
						onClickHandler: () =>{ this.handleResendMail() },
						text: "Resend link",
						className: "resend-mail-btn",
					}
				}
			} else if (error.code === "UserNotFoundException") {
				message = "We couldn’t verify your account. Please try signing up with us or contact your sales representative."
				buttonText = "";
				functionalBtn = {
					onClickHandler: () =>{ this.props.history.push('/signup') },
					text: "Create a New Account",
					className: "main-button"
				}
				status = 'warning';
				fromSpark = 'Message from Avendus Spark'
			} else if (error.code === "CodeDeliveryFailureException") {
				message = "We are sorry, but we couldn't send the verification code to your E-mail. Please check the email you've entered and try again.";
				buttonText = "OKAY";
			} else if (error.code === "InternalErrorException") {
				message = "Our servers are down for the moment. Please try again after sometime.";
				buttonText = "TRY AGAIN";
			} else {
				message = "Something went wrong. Please try again later.";
				buttonText = "OKAY";
			}
			// TODO: add an if statement here for error code wise response handling
			this.showPopUpMessageModal(
				status,
				fromSpark ? fromSpark : 'Oops!',
				message,
				buttonText,
				functionalBtn
			);
			this.setState({ isLoading: false });
			console.log(error);
		}
	};

	handleResendMail = async () => {
        this.setState({ resendLoader: true });

        const { email } = this.state;

        let status = false;

        try {
            const params = {
                email: email.toLowerCase(),
            };

            let url = process.env.REACT_APP_LIB_DOMAIN + '/resend_email';
            let resendMailResponse = await axios.post(url, params);

            if (resendMailResponse &&
                resendMailResponse.hasOwnProperty('status') && 
                resendMailResponse.status === 200 && 
                resendMailResponse.data && 
                resendMailResponse.data.status
            ) {
                status = true;
            }
        } catch (error) {
            // TODO: add if condition for error response
            console.log(error);
        }

        if (status) {
            this.showPopUpMessageModal(
                'success',
                'Mail sent!',
                "Please generate a new password using the link sent to your E-mail.",
                "OKAY"
            );
        } else {
            this.showPopUpMessageModal(
                'error',
                'Oops!',
                "Sorry, our system does not recognize you. Please try signing up with us again to generate a new password or contact your account manager.",
                "TRY AGAIN"
            );
        }
        this.setState({ resendLoader: false });
    };

	showPopUpMessageModal = (status, modalTitle, modalText, buttonText, functionalBtn) => {
		this.setState({
			isModalOpen: true,
			modal: {
				status: status,
				title: modalTitle,
				text: modalText,
				buttonText: buttonText,
				functionalBtn: functionalBtn
			}
		});
	};

	onInputChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	onActionButtonClick = () => {
		this.setState({
			isModalOpen: false
		});
	}
	onKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.forgotPasswordHandler(e);
		}
	}


	render() {
		return (
			<Fragment>
				<Form>
					<FormGroup>
						<div className="label-container">
							<span className="field-label">E-mail</span>
						</div>
						<Input
							type="text"
							name="email"
							placeholder="example@abc.com"
							onChange={this.onInputChange}
							onKeyPress={this.onKeyPress}
							// valid={!this.state.errors.emailInvalid}
							invalid={this.state.errors.emailInvalid}
						/>
						<FormFeedback>
							{this.state.errors.emailInvalid ? "Please Enter a valid Email" : ''}
						</FormFeedback>
					</FormGroup>
					<div
						className="response-text">
						{this.state.errors.cognito ? this.state.errors.cognito.message : ''}
					</div>
					<Button
						style={{
							backgroundColor: "#000",
							padding: "0.6rem",
							width: "70%",
							borderRadius: "2rem",
							marginTop: "20px",
						}}
						onClick={this.forgotPasswordHandler}
					>
						{!this.state.isLoading ? "SUBMIT" : ""}
						{this.state.isLoading && <Spinner size="sm" color="white" />}
					</Button>
					<div
						style={{ margin: "-5px 0 -2px 0" }}>
						<Link className="back-to-login-link" to="/login"> Go back to Login </Link>
					</div>
				</Form>
				{
					this.state.isModalOpen &&
					<ResponseModal
						modal={this.state.isModalOpen}
						backdrop={true}
						modalClass="response-modal"
						titleClass={"title " + this.state.modal.status}
						modalTitle={this.state.modal.title}
						textClass="text"
						modalText={this.state.modal.text}
						buttonClass={"action-button " + this.state.modal.status}
						buttonText={this.state.modal.buttonText}
						onClickAction={this.onActionButtonClick}
						functionalBtn={this.state.modal.functionalBtn}
						isBtnLoading={this.state.resendLoader}
						type={this.state.modal.status}
					/>
				}
			</Fragment>
		);
	}
}

export default observer(RequestVerificationCode);
