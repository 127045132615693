import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PaginationNew = ({ currentPage, totalPages, onPageChange }) => {
    const onPrevButtonClick = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const onNextButtonClick = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const onPageInput = (e) => {
        const newPage = parseInt(e.target.value, 10);
        if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
            onPageChange(newPage);
        }
    };

    const onPageKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onPageInput(e);
        }
    };

    return (
        <div className="pagination">
            <button className="prev" onClick={onPrevButtonClick}>
                <FontAwesomeIcon icon="caret-left" />
            </button>
            <div className="current-page">
                <span className="label">Page: </span>
                <input
                    className="current-page-input"
                    type="text"
                    value={currentPage}
                    onChange={onPageInput}
                    onKeyPress={onPageKeyPress}
                />
                <span className="total-result">/{totalPages}</span>
            </div>
            <button className="next" onClick={onNextButtonClick}>
                <FontAwesomeIcon icon="caret-right" />
            </button>
        </div>
    );
};

export default PaginationNew;
