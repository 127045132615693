import React, { useState } from "react";
import "./style.scss";

function ToggleSwitch(props) {
    const [isToggled, setIsToggled] = useState(props.checked);
    const onToggle = () => {
        setIsToggled(!isToggled)
        props.switchState(!isToggled)
    };
    return (
        <label className="spark-toggle-switch">
            <input
                disabled={props?.disabled}
                type="checkbox"
                checked={isToggled}
                onChange={onToggle} />
            <span className="switch" />
        </label>
    );
}
export default ToggleSwitch;