//This is an extension of Bootstrap 4 card from the library 'reactstrap'

import React from 'react';
import './style.scss';

class SparkCard extends React.Component {
    constructor(props) {
        super(props);
    }

    /**
     * @desc Returns JSX code for the Header section
     * from props based on visibility flag
     *
     * @returns {JSX} - Card header
     */
    getCardHeaderJSX() {
        return (this.props.config.header.show) ? this.props.config.header.content : null;
    }

    /**
     * @desc Returns JSX code for the Footer section
     * from props based on visibility flag
     *
     * @returns {JSX} - Card footer
     */
    getCardFooterJSX() {
        return (this.props.config.footer.show) ? this.props.config.footer.content : null;
    }

    render() {
        return (
            <div className="spark-card">
                {/*Header content is configurable*/}
                {this.getCardHeaderJSX()}

                {/*Body content will always be visible*/}
                <div className="card-body">
                    {this.props.config.body.content}
                </div>

                {/*Footer content is configurable*/}
                {this.getCardFooterJSX()}
            </div>
        );
    }
}

export default SparkCard;
