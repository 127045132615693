import React, { useEffect, useState, useRef } from "react";
import ShareDialog from "../ShareDialog";
import PDFViewer from "../PDFViewer";
import PDFJSBackend from "../../backends/pdfjs";
import documentSearch from "../../apis/documentSearch";
import { DS_SHARE_URL_PREFIX } from "../../constants/strings";
import { getSession } from "../../utils/AuthUtils";
import axios from "axios";

const PDFViewerNewTab = ({ amplitudeAnalytics, store, auth, toastify }) => {
  const [closeAll /* setCloseAll */] = useState(false);
  // const [shortUrl, setShortUrl] = useState(null);
  const [docId, setDocId] = useState(null);
  const [userHasDownloadAccess, setUserHasDownloadAccess] = useState(true);
  const shareDialogRef = useRef();
  const pdfViewerRef = useRef();
  const backend = new PDFJSBackend();

  useEffect(() => {
    window.addEventListener("message", handleIframeTask);
    window.addEventListener("popstate", handlePopState);
    window.onbeforeunload = handleBeforeUnload;
    pdfViewerRef.current.trigger();
    init();
    return () => {
      store.setSelectedDocumentIsOpen(false);
    };
  }, []);

  const handlePopState = (e) => {
    store.resetAllDocumentData();
  };

  const handleBeforeUnload = () => {
    store.resetAllDocumentData();
  };

  const init = () => {
    let url = window.location.href;
    let docId = url.split("/")[4];
    setDocId(docId);
  };

  const handleIframeTask = (e) => {
    if (e.data && typeof e.data === "string") {
      switch (e.data.toLowerCase()) {
        case "show share dialog":
          amplitudeAnalytics.documentAction({ documentId: docId }, "Shared");
          getShortURL();
          break;
        case "download single file":
          store.setTriggerForClientDownload(true);
          pdfViewerRef.current.trigger();
          break;
        case "close viewer":
          store.resetAllDocumentData();
          let url = window.location.href.split("/");
          let baseURL = `${url[0]}//${url[2]}/document_search`;
          window.location.href = baseURL;
          // window.close();
          break;
        case "check download permission":
          checkUserDownloadPermission();
          break;
        default:
          break;
      }
    }
  };

  //   const toggleShareDialog = () => {
  //     console.log("toggleShareDialog", shortUrl);
  //     let shareURL = shortUrl ? shortUrl : `${DS_SHARE_URL_PREFIX}${docId}`;
  //     shareDialogRef.current.setShareURL(shareURL);
  //     return shareDialogRef.current && shareDialogRef.current.show();
  //   };

  const getShortURL = async () => {
    let url = window.location.href;
    let docId = url.split("/")[4];

    const payload = { document_id: docId };
    console.log("payload", payload);
    let session = await getSession();
    let config = { headers: { Authorization: session.accessToken.jwtToken } };
    makeApiCall(
      `${process.env.REACT_APP_LIB_DOMAIN}/fetch_short_url`,
      payload,
      config
    )
      .then(getShortURLSuccess)
      .catch(getShortURLFailure);
  };

  const getShortURLSuccess = (res) => {
    let url = window.location.href.split("/");
    let docId = url[4];
    let baseURL = `${url[0]}//${url[2]}`;
    if (res.status === 200) {
      //setShortUrl(res.data.data.short_url);
      //toggleShareDialog();
      shareDialogRef.current.setShareURL(res.data.data.short_url);
    } else {
      //setShortUrl(null);
      //toggleShareDialog();
      shareDialogRef.current.setShareURL(
        `${baseURL}/document_search?id=${docId}`
      );
    }
    return shareDialogRef.current && shareDialogRef.current.show();
  };

  const getShortURLFailure = (err) => {
    let url = window.location.href.split("/");
    let docId = url[4];
    let baseURL = `${url[0]}//${url[2]}`;
    //setShortUrl(null);
    //toggleShareDialog();
    shareDialogRef.current.setShareURL(
      `${baseURL}/document_search?id=${docId}`
    );
    return shareDialogRef.current && shareDialogRef.current.show();
  };

  const sendMessageToPDFViewer = () => {
    let iframe = document.getElementById("pdfjsviewer-iframe");
    if (!iframe) return;
    let iWindow = iframe.contentWindow;
    let payload = {
      hide: !userHasDownloadAccess,
    };
    iWindow.postMessage(payload, "*");
  };

  const checkUserDownloadPermission = async () => {
    let session = await getSession();
    const payload = { email: session.idToken.payload.email };
    let config = { headers: { Authorization: session.accessToken.jwtToken } };
    makeApiCall(
      `${process.env.REACT_APP_LIB_DOMAIN}/user_settings/download/get_download_access_status`,
      payload,
      config
    )
      .then(getUserPermissionStatusSuccess)
      .catch(getUserPermissionStatusFailure);
  };

  const getUserPermissionStatusSuccess = (res) => {
    if (res.status === 200) {
      setUserHasDownloadAccess(res.data.data.has_access);
      sendMessageToPDFViewer();
    } else {
      setUserHasDownloadAccess(true);
      sendMessageToPDFViewer();
    }
  };

  const getUserPermissionStatusFailure = (err) => {
    console.log(err);
    setUserHasDownloadAccess(true);
    sendMessageToPDFViewer();
  };

  const makeApiCall = (url, payload, config) => {
    return axios.post(url, payload, config);
  };

  return (
    <>
      <PDFViewer
        amplitudeAnalytics={amplitudeAnalytics}
        store={store}
        auth={auth}
        toastify={toastify}
        ref={pdfViewerRef}
        backend={backend}
        api={documentSearch}
        doc_id={docId}
      />
      <ShareDialog
        ref={shareDialogRef}
        toastify={toastify}
        closeAll={closeAll}
        title="SHARE"
        backdrop={true}
        showLabel={true}
      />
    </>
  );
};

export default PDFViewerNewTab;
