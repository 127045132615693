import React, { Component, Fragment } from "react";
import './style.scss';
import { PODCAST_SHARE_URL_PREFIX } from "../../constants/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";


export default class PodcastItem extends Component {
    constructor(props) {
        super(props);
        this.player = null;
    }

    state = {
        streamLink: "",
        isLoading: false
    }

    componentDidMount() {
    }

    handleSharePodcast = (e) => {
        e.stopPropagation();
        let shareURL = `${PODCAST_SHARE_URL_PREFIX}?id=${this.props.audioObj.SHARE_KEY}`;
        this.props.onSharePodcast(shareURL);
    }

    handlePodcastSelect = () => {
        this.props.onPodcastSelect(this.props.audioObj);
    }

    render() {
        return (
            <li className={`audio-list-item ${this.props.currentlyPlaying ? "active" : ""}`}
                onClick={this.handlePodcastSelect}>
                <div className="audio-identifiers">
                    <div className={`audio-icon ${this.props.currentlyPlaying ? "active" : ""}`}>
                        {
                            this.props.currentlyPlaying && this.props.isPlaying ?
                                <FontAwesomeIcon icon="volume-up" />
                                :
                                <FontAwesomeIcon icon={['far', 'play-circle']} />
                        }
                    </div>
                    <div className={`audio-icon ${this.props.currentlyPlaying ? "active" : ""}`}>
                        {this.props.getFileName(this.props.audioObj)}
                    </div>
                </div>
                <div className="player-meta">
                    <div className={`audio-modified-date ${this.props.currentlyPlaying ? "active" : ""}`}>
                        {moment(this.props.audioObj.RELEASE_DATE, "YYYY-MM-DD").format("DD-MMM-YYYY")}
                    </div>
                    <button className={`share-btn ${this.props.currentlyPlaying ? "active" : ""}`}
                        onClick={this.handleSharePodcast} title="Share">
                        <FontAwesomeIcon icon="share-alt" />
                    </button>
                </div>
            </li>
        )
    }
}
