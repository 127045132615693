import React, { Component, Fragment } from 'react';
import './style.scss';
import { Link } from "react-router-dom";

class Footer extends Component {

    render() {
        return (
            <div className="site-footer">
                <Link className="disclaimer terms-and-conditions" to="/terms_and_conditions">
                    Terms and Conditions
                </Link>
            </div>
        );
    }
}

export default Footer;
