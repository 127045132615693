import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Cookies from 'js-cookie';

export async function getSession() {
    try {
        return await Auth.currentSession();
    } catch (e) {
        Hub.dispatch('auth', { event: 'signOut', data: null }, 'Auth');
        return false;
    }
}
export async function checkLogOutSession() {
    try {
        await Auth.currentUserInfo()
            .then((res) => {
                if (Object.keys(res)?.length <= 0) {
                    localStorage.clear();
                    window.location.href = '/login';
                }
            })
            .catch((err) => console.log("erss", err))
        return true;
    } catch (e) {
        return false;
    }
}

// Function to delete a cookie by name
export async function deleteCookie(name) {
    try {
        const cookieExists = Cookies.get(name);
        if (cookieExists) {
            Cookies.remove(name);
            console.log('Cookie removed successfully');
        } else {
            console.log('Cookie not found');
        }
    } catch (error) {
        console.error('Error removing cookie:', error);
    }
}
export function checkUserInCognitoGroup(user, groupName) {
    return user &&
        user.signInUserSession.accessToken.hasOwnProperty("payload") &&
        user.signInUserSession.accessToken.payload.hasOwnProperty("cognito:groups") &&
        user.signInUserSession.accessToken.payload["cognito:groups"].length &&
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(groupName);
}
