/**
 * @class
 * @classdesc This class serves as a utility to inject Mozilla's PDFJS viewer
 * into a target targetContainer
 */
export default class PDFJSBackend {
    /**
     * @desc Creates an iframe on the fly, appends the file URI to its source URI
     * and finally appends that iframe into the target targetContainer
     *
     * @param {string} source - URI of PDF file
     * @param {object} targetContainer - Target targetContainer where to append the iframe
     */
    init(source, targetContainer) {
        try {
            const iframe = document.createElement('iframe');
            iframe.setAttribute("id", "pdfjsviewer-iframe");
            // iframe.src = source;
            iframe.width = '100%';
            iframe.height = '100%';
            targetContainer.innerHTML = "";
            targetContainer.appendChild(iframe);
            const injectedIframe = document.getElementById('pdfjsviewer-iframe');
            injectedIframe.src = source;
        } catch(e) {
            console.error(e);
        }
    }
}
