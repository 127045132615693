class PodcastSearchModuleStore {
    advSearchPanelState = 'hidden';
    syncingAdvSearch = false;
    syncingMainSearch = false;
    toggleAdvSearchPanel = () => {
        if (this.advSearchPanelState === 'hidden') {
            this.advSearchPanelState = 'visible';
        } else {
            this.advSearchPanelState = 'hidden'
        }
    };
    hideAdvSearchPanel = () => {
        this.advSearchPanelState = 'hidden';
    }

}

export default PodcastSearchModuleStore;
