import React, { Component, Fragment } from "react";
import './style.scss';
import ReactHtmlParser from "react-html-parser";
import { getSession } from "../../utils/AuthUtils";
import common from "../../apis/common";
import { Spinner } from 'reactstrap';
import { ENDPOINT_TERMS_CONDITIONS } from "../../constants/strings";

class TermsConditions extends Component {
    state = {
        termsConditions: "",
        title: "",
        isLoading: true
    }

    componentDidMount(){
        this.makeFetchTermsConditionsCall();
    }

    async makeFetchTermsConditionsCall(){
        let session = await getSession();
        let response;
        try{
            response = await common.get(ENDPOINT_TERMS_CONDITIONS,
                { 
                    params: {},
                    headers: { "Authorization": session.accessToken.jwtToken } 
                }
            );
            if( response &&
                response.data.status && 
                response.data.data.hasOwnProperty("content") && 
                response.data.data.hasOwnProperty("title")
            ){
                this.setState({ termsConditions: response.data.data.content, title: response.data.data.title, isLoading: false });
            }else{
                this.setState({ termsConditions: '', title: '', isLoading: false });
            }
        }
        catch(e){
            console.error(e);
        }
    }

    render() {
        return (
            <div className="terms-conditions-main-container">
                {
                    this.state.isLoading 
                    ?
                        <div className="loader-container">
                            <Spinner type="grow" size="lg" color="info" />
                            <Spinner type="grow" size="lg" color="info" />
                            <Spinner type="grow" size="lg" color="info" />
                        </div>
                    :(
                    this.state.termsConditions 
                    ?
                        <div className="terms-conditions-container">
                            <div className="header-title">
                                {this.state.title ? this.state.title : "Terms and Conditions"}
                            </div>
                            <div className="terms-conditions-content">
                                {ReactHtmlParser(this.state.termsConditions)}
                            </div>
                        </div>
                    :
                    <div className="no-data-content">No Data Available</div>
                    )
                }
            </div>
        )
    }
}

export default TermsConditions;