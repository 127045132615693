//Document Search
export const DS_API_BASE_URL = `${process.env.REACT_APP_LIB_DOMAIN}/document_search`;
export const API_BASE_URL = `${process.env.REACT_APP_LIB_DOMAIN}`;
export const DS_ENDPOINT_FETCH_REPORTS = '/fetch_reports';
export const LOGGING_ENDPOINT_AMPLITUDE = '/amplitude_logs';
export const DS_ENDPOINT_DOWNLOAD_REPORT_NWM = '/report';
export const DS_ENDPOINT_DOWNLOAD_REPORT = '/download_file';
export const ENDPOINT_PODCASTS = '/podcasts';
export const ENDPOINT_PODCASTS_DATABASE = '/all_podcasts';
export const ENDPOINT_PODCASTS_SEARCH = '/podcasts/search';
export const ENDPOINT_PODCASTS_GET_URL = '/podcasts/get_url';
export const PODCAST_SHARE_URL_PREFIX = `${process.env.MAIN_HOST}/podcasts`;
export const ENDPOINT_TERMS_CONDITIONS = '/terms_conditions';
export const ENDPOINT_GET_SUGGESTIONS = '/get_suggestions';
export const ENDPOINT_GET_PODCAST_SUGGESTIONS = '/get_podcast_suggestions';
export const DU_ENDPOINT_FILE_UPLOAD = '/file_upload';
export const ENDPOINT_PODCST_UPLOAD = '/podcast_upload';
export const ENDPOINT_PODCST_EDIT = '/edit_podcast';
export const ENDPOINT_BANNER = '/banner_content';
export const ACCESS_CONTROL_SIGNUP_APPROVAL_ENDPOINT = '/signup_approval';
export const CREATE_USER_ENDPOINT = '/createuser';
export const WATERMARK_FILE_UPLOAD_ENDPOINT = 'watermark/generate_watermark';
export const DOWNLOAD_ACCESS_CONTROL_GET_USERS_ENDPOINT = '/user_settings/download/fetch_users'
export const DOWNLOAD_ACCESS_CONTROL_TOGGLE_ACCESS_ENDPOINT = '/user_settings/download/toggle_download'


export const DS_SHARE_URL_PREFIX = `${process.env.MAIN_HOST}/documentsearch?id=`;
export const DS_TOAST_ID = 'Document Search Toast';
export const AC_TOAST_ID = 'Access Control Toast';
export const WM_TOAST_ID = 'Watermark Toast';
export const DAC_TOAST_ID = 'Download Access Control Toast';
export const DS_FILTER_SORT_DATE = 'release_date';
export const DS_FILTER_SNIPPETS = 'highlighted_content'; //Key name for snippets in API response
export const DS_FILTER_SORT_RELEVANCE = '_score';
export const DS_RESULTS_TITLE_MAXLEN = 88; //Max length for document title in results viewer
export const DU_TOAST_ID = 'Document Uploader Toast';
export const CC_TOAST_ID = 'Create credential Toast';
export const MSG_STH_WENT_WRONG_DS = 'Something went wrong while processing your request, please try again.';
export const MSG_STH_WENT_WRONG_DOC_DOWNLOAD = 'Something went wrong while loading the document, please try again.';
export const MSG_DOC_DOWNLOAD_ERROR_NO_URL = 'Cannot download. Document URL not found, please try again.';
export const MSG_DOC_DOWNLOAD_ERROR_INVALID_URL = 'Cannot download. Document URL is invalid, please try again.';
export const MSG_STH_WENT_WRONG_SNIPPETS = 'Something went wrong while loading highlights, please try again.';
export const MSG_NO_SNIPPETS = 'NO HIGHLIGHTS AVAILABLE FOR THIS DOCUMENT';
export const MSG_SNIPPETS_LOAD_ERROR = 'ERROR LOADING PREVIEW';
export const MSG_PREVIEW_PLACEHOLDER = 'CLICK ON A DOCUMENT FROM LEFT PANEL TO PREVIEW';
export const TOAST_TYPE_SUCCESS = 'Success';
export const TOAST_TYPE_ERROR = 'Error';
export const TOAST_TYPE_WARNING = 'Warning';
export const TOAST_TYPE_INFO = 'Info';

//Global
export const TITLE_STH_WENT_WRONG = 'Something went wrong.';
export const DATE_FORMAT = 'DD-MMM-YYYY';
export const MSG_COPIED_CLIPBOARD = 'Copied to clipboard.';
export const MSG_NOT_COPIED_CLIPBOARD = 'Could not copy to clipboard.';

//Route names
export const ROUTE_NAME_DOCUMENT_SEARCH = 'documentSearch';
export const ROUTE_NAME_DOCUMENT_UPLOAD = 'documentUpload';
export const ROUTE_NAME_PODCAST_LIST = 'podcastlist';
export const ROUTE_NAME_PODCAST_EDIT = 'podcastedit';
export const ROUTE_NAME_PODCAST_UPLOAD = 'podcastupload';

export const ROUTE_NAME_ACCESS_CONTROL = 'accesscontrol';
export const ROUTE_NAME_PODCASTS = 'podcasts';
export const ROUTE_NAME_PODCASTS_SHARE = 'podcastShare';
export const ROUTE_NAME_TERMS_CONDITIONS = 'termsConditions';
export const ROUTE_NAME_WATERMARK = 'watermark';
export const ROUTE_NAME_CREATE_USER = 'createuser';
export const ROUTE_NAME_PDF_VIEWER = 'pdf-viewer';
export const ROUTE_NAME_DOWNLOAD_ACCESS_CONTROL = 'download-accesscontrol';
export const PATH_NAME_DOCUMENT_SEARCH = '/document_search';


//Axios error messages
export const MSG_AXIOS_NO_RESP_SERVER = 'No response, the server might be experiencing a downtime, please try again after some time.';
export const MSG_AXIOS_TIMEOUT = 'The request timed out, please try again.';
export const MSG_AXIOS_REQUEST_ERROR = 'There was an error in your request, please check the request format and try again.';
export const AXIOS_ERR_CODE_TIMEOUT = 'ECONNABORTED';

// UI States
export const UI_STATE_SUCCESS = 'SUCCESS';
export const UI_STATE_FAILURE = 'FAILURE';
export const UI_STATE_LOADING = 'LOADING';
export const UI_STATE_EMPTY = 'EMPTY';

// Access Control Page Sections
export const SECTION_UNAUDITED_USERS = 'unaudited';
export const SECTION_AUDITED_USERS = 'audited';

// Access Control Page User Status
export const USER_STATUS_APPROVED = 'APPROVED';
export const USER_STATUS_REJECTED = 'REJECTED';

// Signup Statuses
export const ACCESS_CONTROL_SIGNUP_STATUS_PENDING = 'PENDING';
export const ACCESS_CONTROL_SIGNUP_STATUS_AUDITED = 'AUDITED';
