import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './search.scss';
import {Button, FormGroup, Input} from "reactstrap";

class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchBy: ''
        };
    }

    onChange = (e) => {
        this.setState({searchBy: e.target.value});
    }

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.props.onSubmit(this.state.searchBy, this.props.section);
        }
    }

    onSubmit = () => {
        this.props.onSubmit(this.state.searchBy, this.props.section);
    }

    onReset = () => {
        this.setState({searchBy: ''});
        this.props.onReset('', this.props.section);
    }

    render() {
        const { searchBy } = this.state;
        const { placeholder } = this.props;

        return (
            <div className="search-component">
                <FormGroup>
                    <Input
                        className="search-component__input"
                        type="text"
                        placeholder={placeholder}
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                        value={searchBy}
                    />
                </FormGroup>
                <Button
                    className="search-component__action-btn submit mr-2"
                    onClick={this.onSubmit}
                    color="primary"
                    active
                >
                    Submit
                </Button>
                <Button
                    className="search-component__action-btn reset"
                    onClick={this.onReset}
                    color="secondary"
                    active
                >
                    Reset
                </Button>
            </div>
        );
    }
}

export default observer(Search);
