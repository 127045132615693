import React, { Component, Fragment } from "react";
import './style.scss';
import { getSession } from "../../utils/AuthUtils";
import common from "../../apis/common";
import { Spinner, Input, Button, Alert } from 'reactstrap';
import { ENDPOINT_PODCASTS_DATABASE, ENDPOINT_PODCASTS_SEARCH, ENDPOINT_PODCASTS_GET_URL, DU_TOAST_ID, TOAST_TYPE_ERROR } from "../../constants/strings";
import PodcastItem from "../../components/PodcastItem";
import ShareDialog from "../../components/ShareDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AudioPlayer from "../../components/AudioPlayer";
import findindex from "lodash.findindex";

const queryString = require('query-string');
export default class Podcasts extends Component {
    constructor(props) {
        super(props);
        this.shareDialogRef = React.createRef();
    }

    state = {
        searchText: "",
        termsConditions: "",
        title: "",
        isListLoading: true,
        isPlayerLoading: false,
        audioList: [],
        currentPodcast: {},
        streamLink: "",
        isPlaying: false,
        screen: "list"
    }
    componentDidMount() {
        let shareKey = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })['id'];
        let url = ENDPOINT_PODCASTS_DATABASE;
        let params = shareKey ? {id: shareKey} : {};
        this.makeFetchAudioListCall(url, params);
    }

    componentDidUpdate(prevProps, prevState){
        let prevQueryParams = queryString.parse(prevProps.location.search, { ignoreQueryPrefix: true });
        let queryParams = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
        if (Object.keys(prevQueryParams).length && !Object.keys(queryParams).length){
            this.setState({ searchText: "" });
            this.makeFetchAudioListCall(ENDPOINT_PODCASTS_DATABASE);
        }
    }

    async makeFetchAudioListCall(url, params={}) {
        this.setState({ isListLoading: true });
        let session = await getSession();
        if (!session) {
            this.props.toastify.fire("Something went wrong. Please try again later.", DU_TOAST_ID, TOAST_TYPE_ERROR);
            return;
        }
        let response;
        try {
            let data = JSON.stringify({
                "page": 1,
                "page_size": 100
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `/all_podcasts`,
                headers: {
                    'Authorization': session.accessToken.jwtToken,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            response = await common.request(config);
            console.log(response.data['data']['podcast_list'])
            if (response && response.data.status && response.data['data']['podcast_list']
                && response.data['data']['podcast_list'].length
            ) {
                if (url !== ENDPOINT_PODCASTS_DATABASE) {
                    this.setState({ isPlayerLoading: true });
                    this.onPodcastSelect(response.data.data.podcast_list[0], true);
                }
                this.setState({
                    audioList: response.data.data.podcast_list,
                    isListLoading: false,
                });
            } else {
                this.setState({ audioList: [], isListLoading: false, currentPodcast: {} });
            }
        }
        catch (e) {
            this.setState({ audioList: [], isListLoading: false, currentPodcast: {} });
        }
    }

    async makeSearchAudioCall(searchText) {
        this.setState({ isListLoading: true });
        let session = await getSession();
        let response;
        try {
            response = await common.post(ENDPOINT_PODCASTS_SEARCH,
                {
                    podcast_etags: [],
                    search_string: searchText
                },
                {
                    headers: { "Authorization": session.accessToken.jwtToken }
                }
            );
            if (response && response.data.status && response.data['data']['podcasts']
                && response.data['data']['podcasts'].length
            ) {
                this.setState({
                    audioList: response.data.data.podcasts,
                    isListLoading: false,
                });
            } else {
                this.setState({ audioList: [], isListLoading: false, currentPodcast: {} });
            }
        }
        catch (e) {
            this.setState({ audioList: [], isListLoading: false, currentPodcast: {} });
        }
    }

    async getStreamUrlApiCall (audioTitle) {
        this.setState({ isPlayerLoading: true });
        let session = await getSession();
        let response;
        let endpoint = `${ENDPOINT_PODCASTS_GET_URL}/${audioTitle}`;
        try {
            response = await common.get(endpoint,
                {
                    params: {},
                    headers: { "Authorization": session.accessToken.jwtToken }
                }
            );
            if (response && response.data.status && response.data['data']['url']) {
                this.setState({ streamLink: response.data.data.url, isPlayerLoading: false });
            } else {
                this.setState({ streamLink: "", isPlayerLoading: false, });
            }
        }
        catch (e) {
            this.setState({ isPlayerLoading: false });
        }
    }

    onInputChange = event => {
        this.setState({ searchText: event.target.value });
        this.makeSearchAudioCall(event.target.value);
    };

    onSharePodcast = (shareURL) => {
        this.shareDialogRef.current.setShareURL(shareURL);
        window.addEventListener('message', this.shareDialogRef.current.show());
    }

    onPodcastSelect = (audioObj, setScreenTransition = true) => {
        if (setScreenTransition) {
            this.setState({ screen: "player" });
        }
        this.setState({ currentPodcast: audioObj });
        this.setState({ streamLink: audioObj?.ORIGINAL_URL, isPlayerLoading: false });
        // this.getStreamUrlApiCall(audioObj.TITLE);
    }

    onGoBack = () => {
        // Pausing the player when back button pressed
        this.setState({ screen: "list", isPlaying: false });
    }

    onSearchReset = () => {
        let share_key = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })['id'];
        if (share_key) {
            this.props.history.push('/podcasts');
        } else {
            this.setState({ searchText: "" });
            this.makeFetchAudioListCall();
        }
    }

    onPrevSelect = () => {
        let index = findindex(this.state.audioList, { SHARE_KEY: this.state.currentPodcast.SHARE_KEY });
        if (index - 1 >= 0) {
            this.onPodcastSelect(this.state.audioList[index - 1], false)
        }
    }

    onNextSelect = () => {
        let index = findindex(this.state.audioList, { SHARE_KEY: this.state.currentPodcast.SHARE_KEY });
        if (index + 1 < this.state.audioList.length) {
            this.onPodcastSelect(this.state.audioList[index + 1], false)
        }
    }

    getFileName = (audioObj) => {
        return audioObj?.TITLE;
        // let splittedFilename = audioObj?.TITLE?.split('.');
        // return splittedFilename?.slice(0, splittedFilename?.length - 1).join(".");
    }

    setPlayingState = (isPlaying) => {
        this.setState({ isPlaying });
    }
    render() {
        return (
            <div className="podcast-main-container">
                <div className={`podcast-filter-bar ${this.state.screen != "list" ? "disabled" : ""}`}>
                    <div className="search-container">
                        <Input
                            type="text"
                            value={this.state.searchText}
                            className="search-input"
                            placeholder="Search podcasts by name"
                            onChange={this.onInputChange}
                        />
                        <FontAwesomeIcon icon="search" className="search-icon" />
                    </div>
                    <button
                        title="Reset"
                        className="podcast-reset-btn"
                        onClick={this.onSearchReset}
                        disabled={
                            !queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })['id'] && 
                            !this.state.searchText.length ? true : false
                        }
                    >
                        <FontAwesomeIcon icon="undo" className="podcast-reset-icon" />
                        <span className="reset-text">Reset</span>
                    </button>
                </div>
                {
                    !!this.state.audioList.length && !!this.state.searchText &&
                    <div className="alert-container">
                        <Alert color="info"
                            className={`podcast-results-count ${this.state.screen != "list" ? "disabled" : ""}`}
                        >
                            {`Showing ${this.state.audioList.length} result(s)`}
                        </Alert>
                    </div>
                }
                {
                    this.state.screen == "player" &&
                    <div className="back-btn-container">
                        <button
                            className="podcast-go-back-btn"
                            onClick={this.onGoBack}
                        >
                            <FontAwesomeIcon icon="angle-left" className="podcast-left-icon" />
                            Go Back to podcast list
                        </button>
                    </div>
                }
                <div className="podcast-container">
                    <Fragment>
                        <div className={`podcast-list-container ${this.state.screen != "list" ? "disabled" : ""}`}>
                            {this.state.isListLoading ?
                                <div className="loader-container">
                                    <Spinner color="info" style={{ width: '3rem', height: '3rem' }} />
                                </div>
                                :
                                <ul className="podcast-list">
                                    {
                                        !!this.state.audioList.length ?
                                            this.state.audioList.map((audioObj, idx) => {
                                                return (
                                                    <PodcastItem
                                                        audioObj={audioObj}
                                                        getFileName={this.getFileName}
                                                        isPlaying={this.state.isPlaying}
                                                        currentlyPlaying={this.state.currentPodcast.SHARE_KEY == audioObj.SHARE_KEY}
                                                        onSharePodcast={this.onSharePodcast}
                                                        onPodcastSelect={this.onPodcastSelect}
                                                        idx={idx + 1}
                                                        key={idx}
                                                    />
                                                )
                                            })
                                            :
                                            <div className="no-data">
                                                No Podcasts to show
                                            </div>
                                    }
                                </ul>
                            }
                        </div>
                        <div className={`podcast-player ${this.state.screen != "player" ? "disabled" : ""}`}>
                            {this.state.isPlayerLoading ?
                                <div className="loader-container">
                                    <Spinner color="info" style={{ width: '3rem', height: '3rem' }} />
                                </div>
                                : (
                                    !!Object.keys(this.state.currentPodcast).length ?
                                        <Fragment>
                                            <AudioPlayer
                                                isPlaying={this.state.isPlaying}
                                                className="faded-background"
                                                syncPlayingState={this.setPlayingState}
                                                streamLink={this.state.streamLink}
                                                title={this.getFileName(this.state.currentPodcast)}
                                                handlePrev={this.onPrevSelect}
                                                handleNext={this.onNextSelect}
                                                options={{
                                                    autoplay: (window?.navigator?.userAgent?.indexOf("iPhone") != -1 || window?.navigator?.userAgent?.indexOf("iPad") != -1) ? false : true,
                                                    tooltips: { controls: false, seek: true },
                                                    controls: ['play-large', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings',]
                                                }}
                                            />
                                        </Fragment>
                                        :
                                        <div className="no-data">
                                            No Podcasts selected
                                        </div>
                                )
                            }
                        </div>
                    </Fragment>
                </div>
                <ShareDialog
                    ref={this.shareDialogRef}
                    toastify={this.props.toastify}
                    closeAll={this.state.closeAll}
                    title="Podcast Link"
                    backdrop="static"
                    showLabel={false}
                />
            </div>
        )
    }
}