//This is an extension of Bootstrap 4 dropdown from the library 'reactstrap'

import React, { Component } from 'react';
import './style.scss';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";

class DropdownSelect extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);

        /**
         * @type {boolean} dropdownOpen - Visibility of dropdown
         * @type {string} currSelection - Currently selected value
         */
        this.state = {
            dropdownOpen: false,
            currSelection: 'Relevance'
        };
    }

    /**
     * @desc Toggles the dropdown open/close state
     */
    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    /**
     * @desc Generates JSX code for the Dropdown items
     *
     * @returns {JSX} - Dropdown item
     */
    makeDropdownItemsJSX(selections) {
        return selections.map((selection) => (
            <DropdownItem onClick={this.props.onSortSelect} data-key={selection.key} data-label={selection.label} key={selection.key}>
                {selection.label}
            </DropdownItem>
        ));
    }

    render() {
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret>
                    {this.props.config.defaultSelection}
                </DropdownToggle>
                <DropdownMenu>
                    {this.makeDropdownItemsJSX(this.props.config.selections)}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default DropdownSelect;
