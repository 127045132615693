import React, { Component, Fragment } from 'react';
import { Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { observer } from 'mobx-react';
import TagsInput from "../../components/TagsInput";
import ShareDialog from "../../components/ShareDialog";
import DatePicker from "react-datepicker";
import { documentUploadConfig, getStockInfoConfigObj } from "../../templates/DocumentUpload";
import Validate from "../../utils/FormValidation";
import common from "../../apis/common";
import { ENDPOINT_GET_SUGGESTIONS, DU_ENDPOINT_FILE_UPLOAD } from "../../constants/strings";
import { getSession } from "../../utils/AuthUtils";
import {
    DU_TOAST_ID,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from "../../constants/strings";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AmplitudeAnalytics from "../../backends/amplitudeAnalytics";
import "./style.scss";
import axios from "axios";

const moment = require('moment');
const amplitudeAnalytics = new AmplitudeAnalytics();

axios.interceptors.response.use((response) => {
    return response;
}, error => {
    let err;

    if (error.hasOwnProperty("response")) {
        err = error.response;
    } else {
        err = error;
    }
    return Promise.reject(err);
});

class DocumentUpload extends Component {
    constructor(props) {
        super(props);
        this.shareDialogRef = React.createRef();
        this.store = {
            tagify: {
                fieldInputs: {
                    report_type: null,
                    lead_authors: null,
                    sub_authors: null,
                }
            }
        };
        this.stockInfoData = {};
        this.state = {
            closeAll: false,
            isLoading: false,
            fileInputKey: moment.now(),
            dateState: new Date(),
            URL: "",
            currentCount: 0,
            stockInfoList: [],
            stock_info_active: {},
            payload: {
                file: null,
                report_type: null,
                stock_info: [],
                lead_authors: [],
                sub_authors: [],
                release_date: null,
            },
            errors: {
                file_invalid: null,
                report_type_invalid: null,
                lead_authors_invalid: null,
                sub_authors_invalid: null,
                release_date_invalid: null,
            },
        };
    }

    clearFieldStates = () =>{
        Object.keys(this.store.tagify.fieldInputs).forEach((key)=>{
            this.store.tagify.fieldInputs[key].removeAllTags();
            if (!["report_type", "lead_authors", "sub_authors"].includes(key)){
                delete this.store.tagify.fieldInputs[key];
            }
        });

        this.setState({
            fileInputKey: moment.now(),
            currentCount: 0,
            dateState: new Date(),
            stock_info_active: {},
            stockInfoList: [],
            payload:{
                file: null,
                report_type: null,
                stock_info: [],
                lead_authors: [],
                sub_authors: [],
                release_date: null,
            }
        });
    };

    clearErrorState = () => {
        this.setState({
            responseText: "",
            errors: {
                file_invalid: null,
                report_type_invalid: null,
                lead_authors_invalid: null,
                sub_authors_invalid: null,
                release_date_invalid: null,
            }
        });
    };

    generateSuggestionsPayload = (obj, value) => {
        let identifier = obj.identifier;
        if (["lead_authors", "sub_authors"].includes(obj.identifier)){
            identifier = 'authors';
        }
        return {
            suggestion_text: value ? value : "",
            suggestion_type: [identifier],
            page: this.props.page,
            tab: this.props.tab
        };
    };

    onUpload = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });
        this.clearErrorState();
        const error = Validate('documentUpload', this.state);

        if (Object.keys(error).length) {
            this.setState({
                errors: { ...this.state.errors, ...error },
                isLoading: false
            });
            this.props.toastify.fire("Please input valid file.", DU_TOAST_ID, TOAST_TYPE_ERROR);
            return;
        }

        let stock_info_list = this.generateStockInfoPayload();
        
        let form_data = new FormData();
        form_data.append('file', this.state.payload.file, this.state.payload.file.name);

        let payloadState = { ...this.state.payload, stock_info: stock_info_list };
        delete payloadState.file;
        form_data.append('params', JSON.stringify(payloadState));

        let session = await getSession();
        if (!session) {
            this.props.toastify.fire("Something went wrong. Please try again later.", DU_TOAST_ID, TOAST_TYPE_ERROR);
            return;
        }
        let response;
        try {
            response = await common.post(
                DU_ENDPOINT_FILE_UPLOAD,
                form_data,
                {
                    headers: { "Content-Type": "text/plain", "Authorization": session.accessToken.jwtToken }
                }
            );
            if (response && response.status === 200 && response.data.status && response.data.link) {
                this.shareDialogRef.current.setShareURL(response.data.link);
                window.addEventListener('message', this.shareDialogRef.current.show());
                this.clearFieldStates();
            } else {
                throw response;
            }
            this.props.toastify.fire("File uploaded Successfully", DU_TOAST_ID, TOAST_TYPE_SUCCESS);
            this.setState({ isLoading: false });
        } catch (err) {
            let res = err.response ? err.response : {};
            if(res.hasOwnProperty('data') && res.data.hasOwnProperty("error_code") && res.data.error_code >= 900) {
                this.props.toastify.fire(res.data.error_message, DU_TOAST_ID, TOAST_TYPE_ERROR);
            } else {
                this.props.toastify.fire('Something went wrong', DU_TOAST_ID, TOAST_TYPE_ERROR);
            }
            this.setState({ isLoading: false });
        }
    };

    generateStockInfoPayload = () =>{
        let payloadList = []; 
        Object.keys(this.stockInfoData).forEach((key, idx)=>{
            let singleObj = {};
            if(this.stockInfoData[key].hasOwnProperty(`stock_${key}`) && this.stockInfoData[key][`stock_${key}`] ){
                singleObj['stock_id'] = this.stockInfoData[key][`stock_${key}`];
            }
            if(this.stockInfoData[key].hasOwnProperty(`sector_${key}`) && this.stockInfoData[key][`sector_${key}`]){
                singleObj['sector'] = this.stockInfoData[key][`sector_${key}`];
            }
            if(this.stockInfoData[key].hasOwnProperty(`recommendation_${key}`) && this.stockInfoData[key][`recommendation_${key}`]){
                singleObj['recommendation'] = this.stockInfoData[key][`recommendation_${key}`];
            }
            if(this.stockInfoData[key].hasOwnProperty(`target_price_${key}`)){
                singleObj['target_price'] = this.stockInfoData[key][`target_price_${key}`];
            }
            if(this.stockInfoData[key].hasOwnProperty(`current_price_${key}`)){
                singleObj['current_price'] = this.stockInfoData[key][`current_price_${key}`];
            }
            if(!Object.keys(singleObj).length ){
                return;
            }
            payloadList.push(singleObj);
        });
        return payloadList;
    };

    updateState = (operation, identifier, data) => {
        let prevState = { ...this.state.payload };
        if (["lead_authors", "sub_authors"].includes(identifier)) {
            if (operation === "ADD") {
                prevState[identifier].push(data.value);
            }
            else if (operation === "REMOVE") {
                let index = prevState[identifier].indexOf(data.value);
                if (index > -1) {
                    prevState[identifier].splice(index, 1);
                }
            }
            this.setState({
                payload: { ...prevState }
            });
        } else if(identifier === "report_type") {
            if (operation === "ADD") {
                prevState[identifier] = data.value;
            }
            else if (operation === "REMOVE") {
                prevState[identifier] = null;
            }
            this.setState({
                payload: { ...prevState }
            });
        } else {
            let indexNumber = identifier.split('_'); 
            indexNumber = parseInt(indexNumber[indexNumber.length - 1]);
            if (operation === "ADD") {
                if(!this.stockInfoData.hasOwnProperty(indexNumber)){
                    this.stockInfoData[indexNumber] = {};
                }
                if(identifier.includes('stock') && data.sector_name){
                    this.store.tagify.fieldInputs[`sector_${indexNumber}`].addTags([
                    {
                        category: 'sector',
                        value: data.sector_name 
                    }
                ]);
                }
                this.stockInfoData[indexNumber][identifier] = identifier.includes('stock') ? data.stock_id.toString() : data.value;
            }
            else if (operation === "REMOVE") {
                if(identifier.includes('stock') && data.sector_name){
                    this.store.tagify.fieldInputs[`sector_${indexNumber}`].removeTag(data.sector_name);
                }
                this.stockInfoData[indexNumber][identifier] = null;
            }
        }
    };

    onTagAdd = (e, identifier, forceSuggesterResults) => {
        console.log(e);
        this.updateState('ADD', identifier, e.detail.data);
    };

    onTagRemove = (e, identifier) => {
        console.log(e);
        this.updateState('REMOVE', identifier, e.detail.data);
    };

    transformTag = (tagData, identifier)=>{
        if(this.handleDisableSuggestion(tagData.value, identifier)){
            tagData.value = '';
        }
    };

    onInputChange = event => {
        if(event.target.name.includes('target_price') || event.target.name.includes('current_price')){
            let indexNumber = event.target.name.split('_'); 
            indexNumber = parseInt(indexNumber[indexNumber.length - 1]);
            if (!this.stockInfoData.hasOwnProperty(indexNumber)){
                this.stockInfoData[indexNumber] = {};
            }
            if (isNaN(event.target.value.trim())){
                this.props.toastify.fire("Price needs to be a number", DU_TOAST_ID, TOAST_TYPE_ERROR);
                return;
            }
            this.stockInfoData[indexNumber][event.target.name] = parseInt(event.target.value.trim());
        } else {
            let prevState = { ...this.state.payload };
            prevState[event.target.name] = event.target.value;
            this.setState({
                payload: { ...prevState }
            });
        }
    };

    handleDateChange = (date) => {
        let payload = { ...this.state.payload };
        payload.release_date = moment(date).format('YYYY-MM-DD');
        this.setState({
            dateState: date,
            payload: { ...payload }
        });
    };

    handleUpload = (e) => {
        let payload = { ...this.state.payload };
        payload.file = e.target.files[0];
        this.setState({
            payload: { ...payload }
        });
    };

    componentDidMount() {
        if (!this.props.auth.user ||
            !this.props.auth.user.signInUserSession.accessToken.hasOwnProperty("payload") ||
            !this.props.auth.user.signInUserSession.accessToken.payload.hasOwnProperty("cognito:groups") ||
            !this.props.auth.user.signInUserSession.accessToken.payload["cognito:groups"].length ||
            !this.props.auth.user.signInUserSession.accessToken.payload["cognito:groups"].includes("spark")
        ) {
            this.props.history.push("/document_search");
        }
    }

    generateStockListObjAndUpdateStore = (currentCount)=>{
        let newObj = getStockInfoConfigObj(currentCount);

        let storeObj = this.getStockInfoStoreObj(currentCount);    
        let fieldInputs = { ...this.store.tagify.fieldInputs, ...storeObj};

        this.store.tagify.fieldInputs = { ...fieldInputs };
        console.log("store", this.store);
        return newObj;
    };

    handleDisableSuggestion = (value, category) =>{
        let isDisabled = false;
        if (category === "sub_authors" && this.state.payload.lead_authors.includes(value)) {
            isDisabled = true;
        }
        else if (category === "lead_authors" && this.state.payload.sub_authors.includes(value) ) {
            isDisabled = true;
        }
        return isDisabled;
    };

    getStockInfoStoreObj = (index) => {
        let store = {};
        store[`stock_${index}`] = null;
        store[`sector_${index}`] = null;
        store[`recommendation_${index}`] = null;
        return store;
    };

    handleAddStockInfo = () => {
        let newObj = this.generateStockListObjAndUpdateStore(this.state.currentCount + 1);
        let list = [ ...this.state.stockInfoList];
        list.push(newObj);
        let stock_info_active = { ...this.state.stock_info_active };
        Object.keys(stock_info_active).forEach(key=>{
            stock_info_active[key] = "";
        });
        stock_info_active[this.state.currentCount+1] = "active";
        this.setState({
            stockInfoList: [...list],
            stock_info_active: {...stock_info_active},
            currentCount: this.state.currentCount + 1
        })
    };

    onAccordianToggle = (stock_id) => {
        let prevState = {...this.state.stock_info_active};
        prevState[stock_id] = prevState[stock_id] ? "" : "active";
        this.setState({
            stock_info_active: {...prevState } 
        })
    };

    generateFields = (obj, idx) => {
        switch(obj.type){
            case "file":
                return (
                    <div className="file-container">
                        <input 
                            type="file"
                            key={this.state.fileInputKey}
                            id="file"
                            accept="application/pdf" 
                            onChange={this.handleUpload} 
                            required />
                    </div>
                );

            case "tagify":
                return (
                    <Fragment>
                        <TagsInput
                            auth={this.props.auth}
                            store={this.store}
                            config={obj.config}
                            filterType="fieldInputs"
                            className='field-tags-input collapsed-input'
                            transformTag={this.transformTag.bind(this)}
                            on_add={this.onTagAdd}
                            on_remove={this.onTagRemove}
                            expandOnhover={true}
                            mode="input"
                            apiObj={common}
                            disableSuggestion={this.handleDisableSuggestion}
                            generatePayload={this.generateSuggestionsPayload}
                            suggestionsApiUrl={ENDPOINT_GET_SUGGESTIONS}
                        />
                        <div className="feedback-field"></div>
                    </Fragment>
                );

            case "text":
                return (
                    <FormGroup>
                        <Input
                            type="text"
                            name={obj.identifier}
                            onChange={this.onInputChange}
                            invalid={this.state.errors[obj.identifier + "_invalid"]}
                        />
                    </FormGroup>
                );

            case "date":
                return (
                    <div className="date-container">
                        <DatePicker
                            className="date-picker"
                            dateFormat="dd-MMM-yyyy"
                            selected={this.state.dateState}
                            onChange={this.handleDateChange}
                            popperPlacement="top"
                            dropdownMode="select"
                            placeholderText="Select a date"
                            showMonthDropdown
                            showYearDropdown
                            />
                    </div>
                );

            case "multiple":
                return (
                    <Fragment key={idx}>
                        <div 
                            className={ `stock-accordian-toggle ${this.state.stock_info_active[obj.index]}`}
                            onClick={() => { this.onAccordianToggle(obj.index)}}
                        >
                            Stock - {obj.index}
                        </div>
                        <div className={ `stock-fields-container ${this.state.stock_info_active[obj.index]}`}>
                            {
                                obj.config.map((sub_obj, idx)=>{
                                    return (
                                        <div className="form-field" key={idx}>
                                            <span className="field-label">{sub_obj.fieldName}</span>
                                            { 
                                                this.generateFields(sub_obj)
                                            }
                                            <div className="form-feedback">{this.state.errors[sub_obj.identifier + "_invalid"] && sub_obj.mandatory ? "Invalid Input" : ''}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Fragment>
                )
        }
    };

    render() {
        return (
            <div className="document-upload">
                <div className="upload-card">
                    <div className="card-header">
                        <div className="card-title">
                            Document Upload
                        </div>
                    </div>
                    <div className="card-body">
                        <Form>
                            <div className="form-input-container">
                                {
                                    documentUploadConfig.map((obj, idx) => {
                                        return (
                                            <div className="form-field" key={idx}>
                                                <span className="field-label">{obj.fieldName}
                                                    {obj.mandatory && <b className="mandatory">*</b>}
                                                </span>
                                                {
                                                    this.generateFields(obj)
                                                }
                                                {/* <div className="form-feedback">{this.state.errors[obj.identifier + "_invalid"] && obj.mandatory ? "Invalid Input" : ''}</div> */}
                                            </div>
                                        )
                                    })
                                }
                                <div className="stock-info-container">
                                    {
                                        this.state.stockInfoList.map((stock_obj, idx)=>{
                                            return this.generateFields(stock_obj, idx);
                                        })
                                    }
                                </div>
                                <Button
                                    className="add-stock-info-button"
                                    size="sm"
                                    onClick={this.handleAddStockInfo}
                                >Add stock info
                                    <FontAwesomeIcon icon="plus-square" className="add-icon ico" />
                                </Button>
                            </div>
                            <div className="required-legend"><b className="mandatory">*</b>(Required Fields)</div>
                            <Button
                                className="submit-button"
                                size="sm"
                                onClick={this.onUpload}
                            >{!this.state.isLoading ? "Submit and Upload" : <Spinner size="sm" color="white" />}
                            </Button>
                        </Form>
                        <ShareDialog
                            ref={this.shareDialogRef}
                            toastify={this.props.toastify}
                            closeAll={this.state.closeAll}
                            title="Document Link"
                            backdrop="static"
                            showLabel={false}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(DocumentUpload);
