/**
 * @desc Truncates a string beyong target length
 * and replaces last 3 chars with '...'
 *
 * @param {string} str - Source string
 * @param {number} maxLen - Maximum permissible length
 * @returns {string} - Truncated string
 */
export function truncateWithElipsis(str, maxLen) {
    if(str.length > maxLen) {
        return str.substring(0, maxLen).slice(0, -3).concat('...');
    } else {
        return str;
    }
}
