import React, {Fragment} from 'react';
import {
    AXIOS_ERR_CODE_TIMEOUT,
    DS_ENDPOINT_DOWNLOAD_REPORT, DS_ENDPOINT_DOWNLOAD_REPORT_NWM,
    DS_TOAST_ID,
    MSG_AXIOS_NO_RESP_SERVER,
    MSG_AXIOS_REQUEST_ERROR,
    MSG_AXIOS_TIMEOUT, MSG_DOC_DOWNLOAD_ERROR_INVALID_URL, MSG_DOC_DOWNLOAD_ERROR_NO_URL,
    MSG_STH_WENT_WRONG_DOC_DOWNLOAD,
    TOAST_TYPE_ERROR
} from "../../constants/strings";
import {Spinner} from "reactstrap";
import { getSession } from "../../utils/AuthUtils";
import FloatingLoader from "../FloatingLoader";

/**
 * @class
 * @classdesc This is a wrapper class for loading PDF documents
 * via Mozilla PDFJS library into a container
 */
export default class PDFViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            active: false,
            empty: false,
            fail: false,
        };

        /**
         * @type {object} element - Reference to the target container which will house the PDF viewer iframe
         */
        this.pdfViewerContainerRef = React.createRef();
        this.floatingLoaderRef = React.createRef();
    }

    //Lifecycle methods
    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.active && !this.props.store.selectedDocumentIsOpen) {
            this.initBackend();
        }
    }

    initBackend() {
        try {
            //If a document isn't open already
            if(!this.props.store.selectedDocumentIsOpen) {
                let pageNum = 1;
                let searchTerms = '';

                if(this.props.store.selectedSnippetPageNum) {
                    if(this.props.store.selectedSnippetPageNum > 0) {
                        pageNum = this.props.store.selectedSnippetPageNum;
                    }
                }

                if(this.props.store.selectedSnippetSearchTerms) {
                    if(this.props.store.selectedSnippetSearchTerms.length > 0) {
                        searchTerms = encodeURI(this.props.store.selectedSnippetSearchTerms);
                    }
                }

                let source = `${process.env.PUBLIC_URL}/libs/pdf-dist/web/viewer.html?file=${this.props.store.selectedDocumentBlob}#page=${pageNum}&search=${searchTerms}&zoom=0`;

                this.props.backend.init(source, this.pdfViewerContainerRef.current);
                this.props.store.setSelectedDocumentIsOpen(true);
            }
        } catch(ex) {
            console.error(ex);
            this.props.toastify.fire(MSG_STH_WENT_WRONG_DOC_DOWNLOAD, DS_TOAST_ID, TOAST_TYPE_ERROR);
        }
    }

    /**
     * @desc Triggers the component's engine.
     */
    trigger = () => {
        this.makeDownloadDocumentAPICall();
    };

    /**
     * @desc Calls the asynchronous method 'downloadDocument', it is a wrapper method which
     * relieves the caller from the hassles of handling promises.
     */
    makeDownloadDocumentAPICall = () => {
        if(this.props.store.triggerForClientDownload) {
            this.downloadDocument()
            .then(this.downloadDocumentThenCallback)
            .catch(this.axiosErrorCallback);
        } else {
            this.downloadDocumentNWM()
            .then(this.downloadDocumentThenCallback)
            .catch(this.axiosErrorCallback);
        }
    };

    /**
     * @desc Method for making the API call to download PDF document with watermark,
     * @async
     * @returns {object} - Response object from the server
     */
    async downloadDocument() {
        let session = await getSession();

        //Show loader if trigger is for download
        if(this.props.store.triggerForClientDownload) {
            this.floatingLoaderRef.current.toggle(true);
        }

        return await this.props.api.post(
            DS_ENDPOINT_DOWNLOAD_REPORT,
            {
                id: this.props.doc_id ? this.props.doc_id : this.props.store.selectedDocumentId
            },
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                    'Authorization': session.accessToken.jwtToken
                }
            }
        );
    }

    async downloadDocumentNWM() {
        let session = await getSession();

        //Show loader if trigger is for download
        if(this.props.store.triggerForClientDownload) {
            this.floatingLoaderRef.current.toggle(true);
        }

        return await this.props.api.get(
            DS_ENDPOINT_DOWNLOAD_REPORT_NWM,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                    'Authorization': session.accessToken.jwtToken
                },
                params: {
                    url_mask: this.props.doc_id ? this.props.doc_id : this.props.store.selectedDocumentId
                }
            }
        );
    }

    /**
     * @desc Callback method fired when response from the async method 'downloadDocument'
     * has been received successfully
     *
     * @param {object} response - Response from the api call
     */
    downloadDocumentThenCallback = (response) => {
        try {
            if(response.status === 200 && response.hasOwnProperty('data')) {// 'OK'
                if(response.data) {
                    if(response.data.hasOwnProperty('error_message')) {
                        this.downloadDocumentFailed(response.data.error_message);
                    } else {
                        this.downloadDocumentSuccess(response);
                    }
                } else {
                    this.downloadDocumentFailed(MSG_STH_WENT_WRONG_DOC_DOWNLOAD);
                }
            } else {
                this.downloadDocumentFailed(MSG_STH_WENT_WRONG_DOC_DOWNLOAD);
            }
        } catch(ex) {
            console.log(ex);
            this.downloadDocumentFailed(MSG_STH_WENT_WRONG_DOC_DOWNLOAD);
        }
    };

    /**
     * @desc Callback method fired when an error is thrown
     * out of an axios request
     *
     * @param {object} error - Exception object
     */
    axiosErrorCallback = (error) => {
        if (error.response) {
            this.downloadDocumentFailed(MSG_STH_WENT_WRONG_DOC_DOWNLOAD);
        } else if (error.request) {
            // The request was made but no response was received
            if(error.code === AXIOS_ERR_CODE_TIMEOUT) {
                this.downloadDocumentFailed(MSG_AXIOS_TIMEOUT);
            } else {
                this.downloadDocumentFailed(MSG_AXIOS_NO_RESP_SERVER);
            }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            this.downloadDocumentFailed(MSG_AXIOS_REQUEST_ERROR);
        }
    };

    getDocumentMeta(response) {
        let documentMeta = {
            title: '',
            fileName: '',
            stocks: [],
            reportTypes: [],
            sectors: [],
            recommendations: [],
        };

        try {
            if(response.headers.hasOwnProperty('x-filemetadata')) {
                let fileMetaDataString = response.headers['x-filemetadata'].replace(/'/g, '"');
                let fileMetaDataJson = JSON.parse(fileMetaDataString);

                if (fileMetaDataJson.stock_info) {
                    for(let key in fileMetaDataJson.stock_info) {
                        if (fileMetaDataJson.stock_info.hasOwnProperty(key)) {
                            documentMeta.stocks.push(fileMetaDataJson.stock_info[key].stock_name);
                            documentMeta.sectors.push(fileMetaDataJson.stock_info[key].sector);
                            documentMeta.recommendations.push(fileMetaDataJson.stock_info[key].recommendation);
                        }
                    }
                }

                documentMeta.reportTypes.push(fileMetaDataJson.report_type);
                documentMeta.title = fileMetaDataJson.title;
                documentMeta.fileName = fileMetaDataJson.filename || fileMetaDataJson.fileName;
            }

            return documentMeta;
        } catch(e) {
            console.error(e);
            return {
                title: '',
                stocks: [],
                reportTypes: [],
                sectors: [],
                recommendations: [],
            };
        }
    }

    downloadDocumentSuccess(response) {
        try {
            let documentMeta = this.getDocumentMeta(response);

            this.props.store.setSelectedDocumentBlob(window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })));

            if(this.props.store.triggerForClientDownload) {
                this.props.amplitudeAnalytics.documentAction(documentMeta, 'Downloaded');
                this.downloadDocumentToClient(documentMeta.fileName || '');
            } else {
                this.props.amplitudeAnalytics.documentOpen(
                    this.props.store.selectedDocumentSource,
                    {
                        documentMeta
                    }
                );
                this.setState({active: true, loading: false});
            }
        } catch(e) {
            console.error(e);
        }
    }

    downloadDocumentFailed(errorMsg) {
        this.props.toastify.fire(errorMsg, DS_TOAST_ID, TOAST_TYPE_ERROR);

        /*
        * If the document was triggered for viewing purpose only,
        * in case of a failed state, we show a placeholder
        * */
        if(!this.props.store.triggerForClientDownload) {
            this.setState({fail: true, loading: false});
        }

        //Remove loader
        this.floatingLoaderRef.current.toggle(false);
    }

    downloadDocumentToClient(filename = '') {
        try {
            if(this.props.store.selectedDocumentBlob.length) {
                const downloadFileName = (filename && filename.length) ? filename : 'report.pdf';
                let link = document.createElement('a');
                link.href = this.props.store.selectedDocumentBlob;
                link.setAttribute('download', downloadFileName);
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
            } else {
                this.props.toastify.fire(MSG_DOC_DOWNLOAD_ERROR_NO_URL, DS_TOAST_ID, TOAST_TYPE_ERROR);
            }
        } catch(ex) {
            console.error(ex);
            this.props.toastify.fire(MSG_DOC_DOWNLOAD_ERROR_INVALID_URL, DS_TOAST_ID, TOAST_TYPE_ERROR);
        } finally {
            //Remove floating loader
            this.floatingLoaderRef.current.toggle(false);
        }
    }

    getStateElementJSX() {
        if(this.state.loading) {
            return (
                <div className="state-element loading">
                    <Spinner type="grow" color="secondary" />
                    <Spinner type="grow" color="secondary" />
                    <Spinner type="grow" color="secondary" />
                </div>
            );
        } else if(this.state.active) {
            return (
                <div
                    className="state-element success"
                    id='pdf-viewer'
                    ref={this.pdfViewerContainerRef}
                    style={{width: '100%', height: '100%'}}
                >
                    {/*Append iframe here*/}
                </div>
            );
        } else if(this.state.fail) {
            return (
                <div className="state-element fail" ref={this.failStateElemRef}>
                    <h3 className="placeholder-text">
                        SOMETHING WENT WRONG :(
                    </h3>
                </div>
            );
        }
    }

    render() {
        return (
            <Fragment>
                {this.getStateElementJSX()}
                <FloatingLoader ref={this.floatingLoaderRef} message="Downloading" xPos="center" yPos="center"/>
            </Fragment>
        );
    }
}
