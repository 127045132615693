import { ENDPOINT_GET_SUGGESTIONS } from "../../constants/strings";

export const documentUploadConfig = [
    {
        fieldName: "File",
        state: "file",
        invalidState: "filename_invalid",
        mandatory: true,
        type: "file"
    },
    {
        fieldName: "Report Type",
        mandatory: false,
        type: "tagify",
        config: {
            identifier: 'report_type',
            placeholder: 'Annual Report, Conference Call',
            apiURL: ENDPOINT_GET_SUGGESTIONS,
            suggester: true, // Whether to enable autocomplete suggesters for this particular input field
            fetchDynamically: false,
            forceSuggesterResults: true,
            enforceWhitelist: true,
            maxTags: 1,
            whitelist: [
            ],
        },
    },
    {
        fieldName: "Lead Authors",
        mandatory: false,
        type: "tagify",
        config: {
            identifier: 'lead_authors', // This will uniquely identify a suggester element and an input box in advanced search panel
            placeholder: '', // Placeholder for the input element <input />
            apiURL: ENDPOINT_GET_SUGGESTIONS,
            suggester: true, // Whether to enable autocomplete suggesters for this particular input field
            fetchDynamically: false,
            forceSuggesterResults: true,
            enforceWhitelist: true,
            whitelist: [
            ],
        },
    },
    {
        fieldName: "Sub-Authors",
        mandatory: false,
        type: "tagify",
        config: {
            identifier: 'sub_authors', // This will uniquely identify a suggester element and an input box in advanced search panel
            placeholder: '', // Placeholder for the input element <input />
            apiURL: ENDPOINT_GET_SUGGESTIONS,
            suggester: true, // Whether to enable autocomplete suggesters for this particular input field
            fetchDynamically: false,
            forceSuggesterResults: true,
            enforceWhitelist: true,
            whitelist: [
            ],
        },
    },
    {
        fieldName: "Release Date",
        identifier: "release_date",
        mandatory: false,
        type: "date"
    },
]

export function getStockInfoConfigObj (index){
    return {
        index: index,
        fieldName: "Stock Info",
        mandatory: false,
        type: "multiple",
        config: [
            {
                fieldName: "Stock",
                mandatory: false,
                type: "tagify",
                config: {
                    suggestionIdentifier: "stock",
                    identifier: `stock_${index}`, // This will uniquely identify a suggester element and an input box in advanced search panel
                    name: "Stock", // This is the label which will be shown against an input box in advanced search panel
                    placeholder: 'Maruti Suzuki, Bajaj Finserv, Bharti Airtel', // Placeholder for the input elemen <input />
                    apiURL: ENDPOINT_GET_SUGGESTIONS,
                    suggester: true,
                    fetchDynamically: true,
                    forceSuggesterResults: true, // manually checks for  
                    enforceWhitelist: true,
                    maxTags: 1,
                    whitelist: [
                    ],
                }
            },
            {
                fieldName: "Recommendation",
                mandatory: false,
                type: "tagify",
                config: {
                    suggestionIdentifier: "recommendation",
                    identifier: `recommendation_${index}`, // This will uniquely identify a suggester element and an input box in advanced search panel
                    placeholder: '', // Placeholder for the input element <input />
                    apiURL: ENDPOINT_GET_SUGGESTIONS,
                    suggester: true, // Whether to enable autocomplete suggesters for this particular input field
                    fetchDynamically: false,
                    forceSuggesterResults: true,
                    enforceWhitelist: true,
                    maxTags: 1,
                    whitelist: [
                    ],
                },
            },
            {
                fieldName: "Current Price",
                identifier: `current_price_${index}`,
                mandatory: false,
                type: "text",
            },
            {
                fieldName: "Target Price",
                identifier: `target_price_${index}`,
                mandatory: false,
                type: "text"
            },
            {
                fieldName: "Sector",
                mandatory: false,
                type: "tagify",
                config: {
                    suggestionIdentifier: "sector",              
                    identifier: `sector_${index}`, // This will uniquely identify a suggester element and an input box in advanced search panel
                    placeholder: 'FMCG, Automobiles & Ancillaries, Bank', // Placeholder for the input element <input />
                    apiURL: ENDPOINT_GET_SUGGESTIONS,
                    suggester: true, // Whether to enable autocomplete suggesters for this particular input field
                    fetchDynamically: false,
                    forceSuggesterResults: true,
                    enforceWhitelist: true,
                    maxTags: 1,
                    whitelist: [
                    ],
                }
            }
        ]
    }
} 