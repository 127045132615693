import React, { useEffect, useState } from "react";
import './style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
} from 'reactstrap';
import { PODCAST_SHARE_URL_PREFIX } from "../../../constants/strings";
function PodcastItem({
    pager,
    podcast = [],
    setSelectedPodcast,
    selectedPodcast,
    filter,
    setFilter,
    setIsPlaying,
    setModal,
    setShareURL
}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const onPrevButtonClick = () => {
        if (currentPage > 1) {
            setCurrentPage(parseFloat(currentPage) - 1);
            checkPageNumberAndUpdateFilters(parseFloat(currentPage) - 1);
        }
    };

    const onNextButtonClick = () => {
        if (currentPage < pager?.total_pages) {
            setCurrentPage(parseFloat(currentPage) + 1);
            checkPageNumberAndUpdateFilters(parseFloat(currentPage) + 1);
        }
    };

    const onPageInput = (e) => {
        setCurrentPage(e.target.value);
    };

    const onPageKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (!isNaN(e.target.value)) {
                checkPageNumberAndUpdateFilters(parseInt(e.target.value));
            }
        }
    };
    function calculateFromFilter(pageNumber) {
        if (pageNumber <= 0) {
            return false;
        }
        if (pageNumber <= pager?.total_pages) {
            return pageNumber;
        }
        return false;
    }
    function checkPageNumberAndUpdateFilters(pageNumber) {
        try {
            let from = calculateFromFilter(pageNumber);
            if (from !== false) {
                setFilter({
                    ...filter,
                    page: pageNumber
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        setCurrentPage(pager?.page)
    }, [pager])

    return (
        <>
            <div className="px-4 pdf-container" style={{ border: "0.5px solid #bdc3c7" }}>
                <div>
                    <div className="py-4 d-flex align-items-center justify-content-between">
                        <div className="">
                            <div className="as_result-count">
                                RESULTS
                                (<span className="figure">{pager?.total_count}</span>)
                            </div>
                        </div>
                        <div className="pagination ml-4">
                            <button className="as_prev" onClick={() => onPrevButtonClick()} >
                                <FontAwesomeIcon
                                    icon="caret-left"
                                />
                            </button>
                            <div className="current-page">
                                <span className="label">Page: </span>
                                <input
                                    className="current-page-input"
                                    value={currentPage}
                                    onChange={(e) => onPageInput(e)}
                                    onKeyPress={(e) => onPageKeyPress(e)}
                                    type="text" />
                                <span className="total-result ml-1">/{pager?.total_pages}</span>
                            </div>
                            <button className="as_next" onClick={() => onNextButtonClick()} >
                                <FontAwesomeIcon
                                    icon="caret-right"
                                />
                            </button>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="label">Sort by</span>
                            <div className="d-flex ml-4">
                                <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" >
                                    <DropdownToggle className="date-dropdown" outline caret>Date</DropdownToggle>
                                    <DropdownMenu >
                                        <DropdownItem >Date</DropdownItem>
                                        <DropdownItem >Title</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="pdf-list">
                    {
                        podcast?.length < 0 ?
                            <div className="loader-container">
                                <Spinner color="info" style={{ width: '3rem', height: '3rem' }} />
                            </div>
                            :
                            podcast?.map((item) => {
                                return (
                                    <div
                                        style={{
                                            backgroundColor: selectedPodcast?.ID == item.ID ? "#336699" : "#fff"
                                        }}
                                        key={item?.ID}
                                        onClick={() => {
                                            const data = podcast?.filter((d) => d.ID == item.ID)
                                            setSelectedPodcast(data?.[0])
                                            setIsPlaying(true)
                                        }}
                                    >
                                        <li className="pdf-list-item px-3">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <FontAwesomeIcon
                                                        className={`${selectedPodcast?.ID == item.ID ? 'text-white' : ''}`}
                                                        icon={['far', 'play-circle']}
                                                    />
                                                    <div className={`ml-2 ${selectedPodcast?.ID == item.ID ? 'text-white' : ''}`}>
                                                        <span>{item?.TITLE}</span>
                                                    </div>
                                                </div>
                                                <div className={`${selectedPodcast?.ID == item.ID ? 'text-white' : ' pdf-date'} d-flex align-items-center`}>
                                                    <span className={`mr-3 ${selectedPodcast?.ID == item.ID ? 'text-white' : ''}`} >
                                                        {item?.RELEASE_DATE}
                                                    </span>
                                                    <FontAwesomeIcon
                                                        className={`${selectedPodcast?.ID == item.ID ? 'text-white' : 'share-btn'} `}
                                                        icon="share-alt"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setModal(true);
                                                            const data = podcast?.filter((d) => d.ID == item.ID)?.[0];

                                                            let url =
                                                              (window.location
                                                                .href).split("/");
                                                            let baseURL = `${url[0]}//${url[2]}/podcasts`;
                                                            let shareURL = `${baseURL}?id=${data?.URL_MASK}`;
                                                            setShareURL(shareURL)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </div>
                                )
                            })
                    }
                </ul>
            </div>
        </>
    );
}

export default PodcastItem;
