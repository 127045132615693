export function getDocumentMetaTemplate() {
    return {
        stocks: [],
        reportTypes: [],
        sectors: [],
        recommendations: [],
    };
}

export function getSnippetsTemplate() {
    return {
        placeholder: true,
        data: [],
    };
}

export function getLoadedDocumentMetaTemplate() {
    return {
        stock: '',
        reportName: '',
    };
}
