import React from 'react'

function Banner() {
    return (
        <div
            style={{
                width: "100%",
                height: "10%",
                backgroundColor: "#cb1f27",
                color: "white",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <h3>
                Please Migrate to our new website{" "}
                <a
                    style={{
                        color: "#000"
                    }}
                    href="https://www.avendusspark.com">Avendusspark</a>
            </h3>
        </div>
    )
}

export default Banner