/**
 * @desc Gets URL query params by name
 *
 * @param {string} name - Param name
 * @param {string} url - Target URL
 * @returns {string} value - Param value
 */
import Fingerprint2 from 'fingerprintjs2';
import UAParser from 'ua-parser-js'

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function insertParam(key, value) {
    key = escape(key);
    value = escape(value);

    var kvp = document.location.search.substr(1).split('&');
    if (kvp == '') {
        document.location.search = '?' + key + '=' + value;
    }
    else {

        var i = kvp.length; var x; while (i--) {
            x = kvp[i].split('=');

            if (x[0] == key) {
                x[1] = value;
                kvp[i] = x.join('=');
                break;
            }
        }

        if (i < 0) { kvp[kvp.length] = [key, value].join('='); }

        //this will reload the page, it's likely better to store this until finished
        document.location.search = kvp.join('&');
    }
}

export function generateBrowserFingerprint(callback) {
    Fingerprint2.get({}, (components) => {
        const values = components.map((component) => component.value);
        const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
        callback(fingerprint);
    });
}

export async function updateSessionAndDevicesArray(fingerprint, Auth, devicesAttribute, sessionCountCheck, user, logout = false) {
    var parser = new UAParser();
    let devicesArray = [];
    if (devicesAttribute) {
        devicesArray = JSON.parse(devicesAttribute);
    }
    console.log("fingerprint", fingerprint)
    if (logout) { //logout flow
        const updatedSessionCount = parseInt(sessionCountCheck) > 0 ?
            parseInt(sessionCountCheck) - 1 : 0;
        const filteredDevices = devicesArray?.filter((device) => device.fingerprint != fingerprint);
        await Auth.updateUserAttributes(user, {
            'custom:sessionCount': updatedSessionCount.toString(),
            ...(filteredDevices ? ({
                'custom:devices':
                    updatedSessionCount == 0 ? JSON.stringify([]) :
                        JSON.stringify(filteredDevices)
            }) : {})
        });
    } else {//login flow

        // Check if the fingerprint already exists in devicesArray
        const existingDeviceIndex = devicesArray.findIndex(device => device.fingerprint == fingerprint);

        if (existingDeviceIndex === -1) {
            const parse = parser.getResult();
            const data = {
                fingerprint: fingerprint,
                browser: parse?.browser?.name,
                device: parse?.device?.vendor || parse?.os?.name,
                os: parse?.os?.name
            };

            devicesArray.push(data);

            const updatedSessionCount = parseInt(sessionCountCheck) + 1;
            await Auth.updateUserAttributes(user, {
                'custom:sessionCount': updatedSessionCount.toString(),
                'custom:devices': JSON.stringify(devicesArray)
            });
        }
    }
}