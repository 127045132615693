import {validJsonKey} from "../utils/DataValidation";

class AuthenticationStore {
    user = {
        email: '',
        name: ''
    };
    redirectRoute = "";
    emailFromPreviousScreen = "";

    setRedirectRoute = (route) => {
        this.redirectRoute = route;
    }

    setUser(user) {
        if(validJsonKey(user, 'email', 'string', true)) {
            this.user.email = user.email;
        }

        if(validJsonKey(user, 'name', 'string', true)) {
            this.user.name = user.name;
        }
    }

    setEmail(email) {
        this.emailFromPreviousScreen = email;
    }

    clearEmail() {
        this.emailFromPreviousScreen = "";
    }
}

export default AuthenticationStore;
