import React, { useEffect, useState } from 'react'
import axios from "axios";
import PodcastFilter from '../../components/PodcastNew/PodcastFilter'
import PodcastItem from '../../components/PodcastNew/PodcastItem';
import NewAudioPlayer from '../../components/PodcastNew/NewAudioPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSession } from '../../utils/AuthUtils';
import common from '../../apis/common';
import _, { findIndex } from 'lodash';
import { dummydata, podcastfilterPanelValInitial } from '../../templates/PodCastList';
import moment from 'moment';
import { DS_TOAST_ID, DU_TOAST_ID, ENDPOINT_GET_PODCAST_SUGGESTIONS, ENDPOINT_PODCASTS_GET_URL, MSG_COPIED_CLIPBOARD, MSG_NOT_COPIED_CLIPBOARD, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '../../constants/strings';
import { observer } from 'mobx-react';
import PodcastSearchFilters from '../../components/PodcastNew/PodcastSearchFilters';
import { toJS } from 'mobx';
import './style.scss';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
const queryString = require('query-string');


const defaultFiltter = {
    stock_filter: {
        stocks: []
    },
    sector_filter: {
        sectors: []
    },
    author_filter: {
        author_names: []
    },
    date_filter: {
        from_date: '2009-01-01',
        to_date: moment().format('YYYY-MM-DD')
    },
    podcast_filter: {
        podcast_mask: []
    },
    "include_all": {
        "words": [
        ]
    },
    "include_any": {
        "words": [
        ]
    },
    "exclude_all": {
        "words": [
        ]
    },
    "content": {
        "query": ""
    },
    page: 1,
    page_size: 7
}
const PodcastNew = (props) => {
    const [podcast, setPodcast] = useState([]);
    const [loading, setLoading] = useState();
    const [selectedPodcast, setSelectedPodcast] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [pager, setPager] = useState({});
    const [filterData, setFilterData] = useState([]);
    const [filter, setFilter] = useState(defaultFiltter);
    const [selectedFilter, setSelectedFilter] = useState(podcastfilterPanelValInitial);
    const [previousSelectedFilter, setpreviousSelectedFilter] = useState(podcastfilterPanelValInitial);
    const apiCall = async () => {
        setFilter({
            ...filter,
            page: 1,
            stock_filter: {
                stocks: [
                    ...new Set([...previousSelectedFilter.stocks,
                    ...selectedFilter.stocks])
                ]
            },
            sector_filter: {
                sectors: [
                    ...new Set([...previousSelectedFilter.sectors,
                    ...selectedFilter.sectors])
                ]
            },
            author_filter: {
                author_names: [
                    ...new Set([...previousSelectedFilter.author_names,
                    ...selectedFilter.author_names])
                ]
            }
        })
        setpreviousSelectedFilter({
            stocks: [
                ...new Set([...previousSelectedFilter.stocks,
                ...selectedFilter.stocks])
            ],
            sectors: [
                ...new Set([...previousSelectedFilter.sectors,
                ...selectedFilter.sectors])
            ],
            author_names: [
                ...new Set([...previousSelectedFilter.author_names,
                ...selectedFilter.author_names])
            ]
        });
        setSelectedFilter({
            "author_names": [],
            "stocks": [],
            "sectors": [],
        });
    }
    const docSearchFiltersRef = React.createRef();
    useEffect(() => {
        filter.date_filter.from_date = moment(filter.date_filter.from_date || '2009-01-01').format("YYYY-MM-DD");
        filter.date_filter.to_date = moment(filter.date_filter.to_date || moment().format('YYYY-MM-DD')).format("YYYY-MM-DD");
        makeFetchAudioListCall()
    }, [filter])

    useEffect(() => {
        if (selectedPodcast?.TITLE && selectedPodcast?.TITLE != "") {
            getStreamUrlApiCall(selectedPodcast?.TITLE)
        }
    }, [selectedPodcast])

    function getSectors() {
      let user = props.auth.user;
      let sectors = user.attributes["custom:sectors"];
      let restriction;
      if (user.attributes["custom:restriction"]) {
        restriction = user.attributes["custom:restriction"];
      }
      try {
        if (sectors && sectors != "") {
          sectors = JSON.parse(sectors);
          return sectors;
        }
      } catch (err) {
        console.log("err", err);
        sectors = ["ALL"];
        return sectors;
      }
    }
    const dummyDataFunction = async () => {
        try {
            const data = {
                "data": dummydata
            }
            if (data?.data) {
                setLoading(false);
                setPodcast(data?.data?.podcasts || []);
                setPager(data?.data?.page_info || []);
                setFilterData(data?.data?.filters || []);
            } else {
                setLoading(false);
                setPodcast([])
            }
        }
        catch (e) {
            setLoading(false);
            setPodcast([])
        }
    }
    const makeFetchAudioListCall = async () => {
        setLoading(true);
        // dummyDataFunction();
        // return;
        let shareKey = queryString.parse(props.location.search, { ignoreQueryPrefix: true })['id'];
        if (shareKey) {
            var mask = {
                podcast_filter: {
                    podcast_mask: [shareKey]
                },
            }
        }

        let session = await getSession();
        if (!session) {
            props.toastify.fire("Something went wrong. Please try again later.", DU_TOAST_ID, TOAST_TYPE_ERROR);
            return;
        }
        try {
            let sectors = [];
            const cognito_sectors = getSectors();
            if (cognito_sectors?.length == 1) {
                if (cognito_sectors[0] != "ALL") {
                    sectors = cognito_sectors
                }
            }
            else {
                sectors = cognito_sectors
            }
            if (sectors?.length > 0) {
                const payload1Sectors = filter?.sector_filter?.sectors;
                const payload3Sectors = cognito_sectors
                const commonElements = [];
                if (payload1Sectors && payload1Sectors?.length > 0) {
                    const commonElements1 = payload1Sectors.filter(sector => payload3Sectors.includes(sector));
                    commonElements.push(...commonElements1);
                }
                if (commonElements.length === 0) {
                    sectors = payload3Sectors;
                } else {
                    sectors = commonElements;
                }
            }
            //for all permission
            if (cognito_sectors?.length == 1) {
                if (cognito_sectors[0] == "ALL") {
                    const payload1Sectors = filter?.sector_filter?.sectors;
                    sectors.push(...payload1Sectors)
                }
            }
            let inputData = {
                data: {
                    ...filter,
                    sector_filter: {
                        sectors: sectors
                    },
                    ...mask
                }
            };
            inputData = JSON.stringify(inputData)
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `/podcasts`,
                headers: {
                    'Authorization': session.accessToken.jwtToken,
                    'Content-Type': 'application/json'
                },
                data: inputData
            };
            const { data } = await common.request(config);
            if (data?.data) {
                setLoading(false);
                setPodcast(data?.data?.podcasts || []);
                setPager(data?.data?.page_info || []);
                setFilterData(data?.data?.filters || []);
            } else {
                setLoading(false);
                setPodcast([])
            }
        }
        catch (e) {
            setLoading(false);
            setPodcast([])
        }
    }
    const reseteFilter = () => {
        try {
            //only reset left panle
            setpreviousSelectedFilter({
                "author_names": [],
                "stocks": [],
                "sectors": [],
            });
            setSelectedPodcast(null)
            const payload = toJS(props.store.filters);
            let payload2 = toJS(props.store.filterPanel);
            let payload3 = _.merge(payload, payload2);
            setFilter({
                ...filter,
                stock_filter: {
                    stocks: []
                },
                sector_filter: {
                    sectors: []
                },
                author_filter: {
                    author_names: []
                },
                ...payload3
            })
            // props.store.resetFilters();
        }
        catch (err) {
            console.log("err", err)
        }
    }

    /**
     * @desc Calls the asynchronous method 'fetchResults', it is a wrapper method which
     * relieves the caller from the hassles of handling promises.
     */
    const makeFetchResultsApiCall = () => {
        props.store.setResultViewer(true, null);
        props.store.setSnippets(null, true);
        props.store.setPreviewActive(false);

        const payload = toJS(props.store.filters);
        let payload2 = toJS(props.store.filterPanel);
        let payload3 = _.merge(payload, payload2);
        // reallocating values due to reset by merging
        payload3['from'] = toJS(props.store.filters)['from'];
        payload3['sort'] = toJS(props.store.filters)['sort'];
        payload3['date_filter'] = toJS(props.store.filters)['date_filter'];

        setFilter({
            ...filter,
            ...payload3,
            date_filter: {
                from_date: payload3?.date_filter?.lower_bound,
                to_date: payload3?.date_filter?.upper_bound
            }
        })
    };
    const onPrevSelect = () => {
        const index = findIndex(podcast, { ID: selectedPodcast?.ID })
        if (index - 1 >= 0) {
            setSelectedPodcast(podcast[index - 1])
        }
    }

    const onNextSelect = () => {
        const index = findIndex(podcast, { ID: selectedPodcast?.ID })
        if (index + 1 < podcast.length) {
            setSelectedPodcast(podcast[index + 1])
        }
    }
    const getStreamUrlApiCall = async (audioTitle) => {
        let session = await getSession();
        let response;
        let endpoint = `${ENDPOINT_PODCASTS_GET_URL}/${audioTitle}`;
        try {
            response = await common.get(endpoint,
                {
                    params: {},
                    headers: { "Authorization": session.accessToken.jwtToken }
                }
            );
            console.log("response", response.data['data'])
        }
        catch (e) {
            console.log("error", e)
        }
    }
    const [modal, setModal] = useState(false);
    const [shareURL, setShareURL] = useState(null);

    const copyLink = () => {
        try {
            let textInput = document.getElementById("document-share-link");
            textInput.select();
            document.execCommand('copy');
            props.toastify.fire(MSG_COPIED_CLIPBOARD, DS_TOAST_ID, TOAST_TYPE_SUCCESS);
        } catch (e) {
            console.error(e);
            props.toastify.fire(MSG_NOT_COPIED_CLIPBOARD, DS_TOAST_ID, TOAST_TYPE_ERROR);
        }
    };

    const toggle = () => {
        setModal(!modal);
    };

    const show = () => {
        setModal(true);
        setTimeout(() => copyLink(), 100);
    };

    // useEffect(() => {
    //     let shareKey = queryString.parse(props.location.search, { ignoreQueryPrefix: true })['id'];
    //     if (shareKey) {
    //         setFilter({
    //             ...filter,
    //             podcast_filter: {
    //                 podcast_mask: [shareKey]
    //             },
    //         })
    //     }
    // }, [props.location.search]);

    return (
        <section className='px-xl-5 px-2 as_podcast-container'>
            {/* <div className={`${selectedPodcast ? 'd-none' : ''} d-lg-block`}>
                <div className="row py-3 d-flex">
                    <div className='col-lg-7 col-sm-6'>
                        <div className='search-input-wrap'>
                            <input
                                type='text'
                                value={search_value}
                                className="search-input"
                                placeholder="Search podcasts by name"
                                onChange={(e) => setSearch_value(e.target.value)}
                            />
                            <button className='px-2'
                                onClick={() => onSearch()}
                            >
                                <FontAwesomeIcon className="text-white"
                                    icon="check-circle"
                                /><span className='ml-2'>SEARCH</span>
                            </button>
                            <div className='ml-2'>
                                <button
                                    className='advanch-search-btn px-3 py-3'
                                >
                                    <FontAwesomeIcon icon="sliders-h" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5 mt-sm-0 mt-3 col-sm-6 col-12 d-flex'>
                        <div style={{ flex: 1 }}>
                            <DateRangeModule
                                startDate={filter.date_filter.from_date}
                                endDate={filter.date_filter.to_date}
                                dateFormat="dd-MMM-yyyy"
                                startDateMin={new Date('2001-01-01')}
                                startDateMax={new Date()}
                                endDateMin={new Date('2021-01-01')}
                                endDateMax={new Date()}
                                onDateChange={(startDate, endDate, isDateRangeValid) => {
                                    // if (isDateRangeValid) {
                                    setFilter({
                                        ...filter,
                                        date_filter: {
                                            from_date: moment(startDate).format("YYYY-MM-DD"),
                                            to_date: moment(endDate).format("YYYY-MM-DD")
                                        },
                                    })
                                    // }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className={`container-fluid ${selectedPodcast ? 'd-lg-block d-none' : 'd-block'}`}>
                <div>
                    <div className="py-3">
                        <div>
                            <PodcastSearchFilters
                                sectors={[]}
                                ref={docSearchFiltersRef}
                                store={props?.store}
                                auth={props?.auth}
                                suggestionsApiUrl={ENDPOINT_GET_PODCAST_SUGGESTIONS}
                                page="document_search"
                                tab="all"
                                makeFetchResultsApiCall={makeFetchResultsApiCall}
                                resetFilterValues={reseteFilter}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-lg-3 d-none d-lg-block pt-4 filter-container'>

                        <PodcastFilter
                            setSelectedFilter={setSelectedFilter}
                            selectedFilter={selectedFilter}
                            reseteFilter={reseteFilter}
                            filterData={filterData || []}
                            apiCall={apiCall}
                        />

                    </div>
                    <div className={`d-${selectedPodcast ? 'none' : 'block'} d-lg-block col-md-12 col-lg-${selectedPodcast ? '6' : '9'}`}>
                        <PodcastItem
                            setSelectedPodcast={setSelectedPodcast}
                            selectedPodcast={selectedPodcast}
                            podcast={podcast}
                            pager={pager}
                            filter={filter}
                            setFilter={setFilter}
                            setIsPlaying={setIsPlaying}
                            setModal={setModal}
                            setShareURL={setShareURL}
                        />
                    </div>
                    {
                        (selectedPodcast) && (
                            <>
                                <div className='d-lg-none pt-5 pb-3 d-flex col-12'>
                                    <button onClick={() => setSelectedPodcast(null)} className='back-to-button px-3'><FontAwesomeIcon icon="fa-solid fa-angle-left" /><span className='ml-2'>Go back to product list</span></button>
                                </div>
                                <div className='col-lg-3 col-md-10 col-12 px-0 audio-container d-flex justify-content-center'>
                                    <div className='w-100'>
                                        <NewAudioPlayer
                                            isPlaying={isPlaying}
                                            className="faded-background"
                                            syncPlayingState={(s) => setIsPlaying(s)}
                                            streamLink={selectedPodcast?.ORIGINAL_URL}
                                            title={selectedPodcast?.TITLE}
                                            handlePrev={() => onPrevSelect()}
                                            handleNext={() => onNextSelect()}
                                            options={{
                                                autoplay: true,
                                                tooltips: { controls: false, seek: true },
                                                controls: ['play-large', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings',]
                                            }}
                                        />
                                    </div>

                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
                className="share-dialog"
                backdrop={props.backdrop}
            >
                {/* Using custom header here as <Modalheader/> component injects <h5/> automatically */}
                <div className="dialog-header">
                    <FontAwesomeIcon className="share-icon" icon="share" />
                    <span className="title">{"Podcast Link"}</span>
                </div>

                <ModalBody className="dialog-body">
                    <FormGroup>
                        <Label className="input-label" for="document-share-link">Link</Label>
                        <Input className="input-field" value={shareURL} readOnly type="text" name="city" id="document-share-link" />
                    </FormGroup>
                </ModalBody>

                <ModalFooter className="dialog-footer">
                    <Button onClick={toggle} className="action-btn cancel">Cancel</Button>
                    <Button onClick={copyLink} className="action-btn share">Copy Link</Button>
                </ModalFooter>
            </Modal>
        </section>
    )
}
export default observer(PodcastNew);
