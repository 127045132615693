import React, { Component, Fragment } from "react";
import "./style.scss";
import { ERRORS } from "./errorMessages";
import Validate from "../../utils/FormValidation";
import {
  Button,
  Form,
  FormGroup,
  Spinner,
  Input,
  Label,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { observer } from "mobx-react";
import ResponseModal from "../../components/ResponseModal";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { allCountryCodesWithFlags } from "../../constants/arrays";

const RESTRICTED_DOMAINS =
  process.env.REACT_APP_RESTRICTED_EMAIL_DOMAINS.split(",");
class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCode: "",
      formInputs: {
        name: {
          value: "",
          invalid: false,
          error_message: "",
          required: true,
        },
        company: {
          value: "",
          invalid: false,
          error_message: "",
          required: true,
        },
        contact: {
          value: "",
          invalid: false,
          error_message: "",
          required: false,
        },
        email: {
          value: "",
          invalid: false,
          error_message:
            "Please provide a valid email. Invalid format or domain is not supported.",
          required: true,
        },
      },
      isLoading: false,
      resendMailInProgress: false,
      isSubmittingTermsAndConditions: false,
      isResponseModalOpen: false,
      acceptTerms: false,
      termsConditions: {
        isActive: false,
        title: "",
        content: "",
      },
      responseModal: {
        status: "",
        title: "",
        text: "",
        buttonText: "",
        functionalBtn: {},
      },
      errors: {
        cognito: null,
      },
    };

    this.countryCodeSelect = React.createRef();
  }

  componentDidMount() {
    // Timeout to prevent race condition from Login page
    setTimeout(() => {
      this.props.amplitudeAnalytics.visit("Signup");
    }, 1000);
  }

  /**
   * Clears any error validation states so as to return back the component to normal state
   */
  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        nameInvalid: false,
        companyInvalid: false,
        contactInvalid: false,
        emailInvalid: false,
      },
    });
  };

  // Validates entire form and returns a boolean value
  checkValidForm(formInputs) {
    let validForm = true;

    for (let key in formInputs) {
      if (
        formInputs.hasOwnProperty(key) &&
        formInputs[key].invalid &&
        formInputs[key].required
      ) {
        validForm = false;
        break;
      }
    }

    return validForm;
  }

  // Callback for click event on Submit Button
  handleSubmit = (event) => {
    event.preventDefault();

    // Do not submit until terms and conditions are accepted
    if (this.state.termsConditions.isActive) {
      if (!this.state.acceptTerms) return;

      this.setState({
        isSubmittingTermsAndConditions: true,
        isLoading: true,
      });
    } else {
      this.setState({ isLoading: true });
    }

    this.clearErrorState();

    const formInputs = Validate("signUp", this.state);

    if (!this.checkValidForm(formInputs)) {
      this.setState({ isLoading: false, formInputs });
      return;
    }

    this.signUp();
  };

  //#region Sign Up
  makeSignUpApiCall(url, payload) {
    return axios.post(url, payload);
  }

  signUp() {
    const { countryCode, formInputs, acceptTerms } = this.state;

    let payload = {
      user_name: formInputs.name.value,
      company_name: formInputs.company.value,
      email: formInputs.email.value.toLowerCase(),
      terms_conditions: acceptTerms,
    };

    if (formInputs.contact.value && formInputs.contact.value.length) {
      const phone_number = countryCode.length
        ? `${countryCode}${formInputs.contact.value}`
        : `${allCountryCodesWithFlags[0].dial_code}${formInputs.contact.value}`;
      payload.phone_number = phone_number;
    }

    this.makeSignUpApiCall(
      `${process.env.REACT_APP_LIB_DOMAIN}/signup`,
      payload
    )
      .then(this.onSignupSuccess)
      .catch(this.onSignupError);
  }

  onSignupSuccess = (signUpResponse) => {
    let { acceptTerms, formInputs } = this.state;
    const termsConditions = { ...this.state.termsConditions };

    let status = "info";
    let heading = "Info";
    let functionalBtn = null;
    let buttonText = "OKAY";
    let message = ERRORS.MSG_STH_WENT_WRONG;

    if (signUpResponse.status === 200 && signUpResponse.data) {
      if (signUpResponse.data.status) {
        // After Terms and Conditions
        if (signUpResponse.data.data.response_type) {
          switch (signUpResponse.data.data.response_type) {
            case "success":
              status = "success";
              heading = "Awesome!";
              message = signUpResponse.data.data.content;
              break;
            case "info":
              status = "info";
              heading = "Message from Avendus Spark";
              message =
                "Your credentials are in the process of being verified. You will hear back from us shortly ";
              break;
            default:
              break;
          }

          acceptTerms = true;
          termsConditions.title = "";
          termsConditions.content = "";
          termsConditions.isActive = false;
        }

        // Terms and Conditions
        else {
          status = "success";
          acceptTerms = false;
          termsConditions.title = signUpResponse.data.data.title;
          termsConditions.content = signUpResponse.data.data.content;
          termsConditions.isActive = true;
        }
      } else {
        if (signUpResponse.data.error_code) {
          switch (true) {
            case signUpResponse.data.error_code >= 900:
              message = signUpResponse.data.error_message; // if starting with 9 then display response as it is

              if (signUpResponse.data.error_code === 901) {
                // if mail already sent then display a warning response with a link on popup
                status = "warning";
                buttonText = "";
                heading = "Mail already sent";
                functionalBtn = {
                  onClickHandler: () => {
                    this.resendMail(formInputs.email.value.toLowerCase());
                  },
                  text: "Resend Link",
                  className: "resend-mail-btn",
                };
              }
              break;
            case signUpResponse.data.error_code === 103:
              status = "warning";
              buttonText = "GO BACK";
              break;
            default:
              status = "error";
              message = ERRORS.MSG_UNKNOWN_USER;
              buttonText = "TRY AGAIN";
              break;
          }
        } else {
          status = "error";
          message =
            signUpResponse.data.error_message || ERRORS.MSG_STH_WENT_WRONG;
        }

        acceptTerms = false;
        termsConditions.title = "";
        termsConditions.content = "";
        termsConditions.isActive = false;
      }
    }

    this.setState({
      acceptTerms,
      termsConditions,
      isLoading: false,
      isSubmittingTermsAndConditions: false,
    });

    /** If user has not yet accepted terms and conditions and the response is success then don't
     * show the popup message (instead we show terms and conditions modal in this case with
     * the content and title from response)
     **/

    if (!acceptTerms && status === "success") {
      return;
    }

    this.showPopUpMessageModal(
      status,
      heading,
      message,
      buttonText,
      functionalBtn
    );
  };

  onSignupError = (error) => {
    let heading = "Oops!";
    let status = "warning";
    let buttonText = "TRY AGAIN";
    let message = ERRORS.MSG_SERVER_DOWN;

    if (error.hasOwnProperty("status")) {
      if (error.status >= 400 && error.status < 500) {
        if (
          error.hasOwnProperty("data") &&
          error.data.hasOwnProperty("error_message")
        ) {
          message = error.data.error_message;
        }
      }
    }

    console.error(error);

    this.showPopUpMessageModal(status, heading, message, buttonText, null);

    this.setState({
      isLoading: false,
      isSubmittingTermsAndConditions: false,
    });
  };
  //#endregion

  //#region Resend Mail
  makeResendMailAPICall(url, payload) {
    return axios.post(url, payload);
  }

  resendMail(email) {
    this.setState({ resendMailInProgress: true });
    this.makeResendMailAPICall(
      `${process.env.REACT_APP_LIB_DOMAIN}/resend_email`,
      { email }
    )
      .then(this.onResendMailSuccess)
      .catch(this.onResendMailError);
  }

  onResendMailSuccess = (resendMailResponse) => {
    if (
      resendMailResponse.status === 200 &&
      resendMailResponse.data &&
      resendMailResponse.data.status
    ) {
      this.showPopUpMessageModal(
        "success",
        "Mail sent!",
        ERRORS.MSG_RESEND_MAIL_SUCCESS,
        "OKAY"
      );
    }

    this.setState({ resendMailInProgress: false });
  };

  onResendMailError = (error) => {
    console.error(error);

    this.showPopUpMessageModal(
      "warning",
      "Oops!",
      ERRORS.MSG_RESEND_MAIL_ERROR,
      "TRY AGAIN"
    );

    this.setState({ resendMailInProgress: false });
  };
  //#endregion

  /**
   * It changes popup modal state to render popup messages according to the configuration passed into the function
   *  @param { string } type - Modal type (one of 'success', 'error', 'warning', 'info')
   *  @param { string } title - Title you want displayed on header of the modal
   *  @param { string } text - Text you want displayed on header of the modal
   *  @param { string } buttonText - text to be displayed on the close modal action button
   *  @param { object } functionalBtn - The link object
   */
  showPopUpMessageModal = (
    type,
    title,
    text,
    buttonText,
    functionalBtn = null
  ) => {
    const responseModal = { ...this.state };

    responseModal.type = type;
    responseModal.title = title;
    responseModal.text = text;
    responseModal.buttonText = buttonText;
    responseModal.functionalBtn = functionalBtn;

    this.setState({
      isResponseModalOpen: true,
      responseModal,
    });
  };

  /**
   * Validates contact number against prescribed format
   */
  validateContact = (contact) => {
    // const regex = new RegExp(/([+]?\d{1,2}[.-\\s]?)?(\d{3}[.-]?) {2}\d{4}/);
    const regex = new RegExp(/\d{4,12}$/);

    return regex.test(contact);
  };

  /**
   * Validates email address against prescribed format and restricted domains
   */
  validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      return false;
    }
    const domain = email.split("@")[1].split(".")[0];
    console.log(domain);
    return !RESTRICTED_DOMAINS.includes(domain);
  };

  /**
   * Handles Input changes on all fields
   * and updates state according to the name attribute present on target element
   */
  onInputChange = (event) => {
    const formInputs = { ...this.state.formInputs };

    formInputs[event.target.name].value = event.target.value;

    switch (event.target.name) {
      case "name":
      case "company":
        if (event.target.value.length) {
          formInputs[event.target.name].invalid = false;
          formInputs[event.target.name].error_message = "";
        }
        break;
      case "contact":
        if (!this.validateContact(event.target.value)) {
          formInputs[event.target.name].invalid = true;
          formInputs[event.target.name].error_message =
            "Please provide a valid contact number.";
        } else {
          formInputs[event.target.name].invalid = false;
          formInputs[event.target.name].error_message = "";
        }
        break;
      case "email":
        if (!this.validateEmail(event.target.value.toLowerCase())) {
          formInputs[event.target.name].invalid = true;
          formInputs[event.target.name].error_message =
            "Please provide a valid email. Invalid format or domain is not supported.";
        } else {
          formInputs[event.target.name].invalid = false;
          formInputs[event.target.name].error_message = "";
        }
        break;
      default:
        break;
    }

    this.setState({ formInputs });
  };

  // Callback for Key Press event on Form Inputs
  onKeyPress = (e) => {
    if (e.key === "Enter") {
      // this.handleSubmit(e).then(() => {});
    }
  };

  // Callback for click event on Action Button in Response Modal
  onActionButtonClick = () => {
    this.setState({
      isResponseModalOpen: false,
    });
  };

  // Toggles terms and conditions modal to be visible or hidden
  toggleTermsAndConditionsModal = () => {
    this.setState({
      termsConditions: {
        isActive: !this.state.termsConditions.isActive,
        ...this.state.termsConditions,
      },
    });
  };

  // Toggles accept terms and conditions checkbox states on change
  onCheckboxChange = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  onCountryDropdownSelect = (e) => {
    const countryCode = RegExp(/\+(.*)/).exec(e.target.value)[0];
    this.setState({ countryCode });
  };

  getOptionsForCountryCodeSelect() {
    let options = [];

    allCountryCodesWithFlags.forEach((elem, index) => {
      const classNameStr = `country-option ${elem.code}`;
      options.push(
        <option className={classNameStr} key={`${elem.code}-${index}`}>
          {elem.dial_code}
        </option>
      );
    });

    return options;
  }

  render() {
    const {
      formInputs,
      responseModal,
      isResponseModalOpen,
      termsConditions,
      acceptTerms,
      isSubmittingTermsAndConditions,
      isLoading,
    } = this.state;

    const countryCodeOptionsJSX = this.getOptionsForCountryCodeSelect();

    return (
      <Fragment>
        <Form>
          <FormGroup>
            <div className="label-container">
              <span className="field-label">Name</span>
            </div>
            <Input
              type="text"
              name="name"
              placeholder=""
              onChange={this.onInputChange}
              onKeyPress={this.onKeyPress}
              invalid={formInputs.name.invalid}
            />
            <FormFeedback>{formInputs.name.error_message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <div className="label-container">
              <span className="field-label">Organization</span>
            </div>
            <Input
              type="text"
              name="company"
              placeholder=""
              onChange={this.onInputChange}
              onKeyPress={this.onKeyPress}
              invalid={formInputs.company.invalid}
            />
            <FormFeedback>{formInputs.company.error_message}</FormFeedback>
          </FormGroup>
          <div className="inline-form-groups d-flex align-items-end justify-content-between">
            <FormGroup>
              <div className="label-container">
                <span className="field-label">Contact Number</span>
              </div>
              <Input
                type="select"
                name="select"
                id="countryCodeSelect"
                onChange={this.onCountryDropdownSelect}
                className="country-code-select"
                ref={this.countryCodeSelect}
              >
                {countryCodeOptionsJSX}
              </Input>
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="contact"
                placeholder=""
                onChange={this.onInputChange}
                onKeyPress={this.onKeyPress}
                invalid={formInputs.contact.invalid}
              />
              <FormFeedback>{formInputs.contact.error_message}</FormFeedback>
            </FormGroup>
          </div>
          <FormGroup>
            <div className="label-container">
              <span className="field-label">E-mail</span>
            </div>
            <Input
              type="email"
              name="email"
              placeholder="example@abc.com"
              onChange={this.onInputChange}
              onKeyPress={this.onKeyPress}
              invalid={formInputs.email.invalid}
            />
            <FormFeedback>{formInputs.email.error_message}</FormFeedback>
          </FormGroup>

          {/* <div style={{ margin: '-5px 0 -2px 0' }}>
                        <Link className='resend-mail-link' to='/resendmail'> Resend E-mail </Link>
                        <FontAwesomeIcon
                            className='email-info'
                            icon='info-circle'
                            id='email-info-popover'
                        />
                        <UncontrolledPopover trigger='legacy' placement='bottom' target='email-info-popover'>
                            <PopoverBody>
                                If you didn't complete the sign up process by generating a new password for your account then click on this link to receive a new mail again.
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div> */}

          <Button
            style={{
              backgroundColor: "#000",
              marginTop: "0.2rem",
              padding: "0.8rem",
              width: "50%",
              borderRadius: "2rem",
            }}
            onClick={this.handleSubmit}
          >
            {!isLoading ? "SIGNUP" : ""}
            {isLoading && <Spinner size="sm" color="white" />}
          </Button>

          <div style={{ margin: "-5px 0 -2px 0" }}>
            <Link className="back-to-login-link" to="/login">
              {" "}
              Go back to Login{" "}
            </Link>
          </div>
        </Form>

        {/* Response Modal */}
        {this.state.isResponseModalOpen && (
          <ResponseModal
            modal={isResponseModalOpen}
            backdrop={true}
            modalClass="response-modal"
            titleClass={"title " + responseModal.type}
            modalTitle={responseModal.title}
            textClass="text"
            modalText={responseModal.text}
            buttonClass={"action-button " + responseModal.type}
            buttonText={responseModal.buttonText}
            onClickAction={this.onActionButtonClick}
            functionalBtn={responseModal.functionalBtn}
            isBtnLoading={this.state.resendMailInProgress}
            type={responseModal.type}
          />
        )}

        {/* Terms and Conditions Modal */}
        {termsConditions.isActive && (
          <Modal
            centered={true}
            isOpen={termsConditions.isActive}
            toggle={this.toggleTermsAndConditionsModal}
            className="terms-n-conditions-modal"
            backdrop="static"
            scrollable={true}
          >
            <ModalHeader>{termsConditions.title}</ModalHeader>
            <ModalBody>{ReactHtmlParser(termsConditions.content)}</ModalBody>
            <ModalFooter>
              <div className="accept-terms-conditions-container">
                <Label>
                  <Input
                    type="checkbox"
                    name="acceptTerms"
                    checked={acceptTerms}
                    onChange={this.onCheckboxChange}
                  />
                  <span>I have read and accept the terms of service</span>
                </Label>
              </div>
              <Button
                color="primary"
                disabled={!acceptTerms}
                onClick={this.handleSubmit}
              >
                {!isSubmittingTermsAndConditions ? (
                  "Submit"
                ) : (
                  <Spinner size="sm" color="white" />
                )}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default observer(Signup);
