import React, { Component, Fragment } from 'react';
import Validate from "../../utils/FormValidation";
import { Button, Form, FormGroup, Spinner, Input, FormFeedback } from 'reactstrap';
import { observer } from 'mobx-react';
import ResponseModal from '../../components/ResponseModal';
import axios from "axios";
import { Link } from 'react-router-dom';

class ResendMail extends Component {
    state = {
        email: "",
        isLoading: false,
        isModalOpen: false,
        modal: {
            status: "",
            title: "",
            text: "",
            buttonText: "",
        },
        errors: {
            cognito: null,
            emailInvalid: false,
        }
    };

    /**
     * clears any error validation states so as to return back the component to normal state 
     */
    clearErrorState = () => {
        this.setState({
            errors: {
                cognito: null,
                emailInvalid: false,
            }
        });
    };

    /**
     * this function handles the click on Submit button
     * It calls Validate function to check the validity of the inputs and takes action accordingly
     * It also calls the API which signs up the user on our platform
     */
    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });
        // Form validation
        this.clearErrorState();
        const errors = Validate('resendMail', this.state);
        if (Object.keys(errors).length) {
            this.setState({
                errors: { ...errors },
                isLoading: false
            });
            return;
        }

        const { email } = this.state;
        let status = false;
        try {
            const params = {
                email: email,
            };
            let url = process.env.REACT_APP_LIB_DOMAIN + '/resend_email';
            let resendMailResponse = await axios.post(url, params);
            console.log(resendMailResponse);
            if (resendMailResponse && resendMailResponse.hasOwnProperty('status') && resendMailResponse.status === 200 && resendMailResponse.data && resendMailResponse.data.status) {
                status = true;
            }
        } catch (error) {
            // TODO: add if condition for error response
            console.log(error);
        }

        if (status) {
            this.showPopUpMessageModal(
                'success',
                'Mail sent!',
                "Please generate a new password using the link sent to your E-mail.",
                "OKAY"
            );
        } else {
            this.showPopUpMessageModal(
                'warning',
                'Oops!',
                "Sorry, our system does not recognize you. Please try signing up with us again to generate a new password or contact your account manager.",
                "TRY AGAIN"
            );
        }
        this.setState({ isLoading: false });
    };

    /**
     * It changes popup modal state to render popup messages according to the configuration passed into the function
     *  @param { status } ['error', 'warning', 'success']
     *  @param { modalTitle } , title you want displayed on header of the modal
     *  @param { modalText }, text to be displayed inside the modal
     *  @param { buttonText }, text to be displayed on the close modal action button
     */
    showPopUpMessageModal = (status, modalTitle, modalText, buttonText, linkObj) => {
        this.setState({
            isModalOpen: true,
            modal: {
                status: status,
                title: modalTitle,
                text: modalText,
                buttonText: buttonText,
                linkObj: linkObj
            }
        });
    };

    /**
     * Handles Input changes on all fields 
     * and updates state according to the name attribute present on target element 
     */
    onInputChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onKeyPress = (e) => {
        console.log(e)
        if (e.key === 'Enter') {
            this.handleSubmit(e);
        }
    }

    onActionButtonClick = () => {
        this.setState({
            isModalOpen: false
        });
    }

    render() {
        return (
            <Fragment>
                <Form>
                    <FormGroup>
                        <div className="label-container">
                            <span className="field-label">E-mail</span>
                        </div>
                        <Input
                            type="text"
                            name="email"
                            placeholder="example@abc.com"
                            onChange={this.onInputChange}
                            onKeyPress={this.onKeyPress}
                            invalid={this.state.errors.emailInvalid}
                        />
                        <FormFeedback>
                            {this.state.errors.emailInvalid ? 'Please Enter Valid E-mail ID' : ''}
                        </FormFeedback>
                    </FormGroup>
                    <Button
                        style={{
                            backgroundColor: "#000",
                            padding: "0.6rem",
                            width: "70%",
                            borderRadius: "2rem",
                            marginTop: "20px",
                        }}
                        onClick={this.handleSubmit}
                    >
                        {!this.state.isLoading ? "Resend" : ""}
                        {this.state.isLoading && <Spinner size="sm" color="white" />}
                    </Button>
                    <div
                        style={{ margin: "-5px 0 -2px 0" }}>
                        <Link className="back-to-login-link" to="/login"> Go back to Login </Link>
                    </div>
                </Form>
                {
                    this.state.isModalOpen &&
                    <ResponseModal
                        modal={this.state.isModalOpen}
                        backdrop='static'
                        modalClass="response-modal"
                        titleClass={"title " + this.state.modal.status}
                        modalTitle={this.state.modal.title}
                        textClass="text"
                        modalText={this.state.modal.text}
                        buttonClass={"action-button " + this.state.modal.status}
                        buttonText={this.state.modal.buttonText}
                        onClickAction={this.onActionButtonClick}
                        linkObj={this.state.linkObj}
                        type={this.state.modal.status}
                    />
                }
            </Fragment>
        );
    }
}

export default observer(ResendMail);
