import React, { Component, Fragment, useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import "./style.scss";
const url = `https://zohoapi.sparknewsletter.in/api`;
const axios = require('axios');
// const url = `http://localhost:8000`;
const ComplaintRegister = (props) => {
    const [formData, setFormData] = useState({
        ucc: '',
        contactNumber: '',
        emailAddress: '',
        complaintDetails: '',
        ticketNumber: ''
    });
    const [mode, setMode] = useState('register');
    const [ticketStatus, setTicketStatus] = useState(null);
    const [ticket, setTicket] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };
    useEffect(() => {
        setTicketStatus(null);
        setFormData({
            ucc: '',
            contactNumber: '',
            emailAddress: '',
            complaintDetails: '',
            ticketNumber: ''
        })
    }, [mode]);

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const onSubmit = async () => {
        if (mode === 'register') { 
            const data = {
                ucc: formData.ucc,
                email: formData.emailAddress,
                phone: formData.contactNumber,
                complaint_details: formData.complaintDetails,
            };

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${url}/zoho/createTicket`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            try {
                await axios.request(config)
                    .then((response) => {
                        setTicket(response?.data?.output);
                        openModal()
                        setFormData({
                            ucc: '',
                            contactNumber: '',
                            emailAddress: '',
                            complaintDetails: '',
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.success(error);
                    });
            }
            catch (error) {
                console.error(error);
                toast.error(error.message || 'An error occurred');
            }
        } else if (mode === 'status') {
            let config = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: `${url}/zoho/getTicketByTicketId/${formData.ticketNumber}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            try {
                await axios.request(config)
                    .then((response) => {
                        const data = response.data?.output;
                        console.log("data", data)
                        if (data?.status) {
                            setTicketStatus(data?.status);
                        }
                        else {
                            toast.error("Ticket Not Found.")
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            catch (error) {
                console.error(error);
                toast.error(error.message || 'An error occurred');
            }

        }
    }

    return (
        <div className="document-upload">
            <div className="upload-card">
                <div className="card-header">
                    <div className="radio-buttons p-2">
                        <Label check>
                            <Input
                                type="radio"
                                name="mode"
                                checked={mode === 'register'}
                                onChange={() => setMode('register')}
                            />
                            {' Register Complaint'}
                        </Label>
                        <Label check className='ml-5'>
                            <Input
                                type="radio"
                                name="mode"
                                checked={mode === 'status'}
                                onChange={() => setMode('status')}
                            />
                            {'Complaint Status'}
                        </Label>
                    </div>
                </div>
                <div className="card-body mt-2">
                    <Form style={{ height: '100%' }}>
                        {mode === 'register' && (
                            <div>
                                <FormGroup>
                                    <Label for="ucc">UCC*</Label>
                                    <Input
                                        type="text"
                                        name="ucc"
                                        value={formData.ucc}
                                        onChange={onInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="contactNumber">Contact Number*</Label>
                                    <Input
                                        type="text"
                                        name="contactNumber"
                                        value={formData.contactNumber}
                                        onChange={onInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="emailAddress">Email Address*</Label>
                                    <Input
                                        type="text"
                                        name="emailAddress"
                                        value={formData.emailAddress}
                                        onChange={onInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="complaintDetails">Complaint Details*</Label>
                                    <Input
                                        type="text"
                                        name="complaintDetails"
                                        value={formData.complaintDetails}
                                        onChange={onInputChange}
                                    />
                                </FormGroup>
                            </div>
                        )}
                        {mode === 'status' && (
                            <FormGroup>
                                <Label for="ticketNumber">Ticket Number*</Label>
                                <Input
                                    type="text"
                                    name="ticketNumber"
                                    value={formData.ticketNumber}
                                    onChange={onInputChange}
                                />
                            </FormGroup>
                        )}
                        <Button className="submit-button" size="sm" onClick={onSubmit}>
                            {"Submit"}
                        </Button>
                        {ticketStatus && (
                            <div className="mt-3">
                                <strong>Ticket Status:</strong> {ticketStatus}
                            </div>
                        )}
                    </Form>
                </div>
            </div>
            <Modal
                centered={true}
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                backdrop='static'
                scrollable={true}
            >
                <ModalHeader className='d-flex align-items-center'>
                    Ticket Created
                </ModalHeader>
                <ModalBody className='d-flex flex-column align-items-center'>
                    <div>
                        <div
                            style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                            }}
                        >
                            <h5>{`Your complaint with ticket number`}<strong> {ticket?.ticketNumber}</strong> {`has been successfully registered.`}</h5>
                            <br />
                            <Button
                                color='primary'
                                onClick={closeModal}
                            >
                                {"OK"}
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}
export default observer(ComplaintRegister);

