import React, { Component, Fragment } from "react";
import Plyr from 'plyr';
import './style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class DocumentAudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.audioElement = React.createRef();
    }

    componentDidMount() {
        this.initPlayerObj();
    }

    componentDidUpdate(prevProps) {
        // Check if the streamLink has changed
        if (this.props.streamLink !== prevProps.streamLink) {
            this.handleStreamLinkChange();
        }

        // Check if the player is playing and should be paused
        if (this.player && !this.props.isPlaying && this.player.playing) {
            this.player.pause();
        }
    }

    initPlayerObj() {
        this.player = new Plyr(this.audioElement.current, { ...this.props.options });
        window.player = this.player;
        this.handleStreamLinkChange();
    }

    handleStreamLinkChange() {
        // Pause the player if it's playing
        if (this.player && this.player.playing) {
            this.player.pause();
        }

        // Set the new stream link
        this.audioElement.current.src = this.props.streamLink;

        // Load and play the audio if autoplay is true
        if (this.props.options.hasOwnProperty("autoplay") && this.props.options.autoplay) {
            this.audioElement.current.load();
            this.audioElement.current.play();
            this.props.syncPlayingState(true);
        }
    }

    handlePlayPause = () => {
        if (this.props.isPlaying) {
            this.audioElement.current.pause();
        } else {
            this.audioElement.current.play();
        }
        this.props.syncPlayingState(!this.props.isPlaying);
    }

    render() {
        return (
            !!this.props.streamLink &&
            <Fragment >
                <div
                    style={{
                        marginTop: 10
                    }}
                    className="podcast-player document-search-player">
                    <div className="podcast-image">
                        <div className="audio-image"></div>
                    </div>
                    <div className={this.props.className ? `audio-player ${this.props.className}` : "audio-player"}>
                        <audio ref={this.audioElement} controls>
                            <source src={`${this.props.streamLink}`} />
                        </audio>
                        <div className="additional-controls">
                            {/* <button className="prev-btn" onClick={this.props.handlePrev} title="Prev">
                                <FontAwesomeIcon icon="step-backward" />
                            </button> */}

                            <button className="play-pause-btn" onClick={this.handlePlayPause} title="Play/Pause">
                                {this.props.isPlaying ?
                                    <FontAwesomeIcon icon={['far', 'pause-circle']} />
                                    :
                                    <FontAwesomeIcon icon={['far', 'play-circle']} />
                                }
                            </button>
                            {/* 
                            <button className="next-btn" onClick={this.props.handleNext} title="Next">
                                <FontAwesomeIcon icon="step-forward" />
                            </button> */}
                        </div>
                        <div className="audio-info">
                            {this.props.title}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
