import React, { Component, Fragment } from 'react';
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { observer } from 'mobx-react';
import DatePicker from "react-datepicker";
import common from "../../apis/common";
import { ENDPOINT_GET_SUGGESTIONS, ENDPOINT_PODCST_EDIT, ENDPOINT_PODCST_UPLOAD } from "../../constants/strings";
import { getSession } from "../../utils/AuthUtils";
import {
    DU_TOAST_ID,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from "../../constants/strings";
import "./style.scss";
import Multiselect from 'multiselect-react-dropdown';

const moment = require('moment');

class PodcastEdit extends Component {
    constructor(props) {
        super(props);
        this.shareDialogRef = React.createRef();
        this.store = {
            tagify: {
                fieldInputs: {
                    stock: null,
                    lead_authors: null,
                    sector: null,
                }
            }
        };
        this.stockInfoData = {};
        this.state = {
            closeAll: false,
            isLoading: false,
            fileInputKey: moment.now(),
            dateState: new Date(),
            URL: "",
            currentCount: 0,
            lead_authors: [],
            stock: [],
            sector: [],
            payload: {
                id: null,
                title: null,
                file: null,
                lead_authors: [],
                stock: [],
                sector: [],
                release_date: null,
                is_deleted: false, // Initialize is_deleted to false
            },
            errors: {
                file_invalid: null,
                title_invalid: null,
                report_type_invalid: null,
                lead_authors_invalid: null,
                sub_authors_invalid: null,
                release_date_invalid: null,
            },
        };
    }

    clearFieldStates = () => {
        Object.keys(this.store.tagify.fieldInputs).forEach((key) => {
            this.store.tagify.fieldInputs[key].removeAllTags();
            if (!["lead_authors"].includes(key)) {
                delete this.store.tagify.fieldInputs[key];
            }
        });

        this.setState({
            fileInputKey: moment.now(),
            currentCount: 0,
            dateState: new Date(),
            payload: {
                title: null,
                file: null,
                lead_authors: [],
                stock: [],
                sector: [],
                release_date: null,
            }
        });
    };

    clearErrorState = () => {
        this.setState({
            responseText: "",
            errors: {
                file_invalid: null,
                title_invalid: null,
                report_type_invalid: null,
                lead_authors_invalid: null,
                sub_authors_invalid: null,
                release_date_invalid: null,
            }
        });
    };

    onUpload = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });
        this.clearErrorState();
        // const error = Validate('documentUpload', this.state);
        // if (Object.keys(error).length) {
        //     this.setState({
        //         errors: { ...this.state.errors, ...error },
        //         isLoading: false
        //     });
        //     this.props.toastify.fire("Please input valid file.", DU_TOAST_ID, TOAST_TYPE_ERROR);
        //     return;
        // }

        let form_data = new FormData();
        if (this.state.payload.file != undefined) {
            form_data.append('file', this.state.payload.file, this.state.payload.file.name);
        }
        if (this.state.payload.lead_authors)
            form_data.append('lead_authors', JSON.stringify(this.state.payload.lead_authors));
        if (this.state.payload.release_date)
            form_data.append('release_date', this.state.payload.release_date);
        if (this.state.payload.sector)
            form_data.append('sector', JSON.stringify(this.state.payload.sector));
        if (this.state.payload.stock) {
            if (this.state.payload.stock?.length <= 0) {
                // this.setState({ isLoading: false });
                // this.props.toastify.fire("Please Select at least 1 Stock", DU_TOAST_ID, TOAST_TYPE_ERROR);
                // return;
            }
            else {
                form_data.append('stock', JSON.stringify(this.state.payload.stock));
            }
        }
        if (this.state.payload.title)
            form_data.append('title', this.state.payload.title);

        if (this.state.payload.is_deleted != undefined) {
            form_data.append('is_deleted', this.state.payload.is_deleted == true ? 1 : 0);
        }
        console.log(this.state.payload);
        let session = await getSession();
        if (!session) {
            this.props.toastify.fire("Something went wrong. Please try again later.", DU_TOAST_ID, TOAST_TYPE_ERROR);
            return;
        }
        let response;
        try {
            response = await common.post(
                `${ENDPOINT_PODCST_EDIT}?podcast_id=${this.state.payload.id}`,
                form_data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": session.accessToken.jwtToken
                    }
                }
            );
            if (response && response.status === 200) {
                this.setState({ isLoading: false });
                this.props.toastify.fire(response?.data?.success_message, DU_TOAST_ID, TOAST_TYPE_SUCCESS);
                this.props.history.push('/podcastlist');
            }
        } catch (err) {
            console.log("err", err)
            let res = err.response ? err.response : {};
            if (res.hasOwnProperty('data') && res.data.hasOwnProperty("error_message")) {
                this.props.toastify.fire(res.data.error_message, DU_TOAST_ID, TOAST_TYPE_ERROR);
            } else {
                this.props.toastify.fire('Something went wrong', DU_TOAST_ID, TOAST_TYPE_ERROR);
            }
            this.setState({ isLoading: false });
        }
    };

    async getSuggestions(payload) {
        let session = await getSession();
        if (!session) {
            return [];
        }
        const { data } = await common.get(
            ENDPOINT_GET_SUGGESTIONS,
            {
                params: {
                    ...payload,
                    sectors: ["ALL"]
                },
                headers: { "Authorization": session.accessToken.jwtToken }
            });
        return data;
    }
    async getSectorNameFromStock(payload) {
        let session = await getSession();
        if (!session) {
            return [];
        }
        const { data } = await common.get(
            ENDPOINT_GET_SUGGESTIONS,
            {
                params: {
                    ...payload,
                    sectors: ["ALL"]
                },
                headers: { "Authorization": session.accessToken.jwtToken }
            });
        return data?.data;
    }

    onInputChange = event => {
        if (event.target.name.includes('target_price') || event.target.name.includes('current_price')) {
            let indexNumber = event.target.name.split('_');
            indexNumber = parseInt(indexNumber[indexNumber.length - 1]);
            if (!this.stockInfoData.hasOwnProperty(indexNumber)) {
                this.stockInfoData[indexNumber] = {};
            }
            if (isNaN(event.target.value.trim())) {
                this.props.toastify.fire("Price needs to be a number", DU_TOAST_ID, TOAST_TYPE_ERROR);
                return;
            }
            this.stockInfoData[indexNumber][event.target.name] = parseInt(event.target.value.trim());
        } else {
            let prevState = { ...this.state.payload };
            prevState[event.target.name] = event.target.value;
            this.setState({
                payload: { ...prevState }
            });
        }
    };

    handleDateChange = (date) => {
        let payload = { ...this.state.payload };
        payload.release_date = moment(date).format('YYYY-MM-DD');
        this.setState({
            dateState: date,
            payload: { ...payload }
        });
    };
    handleIsDeletedChange = (event) => {
        let payload = { ...this.state.payload };
        const isDeleted = event.target.checked;
        this.setState({
            payload: {
                ...payload,
                is_deleted: isDeleted
            }
        });
    };
    handleUpload = (e) => {
        let payload = { ...this.state.payload };
        const file = e.target.files[0];

        if (file) {
            // Extract the file name without the extension
            const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");

            // Check if the file name matches the payload title
            if (fileNameWithoutExtension !== payload.title) {
                // Show a toast message
                this.props.toastify.fire("File name shoude be same as title.", DU_TOAST_ID, TOAST_TYPE_ERROR);
                // Clear the selected file
                e.target.value = null;
                payload.file = null;
            } else {
            payload.file = file;
            }

            this.setState({
                payload: { ...payload },
            });
        }
    };

    async componentDidMount() {
        if (!this.props.auth.user ||
            !this.props.auth.user.signInUserSession.accessToken.hasOwnProperty("payload") ||
            !this.props.auth.user.signInUserSession.accessToken.payload.hasOwnProperty("cognito:groups") ||
            !this.props.auth.user.signInUserSession.accessToken.payload["cognito:groups"].length ||
            !this.props.auth.user.signInUserSession.accessToken.payload["cognito:groups"].includes("spark")
        ) {
            this.props.history.push("/document_search");
        }
        const podcast = this.props?.location?.state?.podcast;
        if (podcast) {
            this.setState({
                dateState: new Date(podcast?.RELEASE_DATE),
                payload: {
                    id: podcast?.ID,
                    title: podcast?.TITLE,
                    file: null,
                    lead_authors: podcast?.LEAD_AUTHORS || [],
                    stock: podcast?.STOCK_INFO || [],
                    sector: podcast?.SECTOR_INFO || [],
                    release_date: podcast?.RELEASE_DATE,
                    is_deleted: podcast?.IS_DELETED
                }
            });
        }
        const authorList = await this.getSuggestions({
            suggestion_text: "",
            suggestion_type: ["authors"]
        })
        if (authorList) {
            this.setState({
                lead_authors: authorList?.data
            })
        }
    }
    render() {
        return (
            <div className="document-upload">
                <div className="upload-card">
                    <div className="card-header">
                        <div className="card-title">
                            Edit Podcast
                        </div>
                    </div>
                    <div className="card-body" style={{
                        height: "100%"
                    }}>
                        <Form>
                            <div className="form-input-container">
                                <div className="form-field">
                                    <span className="field-label">{"Podcast File"}</span>
                                    {
                                        <FormGroup>
                                            <div className="file-container">
                                                <input
                                                    type="file"
                                                    id="file"
                                                    accept=".mp3,.m4a"
                                                    onChange={this.handleUpload}
                                                />
                                            </div>
                                        </FormGroup>
                                    }
                                </div>
                            </div>
                            <div className="form-input-container">
                                <div className="form-field">
                                    <span className="field-label">{"Podcast Title"}</span>
                                    {
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name={"title"}
                                                value={this.state.payload["title"]}
                                                disabled={true}
                                                onChange={this.onInputChange}
                                                invalid={this.state.errors["title" + "_invalid"]}
                                            />
                                        </FormGroup>
                                    }
                                </div>
                            </div>
                            <div className="form-input-container">
                                <div className="form-field">
                                    <span className="field-label">{"Release Date"}</span>
                                    {
                                        <FormGroup>
                                            <div className="date-container">
                                                <DatePicker
                                                    className="date-picker"
                                                    dateFormat="dd-MMM-yyyy"
                                                    selected={this.state.dateState}
                                                    onChange={this.handleDateChange}
                                                    popperPlacement="top"
                                                    dropdownMode="select"
                                                    placeholderText="Select a date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                        </FormGroup>
                                    }
                                </div>
                            </div>
                            <div className="form-input-container py-4">
                                <div className="form-field">
                                    <span className="field-label">{"Lead Authors"}</span>
                                    {
                                        <FormGroup>
                                            <Multiselect
                                                options={this.state.lead_authors}
                                                selectedValues={this.state.payload.lead_authors?.map((d) => {
                                                    return {
                                                        category_name: d
                                                    }
                                                })}
                                                onSelect={(selectedList, selectedItem) => {
                                                    this.setState({
                                                        payload: {
                                                            ...this.state.payload,
                                                            lead_authors: [...new Set([...this.state.payload.lead_authors, selectedItem?.category_name])]
                                                        }
                                                    })
                                                }}
                                                onRemove={(selectedList, selectedItem) => {
                                                    this.setState({
                                                        payload: {
                                                            ...this.state.payload,
                                                            lead_authors: [...new Set([...this.state.payload.lead_authors?.filter(d => d != selectedItem?.category_name)])]
                                                        }
                                                    })
                                                }}
                                                displayValue="category_name"
                                            />
                                        </FormGroup>
                                    }
                                </div>
                            </div>
                            <div className="form-input-container"
                            // style={{
                            //     marginTop: 20 + ((this.state.payload.lead_authors || [])?.length) * 4
                            // }}
                            >
                                <div className="form-field">
                                    <span className="field-label">{"Stock"}</span>
                                    {
                                        <FormGroup>
                                            <Multiselect
                                                emptyRecordMsg="Search Any Stock Name"
                                                onSearch={async (e) => {
                                                    const stockList = await this.getSuggestions({
                                                        suggestion_text: e,
                                                        suggestion_type: ["stock"]
                                                    })
                                                    if (stockList) {
                                                        this.setState({
                                                            stock: stockList?.data
                                                        })
                                                    }
                                                }}
                                                onSelect={(selectedList, selectedItem) => {
                                                    this.setState({
                                                        payload: {
                                                            ...this.state.payload,
                                                            stock: [...new Set([...this.state.payload.stock, selectedItem?.category_name])],
                                                            sector: [...new Set([...this.state.payload.sector, selectedItem?.sector_name])]
                                                        }
                                                    })
                                                }}
                                                onRemove={async (selectedList, selectedItem) => {
                                                    let sector_name_list = await this.getSectorNameFromStock({
                                                        suggestion_text: selectedItem?.category_name,
                                                        suggestion_type: ["stock"]
                                                    });
                                                    sector_name_list = sector_name_list?.map((d) => d?.category_name?.sector_name);
                                                    this.setState({
                                                        payload: {
                                                            ...this.state.payload,
                                                            stock: [...new Set([...this.state.payload.stock?.filter(d => d != selectedItem?.category_name)])],
                                                            sector: [...new Set([...
                                                                this.state.payload.sector?.length > 0 ?
                                                                    this.state.payload.sector?.filter(d => !sector_name_list.includes(d)) :
                                                                    []
                                                            ])]
                                                        }
                                                    })
                                                }}
                                                options={this.state.stock?.map((d) => {
                                                    return {
                                                        category_name: d?.category_name?.stock_name,
                                                        sector_name: d?.category_name?.sector_name,
                                                    }
                                                })}
                                                selectedValues={this.state.payload.stock?.map((d) => {
                                                    return {
                                                        category_name: d
                                                    }
                                                })}
                                                displayValue="category_name"
                                            />
                                        </FormGroup>
                                    }
                                </div>
                            </div>
                            <div className="form-input-container py-4">
                                <div className="form-field">
                                    <span className="field-label">{"Sectors"}</span>
                                    {
                                        <FormGroup>
                                            <Multiselect
                                                emptyRecordMsg="Please Select Stock Sectors Will Automatically Selected"
                                                options={[]}
                                                selectedValues={this.state?.payload?.sector?.map((d) => {
                                                    return {
                                                        category_name: d
                                                    }
                                                })}
                                                onRemove={(selectedList, selectedItem) => {
                                                    this.setState({
                                                        payload: {
                                                            ...this.state.payload,
                                                            sector: [...new Set([...this.state.payload.sector?.filter(d => d != selectedItem?.category_name)])]
                                                        }
                                                    })
                                                }}
                                                displayValue="category_name"
                                            />
                                        </FormGroup>
                                    }
                                </div>
                            </div>
                            <div className="form-input-container">
                                <div className="form-field">
                                    <span className="field-label">{"Is Deleted"}</span>
                                    {
                                        <FormGroup>
                                            <Label>
                                                <Input
                                                    type="checkbox"
                                                    name="is_deleted"
                                                    checked={this.state.payload.is_deleted}
                                                    onChange={this.handleIsDeletedChange}
                                                />
                                                {' '}
                                                {/* {this.state.payload.is_deleted ? "Mark as Deleted" : "Mark as UnDeleted"} */}
                                            </Label>
                                        </FormGroup>
                                    }
                                </div>
                            </div>
                            <div style={{ marginTop: 100, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Button
                                    className="submit-button"
                                    size="sm"
                                    onClick={this.onUpload}
                                >{!this.state.isLoading ? "Submit and Edit" : <Spinner size="sm" color="white" />}
                                </Button>
                                <a
                                    style={{
                                        color: "black"
                                    }}
                                    href={`/podcastlist`}
                                    className="mt-2"
                                >
                                    Go Back to Podcast List
                                </a>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(PodcastEdit);
