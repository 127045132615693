import {toJS} from "mobx";
import { filterTemplate, filterTemplate2 } from '../templates/DocumentSearch/index';
import {
    getDocumentMetaFrame,
    getDocumentMetaTemplate,
    getLoadedDocumentMetaTemplate,
    getSnippetsTemplate
} from "../utils/Document";

let toJs = require('lodash.clonedeep');

class DocumentSearchStore {
    apiCallActive = true;
    filters = toJs(filterTemplate);
    results = null;
    visitCounter = 0; //Visits to the app are recorded in this counter
    resultViewer = {
        apiCallActive: true,
        results: null,
    };
    pagination = {
        currentPage: 0,
        totalPages: 0,
    };
    previewActive = false;
    snippets = getSnippetsTemplate();
    selectedDocumentId = '';
    selectedDocumentSource = '';
    selectedDocumentIsOpen = false;
    selectedDocumentBlob = ``;
    selectedSnippetSearchTerms = '';
    selectedSnippetPageNum = 1;
    sharedDocumentId = '';
    triggerForClientDownload = false;
    tagify = {
        mainSearch: {
            input: null
        },
        advanceSearch: {
            sector: null,
            stock: null,
            report_type: null,
            recommendation: null,
            include_all: null,
            include_any: null,
            exclude_all: null,
        }
    };
    filterPanel = toJS(filterTemplate2);
    temporarySharedDocumentId = "";
    lastReadReport = {
        "data": [
            {
                "document_id": "e413f7dc3e59caf5c65a80467ca78edfef9df0be0648a2135cfd0a199749d52c",
                "lead_authors": null,
                "report_type": "Company Update",
                "stock_info": [
                    {
                        "recommendation": "BUY",
                        "sector": null,
                        "stock_id": null,
                        "stock_name": null
                    }
                ],
                "sub_authors": [
                    "Gupta SK."
                ],
                "title": "Zomato test 2"
            },
            {
                "document_id": "61d38a981ede14bb5b18d266134966d183481ade6556693b4cc35463e81c4571",
                "lead_authors": [
                    "Rajamohan R"
                ],
                "report_type": "Annual Report Analysis",
                "sub_authors": null,
                "title": "Conference call - 1 oct"
            },
            {
                "document_id": "af68c6f1f8e99593152a280c409d2431c1a5ccd1e7f2765d708376197a54b48d",
                "lead_authors": [
                    "Ganesh K",
                    "Shalabh Jain"
                ],
                "report_type": "Initiation",
                "stock_info": [
                    {
                        "recommendation": "BUY",
                        "sector": "Banking",
                        "stock_id": 42,
                        "stock_name": "Bank of Baroda"
                    }
                ],
                "sub_authors": null,
                "title": "Shalabh Test 16Sep2021-3"
            },
            {
                "document_id": "eaba1dca11998ba4b3e593b4a58c46a76e6c99ff5443a5adfda06564066cf0e3",
                "lead_authors": [
                    "Ganesh K",
                    "Shalabh Jain"
                ],
                "report_type": "Initiation",
                "stock_info": [
                    {
                        "recommendation": "BUY",
                        "sector": "Strategy",
                        "stock_id": 42,
                        "stock_name": "Bank of Baroda"
                    }
                ],
                "sub_authors": null,
                "title": "Shalabh Test 16Sep2021-4"
            },
            {
                "document_id": "54000d6b2dd7ef45be52c14e13e96f51bf831e8006e8c71f5a6c381bb23051f4",
                "lead_authors": [
                    "Rubani Kumar"
                ],
                "report_type": "Company Update",
                "stock_info": [
                    {
                        "recommendation": "BUY",
                        "sector": "Automobile Oems & Ancillaries",
                        "stock_id": 235,
                        "stock_name": "Maruti Suzuki India"
                    }
                ],
                "sub_authors": [
                    "Abhinesh Vijayaraj"
                ],
                "title": "Title has been changed through API. Changing the author also"
            }
        ],
        "status": true
    }

    setFilterPanel(data){
        this.filterPanel = data;
    }

    incrementVisitCounter() {
        this.visitCounter++;
    }

    setApiCallActive(apiCallActive) {
        this.apiCallActive = apiCallActive;
    }

    setOpenPDFModal(openPDFModal) {
        this.openPDFModal = openPDFModal;
    }

    setResults(results) {
        this.results = toJS(results);
    }

    setResultViewer(apiCallActive, results = null) {
        this.resultViewer.apiCallActive = apiCallActive;
        this.resultViewer.results = (!results) ? null : toJs(results);
    }

    setFilterViewer(apiCallActive, results = null) {
        this.filterViewer.apiCallActive = apiCallActive;
        this.filterViewer.results = (!results) ? null : toJs(results);
    }
    setPreviewActive(previewActive) {
        this.previewActive = previewActive;
    }

    setSnippets(snippets, placeholder = false) {
        this.snippets.data = toJS(snippets);
        this.snippets.placeholder = placeholder;
    }

    setCurrentPage(pageNumber) {
        this.pagination.currentPage = pageNumber;
    }

    setTotalPages(pages) {
        this.pagination.totalPages = pages;
    }

    setFilters(filter, nestedFilter, val) {
        if (nestedFilter) {
            this.filters[filter][nestedFilter] = val;
        } else {
            this.filters[filter] = val;
        }
    }

    setSelectedDocumentId(selectedDocumentId) {
        this.selectedDocumentId = selectedDocumentId;
    }

    setSelectedDocumentSource(selectedDocumentSource) {
        this.selectedDocumentSource = selectedDocumentSource;
    }

    setSelectedDocumentIsOpen(selectedDocumentIsOpen) {
        this.selectedDocumentIsOpen = selectedDocumentIsOpen;
    }

    setSelectedDocumentBlob(selectedDocumentBlob) {
        this.selectedDocumentBlob = selectedDocumentBlob;
    }

    setSelectedSnippetPageNum(selectedSnippetPageNum) {
        this.selectedSnippetPageNum = selectedSnippetPageNum;
    }

    setSelectedSnippetSearchTerms(selectedSnippetSearchTerms) {
        this.selectedSnippetSearchTerms = selectedSnippetSearchTerms;
    }

    setSharedDocumentId(sharedDocumentId) {
        this.sharedDocumentId = sharedDocumentId;
    }

    setTriggerForClientDownload(triggerForClientDownload) {
        this.triggerForClientDownload = triggerForClientDownload;
    }

    resetFilters() {
        this.filters = toJs(filterTemplate);
    }

    resetFilterValues(filterValues) {
        this.filters = filterValues;
    }

    resetAllDocumentData() {
        this.selectedDocumentId = '';
        this.selectedDocumentSource = '';
        this.selectedDocumentIsOpen = false;
        this.selectedDocumentBlob = ``;
        this.selectedSnippetSearchTerms = '';
        this.selectedSnippetPageNum = 1;
    }
    resetFilterVal(){
        this.filterPanel = toJs(filterTemplate2);
    }
    getFilters() {
        return this.filters;
    }
    
}

export default DocumentSearchStore;
