//This is a modified version of Bootstrap 4 button

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import TagsInput from '../TagsInput';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toJS } from 'mobx';
var filter = require('lodash.filter');

class AdvancedSearch extends Component {
    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.props.onSearchTrigger();
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false)
    }

    handleClickOutside = (e) => {
        if (this.node.contains(e.target) || e.target.closest('.tagify__dropdown')) {
            return;
        }
        this.props.searchModuleStore.hideAdvSearchPanel();
    };

    emptyAllAdvSearchTagInputs = () => {
        Object.keys(toJS(this.props.store.tagify.advanceSearch)).forEach((key) => {
            this.props.store.tagify.advanceSearch[key].removeAllTags();
        });
    };

    onAdvSearchTagAdd = (e, identifier, forceSuggesterResults) => {
        if (!this.props.searchModuleStore.syncingAdvSearch) {
            if (forceSuggesterResults) {
                let result = filter(this.props.store.tagify.advanceSearch[identifier].settings.whitelist, { value: e.detail.data.value });
                if (!result.length) {
                    this.props.store.tagify.advanceSearch[identifier].removeTag(e.detail.tag);
                    return;
                }
            }
            let category;
            if (!e.detail.data.hasOwnProperty('category') || !e.detail.data.category) {
                category = identifier;
            } else { category = e.detail.data.category; }
            this.props.searchModuleStore.syncingMainSearch = true;
            this.props.store.tagify.mainSearch.input.addTags([
                {
                    ...e.detail.data,
                    value: e.detail.data.value,
                    category: category,

                }
            ]);
            this.props.searchModuleStore.syncingMainSearch = false;
        }
    };

    onAdvSearchTagRemove = (e, identifier) => {
        if (!this.props.searchModuleStore.syncingAdvSearch) {
            console.log("removing from main search too", e, identifier);
            let category;
            if (!e.detail.data.hasOwnProperty('category') || !e.detail.data.category) {
                category = identifier;
            } else { category = e.detail.data.category; }
            let tagElement = document.querySelector(`.main-search-input tag[value="${e.detail.data.value}"][category="${category}"]`);
            this.props.searchModuleStore.syncingMainSearch = true;
            this.props.store.tagify.mainSearch.input.removeTag(tagElement);
            this.props.searchModuleStore.syncingMainSearch = false;
        }
    };

    render() {
        return (
            <div className="adv-search-container" ref={(node) => { this.node = node }}>
                <Button
                    className={"adv-search-button " + (this.props.searchModuleStore.advSearchPanelState === 'visible' ? "active" : "")}
                    id="PopoverFocus"
                    type="button"
                    onClick={
                        () => {
                            if (this.props.searchModuleStore.advSearchPanelState === 'hidden') {
                                this.props.searchModuleStore.syncingAdvSearch = true;
                                this.props.onAdvSearchButtonClick();
                                this.props.searchModuleStore.syncingAdvSearch = false;
                            }

                            this.props.searchModuleStore.toggleAdvSearchPanel();
                        }
                    }
                >
                    <FontAwesomeIcon icon="sliders-h" />
                </Button>
                <div className={"advanced-search-panel advanced-search-panel-" + this.props.searchModuleStore.advSearchPanelState}>
                    <div className="advanced-search-panel-header">
                        Advanced Search
                        <button
                            className="as-reset-button"
                            onClick={this.props.onAdvSearchReset}
                        >
                            <FontAwesomeIcon icon="undo" className="adv-reset-icon" />
                            RESET
                        </button>
                    </div>
                    <div className="advanced-search-panel-body">
                        {this.props.advancedSearchConfig.map((obj, idx) => {
                            return (
                                <div className="as-input-area" key={idx}>
                                    <div className="as-input-container">
                                        <div className="as-input-label">{obj.name}</div>
                                        <div className="as-input-wrapper">
                                            <TagsInput
                                                sectors={this.props.sectors}
                                                auth={this.props.auth}
                                                store={this.props.store}
                                                config={obj}
                                                filterType={this.props.filterType}
                                                className="as-input"
                                                on_add={this.onAdvSearchTagAdd}
                                                on_remove={this.onAdvSearchTagRemove}
                                                onKeyPress={this.onKeyPress}
                                                mode="input"
                                                apiObj={this.props.apiObj}
                                                generatePayload={this.props.generatePayload}
                                                suggestionsApiUrl={this.props.suggestionsApiUrl}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className="advanced-search-panel-footer">
                        <div className="as-action-buttons">
                            <button
                                className="as-search-button"
                                onClick={
                                    () => {
                                        this.props.searchModuleStore.toggleAdvSearchPanel();
                                        this.props.onSearchTrigger();
                                    }
                                }
                            >
                                <FontAwesomeIcon icon="search" className="adv-search-icon" />
                                SEARCH
                            </button>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

export default observer(AdvancedSearch);
