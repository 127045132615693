//This is a modified version of Bootstrap 4 modal

import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import Label from "reactstrap/es/Label";
import Input from "reactstrap/es/Input";
import FormGroup from "reactstrap/es/FormGroup";
import './style.scss';
import {
    DS_TOAST_ID,
    MSG_COPIED_CLIPBOARD,
    MSG_NOT_COPIED_CLIPBOARD,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from "../../constants/strings";

class ShareDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            centered: true,
            shareURL: ''
        };
    }

    copyLink = () => {
        try {
            let textInput = document.getElementById("document-share-link");
            textInput.select();
            document.execCommand('copy');
            this.props.toastify.fire(MSG_COPIED_CLIPBOARD, DS_TOAST_ID, TOAST_TYPE_SUCCESS);
        } catch(e) {
            console.error(e);
            this.props.toastify.fire(MSG_NOT_COPIED_CLIPBOARD, DS_TOAST_ID, TOAST_TYPE_ERROR);
        }
    };

    setShareURL(url) {
        this.setState({shareURL: url});
    }

    /**
     * This method toggles the visibility of Share Dialog modal
     */
    toggle = () => {
        this.setState(prevState => ({modal: !prevState.modal}));
    };

    /**
     * This method toggles the visibility of Share Dialog modal
     * to 'show' or 'visible'
     */
    show = () => {
        this.setState({modal: true}, ()=>setTimeout(()=> this.copyLink(),100));
    };

    render() {
        return (
            <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                onClosed={this.props.closeAll ? this.toggle : undefined}
                centered={this.state.centered}
                className="share-dialog"
                backdrop={this.props.backdrop}
            >
                {/*Using custom header here as <Modalheader/> component injects <h5/> automatically*/}
                <div className="dialog-header">
                    <FontAwesomeIcon className="share-icon" icon="share"/>
                    <span className="title">{this.props.title}</span>
                </div>

                <ModalBody className="dialog-body">
                    <FormGroup>
                        {
                            this.props.showLabel &&
                            <Label className="input-label" for="document-share-link">Link</Label>
                        }
                        <Input className="input-field" value={this.state.shareURL} readOnly type="text" name="city" id="document-share-link"/>
                    </FormGroup>
                </ModalBody>

                <ModalFooter className="dialog-footer">
                    <Button onClick={this.toggle} className="action-btn cancel">Cancel</Button>
                    <Button onClick={this.copyLink} className="action-btn share">Copy Link</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ShareDialog;
